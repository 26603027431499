import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FamilyPlanningRecordForm from './familyPlanningRecordForm';
import ServiceVisitsTable from './familyServiceVisitTable';
import AddServiceVisitModal from './addServiceVisitModal';
import { Container, Button } from 'react-bootstrap';
import { getFamilyPlanningRecordById, updateFamilyPlanningRecord, addServiceVisit, getServiceVisitsByRecordId, deleteServiceVisit, updateServiceVisit } from '../Controller/FamilyPlanningRecord_controller';

const FamilyPlanningRecordDetails = () => {
  const { id } = useParams();
  const [record, setRecord] = useState(null);
  const [updatedRecord, setUpdatedRecord] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [serviceVisits, setServiceVisits] = useState([]);
  const [currentVisit, setCurrentVisit] = useState(null);


  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessFormMessage, setShowSuccessFormMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successFormMessage, setSuccessFormMessage] = useState('');

  useEffect(() => {
    const fetchRecord = async () => {
      try {
        setLoading(true);
        if (id) {
          const data = await getFamilyPlanningRecordById(id);
          setRecord(data);
          setUpdatedRecord({
            ...data,
            acceptedMethods: data.acceptedMethods || [],
            heent: data.heent || [],
            chestHeart: data.chestHeart || [],
            abdomen: data.abdomen || [],
            genital: data.genital || [],
            extremities: data.extremities || [],
          });
        } else {
          setError('ID is undefined');
        }
      } catch (err) {
        setError('Failed to fetch record.');
      } finally {
        setLoading(false);
      }
    };

    const fetchServiceVisits = async () => {
      try {
        if (id) {
          const visits = await getServiceVisitsByRecordId(id);
          setServiceVisits(visits || []);
        }
      } catch (err) {
      }
    };

    fetchRecord();
    fetchServiceVisits();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedRecord({ ...updatedRecord, [name]: value });
  };

  const handleSaveClick = async () => {
    const confirmed = window.confirm('Are you sure you want to update the record?');
  
    if (!confirmed) return; // Exit if the user cancels
    try {
      setLoading(true);
      await updateFamilyPlanningRecord(id, updatedRecord);
      setRecord(updatedRecord);
      setSuccessFormMessage('Record updated successfully.');
      setShowSuccessFormMessage(true);
      setTimeout(() => setSuccessFormMessage(false), 3000);
      setEditMode(false);
    } catch (err) {
      setError('Failed to update record.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setUpdatedRecord(record);
    setEditMode(false);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleAddVisitClick = () => {
    setShowAddModal(true);
  };

  const handleAddVisit = async (newVisit) => {
    try {
      await addServiceVisit(id, newVisit);
      const updatedVisits = await getServiceVisitsByRecordId(id);
      setServiceVisits(updatedVisits || []);
      setShowAddModal(false);
      setSuccessMessage('Service visit added successfully.');
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      setError('Failed to add service visit.');
    }
  };

  const handleUpdateVisit = async (updatedVisit) => {
    try {
      await updateServiceVisit(id, updatedVisit);
      const updatedVisits = await getServiceVisitsByRecordId(id);
      setServiceVisits(updatedVisits || []);
      setShowUpdateModal(false);
      setSuccessMessage('Service visit updated successfully.');
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      setError('Failed to update service visit.');
    }
  };

  const handleDeleteVisit = async (visitId) => {
    try {
      await deleteServiceVisit(id, visitId);
      const updatedVisits = await getServiceVisitsByRecordId(id);
      setServiceVisits(updatedVisits || []);
      setSuccessMessage('Service visit deleted successfully.');
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      setError('Failed to delete service visit.');
    }
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const handleUpdateVisitClick = (visit) => {
    setCurrentVisit(visit);
    setShowUpdateModal(true);
  };

  return (
    <Container>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {record && (
        <>
          {showSuccessFormMessage && (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          {successFormMessage}
          <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowSuccessFormMessage(false)}></button>
        </div>
      )}
          <FamilyPlanningRecordForm
            record={record}
            updatedRecord={updatedRecord}
            editMode={editMode}
            handleChange={handleChange}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            setUpdatedRecord={setUpdatedRecord}
          />
          <AddServiceVisitModal
            show={showAddModal}
            handleClose={handleCloseAddModal}
            recordId={id}
            handleAddVisit={handleAddVisit}
          />
          {currentVisit && (
            <AddServiceVisitModal
              show={showUpdateModal}
              handleClose={handleCloseUpdateModal}
              recordId={id}
              handleAddVisit={handleUpdateVisit}
              initialVisit={currentVisit}
            />
          )}
           {showSuccessMessage && (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          {successMessage}
          <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowSuccessMessage(false)}></button>
        </div>
      )}
        {error && (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {error}
          <button type="button" className="btn-close" aria-label="Close" onClick={() => setError('')}></button>
        </div>
      )}
          <ServiceVisitsTable
            visits={serviceVisits}
            recordId={id}
            onAddVisitClick={handleAddVisitClick}
            onUpdateVisitClick={handleUpdateVisitClick}
            onDeleteVisitClick={handleDeleteVisit}
          />
        </>
      )}
    </Container>
  );
};

export default FamilyPlanningRecordDetails;
