import axios from 'axios';


const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api';



export const getChildHealthRecords = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/childRecords/childHealthRecords`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching child health records:', error);
    throw error;
  }
};

export const searchChildHealthRecords = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/childRecords/search-child-records`, {
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching child health records:", error);
    return [];
  }
};


export const searchArchivedChildHealthRecords = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/childRecords/search-archived-child-records`, {
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching child health records:", error);
    return [];
  }
};
export const deleteChildHealthRecord = async (id) => {
  try {
    await axios.put(`${BASE_URL}/childRecords/archive-childHealthRecords/${id}`);
  } catch (error) {
    console.error('Error deleting child health record:', error);
    throw error;
  }
};
export const addChildHealthRecord = async (record) => {
  try {
    const response = await axios.post(`${BASE_URL}/childRecords/childHealthRecords`, record);
    return response.data;
  } catch (error) {
    console.error('Error adding child health record:', error);
    throw error;
  }
};

export const getChildHealthRecordById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/childRecords/childHealthRecords/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching child health record by ID:', error);
    throw error;
  }
};
export const updateChildHealthRecord = async (id, record) => {
  try {
    const response = await axios.post(`${BASE_URL}/childRecords/childHealthRecords/${id}`, record);
    return response.data;
  } catch (error) {
    console.error('Error updating child health record:', error);
    throw error;
  }
};

export const getChildVisitRecords = async (childHealthRecordId) => {
  try {
    const response = await axios.get(`${BASE_URL}/childRecords/childVisitRecords/${childHealthRecordId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching child visit records:', error);
    throw error;
  }
};


export const addChildVisitRecord = async (childHealthRecordId, visitRecord) => {
  try {
    console.log("Adding child visit record in frontend:", visitRecord);
    const response = await axios.post(
      `${BASE_URL}/childRecords/childVisitRecords/${childHealthRecordId}`,
      visitRecord,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    console.log("Response:", response);
    return response.data; // Return response data
  } catch (error) {
    // Log error response data or message
    if (error.response) {
      console.error('Error adding child visit record:', error.response.data);
    } else {
      console.error('Error adding child visit record:', error.message);
    }
    throw error; // Re-throw the error to be handled by the caller
  }
};


export const addVaccineRecord = async (childRecord_id, record) => {
  try {
    console.log('Add Vaccine Record Response:', childRecord_id);
    const recordWithId = { ...record, childRecord_id };
    const response = await axios.post(`${BASE_URL}/childRecords/add-vaccines`, recordWithId);
    console.log('Add Vaccine Record Request:', `${BASE_URL}/childRecords/vaccines`, recordWithId);
    console.log('Add Vaccine Record Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding vaccine record:', error);
    throw error;
  }
};

export const deleteVaccineRecord = async (id) => {
  if (!id) {
    console.error("ID is undefined or null");
    return;
  }

  console.log(`Deleting vaccine record with ID: ${id}`);
  try {
    await axios.delete(`${BASE_URL}/childRecords/vaccineRecords/${id}`);
    console.log(`Successfully deleted vaccine record with ID: ${id}`);
  } catch (error) {
    console.error(`Error deleting vaccine record with ID: ${id}`, error);
    throw error;
  }
};

export const getVaccineRecords = async (childRecord_id) => {
  try {
    const response = await axios.get(`${BASE_URL}/childRecords/${childRecord_id}/vaccineRecords`);
    return response.data || [];
  } catch (error) {
    console.error('Error fetching vaccine records:', error);
    throw error;
  }
};
export const updateVaccineRecord = async (recordId, updatedRecord) => {
  try {
    console.log("updating vaccine record", updatedRecord);
    // Use the _id field from updatedRecord if needed
    await axios.put(`${BASE_URL}/childRecords/vaccineRecords/${recordId}`, updatedRecord);
  } catch (error) {
    console.error('Error saving vaccine record:', error.message, error.stack);
    throw error;
  }
};


export const updateVisitRecord = async (updatedRecord) => {
  try {
    // Ensure updatedRecord contains _id for identifying the record to update
    if (!updatedRecord._id) {
      throw new Error('Missing _id in the updated record');
    }

    console.log("Updating visit record", updatedRecord);
    const response = await axios.put(`${BASE_URL}/childRecords/childVisitsRecords`, updatedRecord);

    // Debug information on success
    console.log('Visit record updated successfully:', response.data);
    return response.data; // Return response data if needed elsewhere

  } catch (error) {
    console.error('Error updating visit record:', error.message, error.stack);
    throw error;
  }
};
// Delete a visit record
export const deleteVisitRecord = async (recordId) => {
  try { 
    console.log(`Deleting visit record with ID: ${recordId}`);
    await axios.delete(`${BASE_URL}/childRecords/visitRecords/${recordId}`);
  } catch (error) {
    console.error(`Error deleting visit record with ID: ${recordId}`, error);
    throw error;
  }
};

export const getArchivedChildHealthRecords = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/childRecords/archivedChildHealthRecords`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching archived child health records:', error);
    throw error;
  }
};

// Restore archived child health record
export const restoreChildHealthRecord = async (id) => {
  try {
    const response = await axios.put(`${BASE_URL}/childRecords/restore-childHealthRecord/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error restoring child health record:', error);
    throw error;
  }
};
