import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faPrescriptionBottle, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MedicineDispensedRecords from '../MedicineDistributionRecord'; 
import AppointmentsTodayTable from './AppointmentTodayTable'; 
import { fetchAppointmentWeek } from '../Controller/appointment_controller';
import { fetchMedAndVitRecordsToday } from '../Controller/inventory_controller'; // Import the new function

import '../App.css';

function HealthWorkerDashboard() {
  const [activeComponent, setActiveComponent] = useState(null); // State to manage the active component
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [totalMedicineRecords, setTotalMedicineRecords] = useState(0); // State for medicine records

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const appointmentData = await fetchAppointmentWeek();
        setTotalAppointments(appointmentData.totalAppointments || 0);
        setAppointments(Array.isArray(appointmentData.appointments) ? appointmentData.appointments : []);

        const medicineData = await fetchMedAndVitRecordsToday();
        setTotalMedicineRecords(medicineData.totalRecords || 0); // Set total records
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    getDashboardData();
  }, []);

  const toggleComponent = (component) => {
    if (activeComponent === component) {
      setActiveComponent(null); // will remove active component if clicked again
    } else {
      setActiveComponent(component); // select the clicked component
    }
  };

  return (
    <Container className="admin-dashboard">
      <Row className="mb-4">
        <Col>
          <div className='page-header'><FontAwesomeIcon icon={faHeartbeat} size="lg" /> HEALTH WORKER DASHBOARD</div>
        </Col>
      </Row>
      <TransitionGroup as={Row} className="equal-height-cards">
        <CSSTransition key="totalAppointments" timeout={500} classNames="fade">
          <Col md={3} className="d-flex" onClick={() => toggleComponent('appointments')}>
            <Card className="info-card appointments-card flex-fill">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="text-left">
                  <Card.Title>Total Appointments <br></br>This Week</Card.Title>
                  <Card.Text>{totalAppointments}</Card.Text>
                </div>
                <div className="calendar-icon-container">
                  <FontAwesomeIcon icon={faCalendarCheck} size="2x" className="info-icon" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </CSSTransition>
        <CSSTransition key="medicineDistributedToday" timeout={500} classNames="fade">
          <Col md={3} className="d-flex" onClick={() => toggleComponent('medicine')}>
            <Card className="info-card medicine-card flex-fill">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="text-left">
                  <Card.Title>Medicine and Vitamins <br></br> Distributed Today</Card.Title>
                  <Card.Text>{totalMedicineRecords}</Card.Text> {/* Display total medicine records */}
                </div>
                <div className="user-icon-container">
                  <FontAwesomeIcon icon={faPrescriptionBottle} size="2x" className="info-icon" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </CSSTransition>
      </TransitionGroup>

      {/* Conditionally render the components based on activeComponent state */}
      {activeComponent === 'appointments' && <AppointmentsTodayTable appointments={appointments} />}
      {activeComponent === 'medicine' && <MedicineDispensedRecords />}
      </Container>
  );
}

export default HealthWorkerDashboard;
