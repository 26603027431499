import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faMagnifyingGlass, faTrash, faWarehouse,faBoxArchive } from '@fortawesome/free-solid-svg-icons';
import { getInventory, addItem, updateItem, deleteItem, searchItem } from './Controller/inventory_controller';
import './recordStyles.css'; 
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col, Button, Modal, Pagination, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import PaginationComponent from './UI/Pagination'; 

const InventoryManagement = () => {
  const [inventory, setInventory] = useState([]);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newItem, setNewItem] = useState({
    name: '',
    genericName: '',
    brand: '',
    expirationDate: '',
    qty: 0,
    category: '',
    description: '',
    manufacturer: '',
    storageLocation: '',
    source: '',
    batchNumber: '',
  });
  const [currentItem, setCurrentItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchInventory = async (page = 1) => {
    try {
      const { items, totalPages } = await getInventory(page, 20, startDate, endDate);
      setInventory(items);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching inventory:', error.message);
      setFailedMessage('Failed to fetch inventory items.');
    }
  };

  useEffect(() => {
    fetchInventory();
  }, [searchQuery, startDate, endDate, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Handle page data loading or fetching here
    console.log(`Selected page: ${page}`);
  };
  const resetForm = () => {
    setNewItem({
      name: '',
      genericName: '',
      brand: '',
      manufacturer: '',
      expirationDate: '',
      qty: '',
      category: '',
      description: '',
      storageLocation: '',
      source: '',
      batchNumber: ''
    });
    setIsValid(true);  // Reset validation state
  };
  

  const handleSearch = async () => {
    try {
      const { items, totalPages } = await searchItem(searchQuery);
      setInventory(items);
      setTotalPages(totalPages);
      setCurrentPage(1); // Reset to page 1
    } catch (error) {
      console.error('Error fetching search results:', error.message);
      setFailedMessage('Failed to fetch search results.');
    }
  };

  const handleDeleteItem = async (itemId) => {
    const confirmDelete = window.confirm('Are you sure you want to archive this item?');
    if (!confirmDelete) {
      return;
    }
    try {
      await deleteItem(itemId);
      setSuccessMessage('Item archived successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); 
      fetchInventory(); // Refresh inventory
    } catch (error) {
      console.error('Error archiving item:', error);
    }
  };

  const handleAddItem = () => {
    setShowAddItemModal(true);
  };

  const handleSaveNewItem = async () => {
    try {
      if (!validateForm()) {
        return; // If the form is not valid, stop here
      }
      await addItem(newItem);
      setSuccessMessage('Item added successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); 
      fetchInventory(); // Refresh inventory
      setNewItem({
        name: '',
        brand: '',
        expirationDate: '',
        qty: 0,
        category: '',
        description: '',
        storageLocation: '',
        source: '',
        batchNumber: '',
      });
      setShowAddItemModal(false);
    } catch (error) {
      console.error('Error adding new item:', error);
    }
  };

  const handleEditItem = (item) => {
    setCurrentItem(item);
    setEditMode(false);
    setShowEditItemModal(true);
  };

  const handleSaveEditedItem = async () => {
    console.log(currentItem);
    if (!validateEditForm()) {
      return; 
    }
 
    if (!currentItem)return;

    const confirmUpdate = window.confirm('Are you sure you want to update this item?');
    if (!confirmUpdate) {
      return;
    }
    try {
      await updateItem(currentItem._id, currentItem);
      setSuccessMessage('Item updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); 
      fetchInventory(); // Refresh inventory
      setCurrentItem(null);
      setEditMode(false);
      setShowEditItemModal(false);
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem(prevItem => ({ ...prevItem, [name]: value }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem(prevItem => ({ ...prevItem, [name]: value }));
  };

  const navigate = useNavigate();
  const redirectToArchivedPage = () => {
    navigate('/archived-inventory-record');
  };

  const [isValid, setIsValid] = useState(true);
  const validateForm = () => {
    if (!newItem.name || !newItem.genericName || !newItem.brand || !newItem.expirationDate || !newItem.qty || 
      !newItem.description  ||  !newItem.storageLocation || !newItem.source || !newItem.batchNumber) {
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    return true;
  };

  const validateEditForm = () => {
    // Check if any required fields are empty
    if (
      !currentItem.name || 
      !currentItem.brand || 
      !currentItem.manufacturer || 
      !currentItem.expirationDate || 
      !currentItem.qty || 
      !currentItem.category || 
      !currentItem.storageLocation || 
      !currentItem.source || 
      !currentItem.batchNumber ||
      !currentItem.description
    ) {
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    return true;
  };
  


  return (
    <div className='page-container'>
        <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="page-header">
        <FontAwesomeIcon icon={faWarehouse} className="inventory-icon"/> MEDICINE AND VITAMINS INVENTORY 
      </div>
      
      </div>
      <div className='msg'>
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )} </div>

        {/* Add Item Modal */}
        <Modal show={showAddItemModal} onHide={() => setShowAddItemModal(false)}>
          <Modal.Header>
            <Modal.Title>ADD NEW ITEM</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
             <Row> 
              <Col><Form.Group controlId="newItemName">
                <Form.Label>Name <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={newItem.name}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.name} 

                />
              </Form.Group></Col>
              <Col><Form.Group controlId="newItemGenericName">
                <Form.Label>Generic Name <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="text"
                  name="genericName"
                  value={newItem.genericName}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.genericName} 

                />
              </Form.Group> </Col> </Row>
              <Row>
              <Col><Form.Group controlId="newItemBrand">
                <Form.Label>Brand <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="text"
                  name="brand"
                  value={newItem.brand}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.brand} 

                />
              </Form.Group> </Col> 
              <Col>   <Form.Group controlId="newItemManufacturer">
                <Form.Label>Manufacturer <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="text"
                  name="manufacturer"
                  value={newItem.manufacturer}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.manufacturer} 
                />
              </Form.Group> </Col>
              </Row>
              <Row> 
              <Col> <Form.Group controlId="newItemExpirationDate">
                <Form.Label>Expiration Date <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="date"
                  name="expirationDate"
                  value={newItem.expirationDate}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.expirationDate} 
                />
              </Form.Group> </Col>
              <Col md={3}><Form.Group controlId="newItemQty">
                <Form.Label>Quantity <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="number"
                  name="qty"
                  value={newItem.qty}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.qty} 

                />
              </Form.Group></Col>
              <Col md={4}><Form.Group controlId="newItemCategory">
                <Form.Label>Category <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="text"
                  name="category"
                  value={newItem.category}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.category} 
                />
              </Form.Group> </Col> </Row>
              <Form.Group controlId="newItemDescription">
                <Form.Label>Description <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                 as="textarea"
                  name="description"
                  value={newItem.description}
                  onChange={handleInputChange}
                  isInvalid={!isValid && !newItem.description} 
                />
              </Form.Group>
            
              <Row><Col>
              <Form.Group controlId="newItemStorageLocation">
                <Form.Label>Storage Location <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  as="select"
                  name="storageLocation"
                  value={newItem.storageLocation}
                  isInvalid={!isValid && !newItem.storageLocation} 
                  onChange={handleInputChange}>
                  <option value="">Select Location</option>
                  <option value="BHCS1">BHCS1</option>
                  <option value="BHCS2">BHCS2</option>
                  <option value="BHCS3">BHCS3</option>
                </Form.Control>
              </Form.Group> </Col> 
              <Col><Form.Group controlId="newItemSource">
                <Form.Label>Source <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  as="select"
                  name="source"
                  value={newItem.source}
                  isInvalid={!isValid && !newItem.source} 
                  onChange={handleInputChange}
                >
                  <option value="">Select Source</option>
                  <option value="Barangay">Barangay</option>
                  <option value="City Office">City Office</option>
                </Form.Control> 
              </Form.Group></Col> </Row>
              <Form.Group controlId="newItemBatchNumber">
                <Form.Label>Batch Number <span style={{ color: 'red' }}>*</span> </Form.Label>
                <Form.Control
                  type="number"
                  name="batchNumber"
                  value={newItem.batchNumber}
                  isInvalid={!isValid && !newItem.source} 
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              setShowAddItemModal(false);
              resetForm(); 
            }}>Close</Button>
            <Button variant="primary" onClick={handleSaveNewItem}>Save</Button>
          </Modal.Footer>
        </Modal>

        {showEditItemModal && currentItem && (
          <Modal show={showEditItemModal} onHide={() => setShowEditItemModal(false)}>
            <Modal.Header>
              <Modal.Title>{editMode ? 'Update Item' : 'View Item'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
              <Row> <Col> 
              <Form.Group controlId="editName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={currentItem.name}
                    onChange={handleEditInputChange}
                    isInvalid={!isValid && !currentItem.name} 
                    disabled={!editMode}
                  />
                </Form.Group> </Col>
                <Col> <Form.Group controlId="editGenericName">
                  <Form.Label>Generic Name </Form.Label>
                  <Form.Control
                    type="text"
                    name="genericName"
                    value={currentItem.genericName}
                    isInvalid={!isValid && !currentItem.genericName} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  />
                </Form.Group> </Col> </Row>
                <Row> <Col> 
                <Form.Group controlId="editBrand">
                  <Form.Label>Brand </Form.Label>
                  <Form.Control
                    type="text"
                    name="brand"
                    value={currentItem.brand}
                    onChange={handleEditInputChange}
                    isInvalid={!isValid && !currentItem.brand} 
                    disabled={!editMode}
                  />
                </Form.Group></Col>
                <Col> 
                <Form.Group controlId="editManufacturer">
                  <Form.Label>Manufacturer </Form.Label>
                  <Form.Control
                    type="text"
                    name="manufacturer"
                    value={currentItem.manufacturer}
                    isInvalid={!isValid && !currentItem.manufacturer} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  />
                </Form.Group>  </Col> </Row>
                <Row> <Col> 
                <Form.Group controlId="editExpirationDate">
                  <Form.Label>Expiration Date </Form.Label>
                  <Form.Control
                    type="date"
                    name="expirationDate"
                    value={currentItem.expirationDate}
                    isInvalid={!isValid && !currentItem.expirationDate} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  />
                </Form.Group></Col>
                <Col md={3}> 
                <Form.Group controlId="editQty">
                  <Form.Label>Quantity </Form.Label>
                  <Form.Control
                    type="number"
                    name="qty"
                    value={currentItem.qty}
                    isInvalid={!isValid && !currentItem.qty} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  />
                </Form.Group> </Col>
                <Col md={4}> 
                 <Form.Group controlId="editCategory">
                  <Form.Label>Category </Form.Label>
                  <Form.Control
                    type="text"
                    name="category"
                    value={currentItem.category}
                    isInvalid={!isValid && !currentItem.category} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  />
                </Form.Group> </Col> </Row>
                <Form.Group controlId="editDescription">
                  <Form.Label>Description </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={currentItem.description}
                    isInvalid={!isValid && !currentItem.description} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  />
                </Form.Group>
              
                <Row> <Col>  
                <Form.Group controlId="editStorageLocation">
                  <Form.Label>Storage Location</Form.Label>
                  <Form.Control
                    as="select"
                    name="storageLocation"
                    value={currentItem.storageLocation}
                    isInvalid={!isValid && !currentItem.storageLocation} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}>
                    <option value="">Select Location</option>
                    <option value="BHCS1">BHCS1</option>
                    <option value="BHCS2">BHCS2</option>
                    <option value="BHCS3">BHCS3</option>
                  </Form.Control>
                </Form.Group></Col>
                <Col>  <Form.Group controlId="editSource">
                  <Form.Label>Source </Form.Label>
                  <Form.Control
                    as="select"
                    name="source"
                    value={currentItem.source}
                    isInvalid={!isValid && !currentItem.source} 
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  >
                    <option value="">Select Source</option>
                    <option value="Barangay">Barangay</option>
                    <option value="City Office">City Office</option>
                  </Form.Control>
                </Form.Group> </Col> </Row>
                <Form.Group controlId="editBatchNumber">
                  <Form.Label>Batch Number </Form.Label>
                  <Form.Control
                    type="number"
                    name="batchNumber"
                    value={currentItem.batchNumber}
                    isInvalid={!isValid && !currentItem.batchNumber}
                    onChange={handleEditInputChange}
                    disabled={!editMode}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditItemModal(false)}>Close</Button>
              {editMode ? (
                <Button variant="primary" onClick={handleSaveEditedItem}>Save Changes</Button>
              ) : (
                <Button variant="primary" onClick={() => setEditMode(true)}>Update</Button>
              )}
            </Modal.Footer>
          </Modal>
        )}
        <div className="container-usertable">
  
        <div className="row align-items-center">
        <div className="col-md-5 d-flex">
          <div className="d-flex align-items-center me-4">
            <p className="mb-0 me-2">From:</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-control date-input"
            />
          </div>
          <div className="d-flex align-items-center">
            <p className="mb-0 me-2">To:</p>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="form-control date-input"
            />
          </div>
        </div>
        <div className="col-md-7 d-flex align-items-center">
        <div className="archive-btn"><button className="btn" onClick={redirectToArchivedPage}>
        <FontAwesomeIcon icon={faBoxArchive} /> ARCHIVE
        </button>   </div>
          <div className="add-user-button">
            <Button className="btn " onClick={handleAddItem}>
            <FontAwesomeIcon icon={faPlus} /> ADD AN ITEM
            </Button>
          </div>
          <div className="search-bar">
            <div className="search-bar-inner d-flex">
              <input
                type="text"
                placeholder="Search record"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <button className="search-button" onClick={handleSearch}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </div>
          </div>
        </div>
    </div>


      <div className="user-table">
        <table >
          <thead>
            <tr>
              <th>Name</th>
              <th>Generic Name</th>
              <th>Brand</th>
              <th>Expiration Date</th>
              <th>QTY</th>
              <th>Category</th>
              <th className="fixed-header">Description</th>
              <th className="fixed-header-2">Manufacturer</th>
              <th>Location</th>
              <th>Source</th>
              <th>Batch No.</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {inventory.length > 0 ? (
              inventory.map(item => (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>{item.genericName}</td>
                  <td>{item.brand}</td>
                  <td>{item.expirationDate}</td>
                  <td>{item.qty}</td>
                  <td>{item.category}</td>
                  <td class="fixed-cell">
                <div class="scrollable-content">{item.description}   </div></td>
                <td>{item.manufacturer}</td>
                  <td>{item.storageLocation}</td>
                  <td>{item.source}</td>
                  <td>{item.batchNumber}</td>
                  <td className='fixed-action-th'>
                <button className="btn btn-sm btn-primary me-1"
                   onClick={() =>handleEditItem(item)}><FontAwesomeIcon icon={faEye} /></button>
                <button className="btn btn-sm btn-danger"
                   onClick={() => handleDeleteItem(item._id)}><FontAwesomeIcon icon={faTrash} /></button>
                </td>

                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">No records found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />

    </div> </div>
  );
};

export default InventoryManagement;
