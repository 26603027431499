import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlus, faEye, faTrashAlt, faUserPlus, faFileLines, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { Form, Button, Row, Col, Modal,Alert, Pagination } from 'react-bootstrap';
import { getPrenatalRecords, addPrenatalRecord, deletePrenatalRecord, searchPrenatalRecords } from '../Controller/PrenatalRecord_controller';
import StreetDropdown from './streetDropdownBtn'; 
import PaginationComponent from './Pagination'; 

const HealthWorkerPrenatalRecordUI = () => {
  const [prenatalRecords, setPrenatalRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddRecordModal, setShowAddRecordModal] = useState(false);
  const [newRecord, setNewRecord] = useState({
    fName: '',
    mName: '',
    lName: '',
    dob: '',
    houseNumber: '',
    streetName: '',
    sitio: '',
    occupation: '',
    contactNumber: '',
    emergencyContactPerson: '',
    emergencyContactPersonNumber: '',
    philHealthNumber: '',
    comorbidity: '',
    allergy: '',
    obstetricScoreG: '',
    obstetricScoreT: '',
    obstetricScoreP: '',
    obstetricScoreA: '',
    obstetricScoreL: '',
    lmp: '',
    edc: '',
    tdShotsHistory: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPrenatalRecords(currentPage);
  }, [currentPage]);
  const fetchPrenatalRecords = async (page) => {
    try {
      const records = await getPrenatalRecords(page, 20); // Set the limit as needed
      setPrenatalRecords(records.records);
      setTotalPages(records.totalPages);
    } catch (error) {
      console.error('Error fetching prenatal records:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handleSearch = async () => {
    const filteredRecords = await searchPrenatalRecords(searchQuery);
    setPrenatalRecords(filteredRecords);
  };

  const handleDeleteRecord = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to archive this record?');
    if (!confirmDelete) {
      return;
    }

    try {
      await deletePrenatalRecord(id);
      setPrenatalRecords(prenatalRecords.filter(record => record._id !== id));
      setSuccessMessage('Record archived successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
      fetchPrenatalRecords(currentPage);

    } catch (error) {
      console.error('Error archiving prenatal record:', error);

    }
  };

  const addNewRecord = async () => {
    if (validateForm()) {
      try {
        console.log('Adding new record:', newRecord);
    
        const response = await addPrenatalRecord(newRecord);
        setPrenatalRecords([...prenatalRecords, response]);
        resetNewRecord();
        setShowAddRecordModal(false);
        setSuccessMessage('Record added successfully!');
        setTimeout(() => setSuccessMessage(''), 3000); 
        fetchPrenatalRecords();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          // Check for specific error message
          if (error.response.data.message === 'Record already exists') {
            setFailedMessage('Record already exists. Please check the details and try again.');
          } else {
            setFailedMessage('Error adding record: ' + error.response.data.message);
          }
        } else {
          setFailedMessage('Error adding record. Please try again.');
        }
        setTimeout(() => setFailedMessage(''), 3000); 
        setShowAddRecordModal(false);
      }
    } else {
      console.log('Form validation failed');
    }
  };
  


  const resetNewRecord = () => {
    setNewRecord({
      fName: '',
      mName: '',
      lName: '',
      dob: '',
      houseNumber: '',
      streetName: '',
      sitio: '',
      occupation: '',
      contactNumber: '',
      emergencyContactPerson: '',
      emergencyContactPersonNumber: '',
      philHealthNumber: '',
      comorbidity: '',
      allergy: '',
      obstetricScoreG: '',
      obstetricScoreT: '',
      obstetricScoreP: '',
      obstetricScoreA: '',
      obstetricScoreL: '',
      lmp: '',
      edc: '',
      tdShotsHistory: '',
    });
  };

  const handleViewRecordDetails = (id) => {
    navigate(`/prenatal-record/${id}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!newRecord.fName.trim()) newErrors.fName = 'First Name is required';
    if (!newRecord.lName.trim()) newErrors.lName = 'Last Name is required';
    if (!newRecord.dob) newErrors.dob = 'Date of Birth is required';
    if (!newRecord.streetName.trim()) newErrors.streetName = 'Street name is required';
    if (!newRecord.sitio) newErrors.sitio = 'Sitio is required';
    if (!newRecord.contactNumber.trim()) newErrors.contactNumber = 'Contact Number is required';
    if (!newRecord.emergencyContactPerson.trim()) newErrors.emergencyContactPerson = 'Emergency Contact Person is required';
    if (!newRecord.emergencyContactPersonNumber.trim()) newErrors.emergencyContactPersonNumber = 'Emergency Contact Person Number is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const redirectToArchivedPage = () => {
    navigate('/archived-prenatal-record');
  };

  return (
    <div className="main-content">
      <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="page-header">
        <FontAwesomeIcon icon={faFileLines} className="user-header-icon" />
        PRENATAL RECORD MANAGEMENT
      </div>
      {/* <button className="btn btn-primary" onClick={redirectToArchivedPage}>
          <FontAwesomeIcon icon={faBoxArchive} /> 
        </button> */}
      </div>
      <div className="container-usertable">
        <div className="row">
          <div className="col-md-6">
            <div className="add-user-button">
              <button className="btn btn-primary float-md-start" onClick={() => setShowAddRecordModal(true)}>
              <FontAwesomeIcon icon={faPlus} /> ADD A RECORD
              </button>
            </div>
          </div>
          <div className="col-md-6">
                  <div className="search-bar">
                    <div className="search-bar-inner">
                      <input
                        type="text"
                        placeholder="Search by First Name or Last Name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                      />
                      <button
                        className="search-button"
                        onClick={() => handleSearch(searchQuery)}
                      >
                     <span> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                      </button>
                    </div>
                </div>  
          </div> 
        </div>
        <div className="msg">
        {successMessage && (
          <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
            {successMessage}
          </Alert>
        )}
      </div>
      <div className="msg">
        {failedMessage && (
          <Alert variant="danger" onClose={() => setFailedMessage('')} dismissible>
            {failedMessage}
          </Alert>
        )}
      </div>

          {/* Modal for adding new prenatal record */}
          {showAddRecordModal && (
  <Modal show={showAddRecordModal} onHide={() => setShowAddRecordModal(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>ADD PRENATAL RECORD</Modal.Title>
    </Modal.Header>
    <Modal.Body>
              <Form>
              <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>First Name <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="fName"
                    value={newRecord.fName}
                    isInvalid={!!errors.fName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Middle Initial</Form.Label>
                  <Form.Control
                    type="text"
                    name="mName"
                    value={newRecord.mName}   
                    onChange={handleInputChange}
                    maxLength={1}                      

                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Last Name <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="lName"
                    value={newRecord.lName}
                    isInvalid={!!errors.lName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
          <Form.Label>Date of Birth <span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="date"
            name="dob"
            value={newRecord.dob}
            isInvalid={!!errors.dob}
            onChange={handleInputChange}
          />
        </Form.Group>
   
      <Row>
      <Col>
    <Form.Group>
      <Form.Label>House/Bldg Number</Form.Label>
      <Form.Control
        type="text"
        name="houseNumber"
        value={newRecord.houseNumber}
        onChange={handleInputChange}
        style={{ width: '100%' }} 
      />
    </Form.Group>
  </Col>
  <Col>
  <Form.Group controlId="streetName">
                <Form.Label>Street Name <span style={{ color: 'red' }}>*</span></Form.Label>
                <StreetDropdown
                  name="streetName"
                  value={newRecord.streetName}
                  onChange={handleInputChange}
                  isInvalid={!!errors.streetName}
                />
              </Form.Group>
    </Col>
      </Row>
      <Col >
          <Form.Group>
            <Form.Label>Sitio/Subdivision <span style={{ color: 'red' }}>*</span> </Form.Label>
            <Form.Control
              as="select"
              name="sitio"
              value={newRecord.sitio}
              onChange={handleInputChange}
              isInvalid={!!errors.sitio}
            >
              <option value="">Select Sitio</option>
              {[
                'Lower Zigzag', 'Parugan Blk II', 'Sto. Niño', 'Dalig III', 'Tubigan', 'San Antonio',
                'Nayong Silangan', 'Woodrow', 'Maia Alta', 'Lores Executive', 'Dalaya', 'Sta. Maria',
                'Gloria Heights', 'Cuesta Verde', 'Terrazas Verde', 'Lugo', 'Payong', 'Dalig II', 'Kamatisan',
                'Milagros', 'Aba Homes', 'Upper Zigzag', 'Parugan Blk I', 'Kaytinaga', 'Dalig I',
                'Birmingham', 'Junmar Subd', 'La Terraza de Antipolo', 'Robbis/Courtyard', 'Avida', 'Synergy'
              ].map((sitio) => (
                <option key={sitio} value={sitio}>
                  {sitio}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
         <Row> <Col md={6}>
                <Form.Group>
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    name="occupation"
                    value={newRecord.occupation}
                    onChange={handleInputChange}
                    style={{ width: '100%' }} 
                  />
                </Form.Group></Col>
                <Col md={6}><Form.Group>
                  <Form.Label>Contact Number <span style={{ color: 'red' }}>*</span> </Form.Label>
                  <Form.Control
                    type="number"
                    name="contactNumber"
                    value={newRecord.contactNumber}
                    onChange={handleInputChange}
                    isInvalid={!!errors.contactNumber}
                    style={{ width: '100%' }} 
                  />
                </Form.Group> </Col></Row>

               <Row> <Col md={6}> <Form.Group>
                  <Form.Label>Emergency Contact Person <span style={{ color: 'red' }}>*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    name="emergencyContactPerson"
                    value={newRecord.emergencyContactPerson}
                    isInvalid={!!errors.emergencyContactPerson}
                    onChange={handleInputChange}
                    style={{ width: '100%' }} 
                  />
                </Form.Group></Col>
                <Col md={6}><Form.Group>
                  <Form.Label>Emergency Contact Person Number <span style={{ color: 'red' }}>*</span> </Form.Label>
                  <Form.Control
                    type="number"
                    name="emergencyContactPersonNumber"
                    value={newRecord.emergencyContactPersonNumber}
                    isInvalid={!!errors.emergencyContactPersonNumber}
                    onChange={handleInputChange}
                    style={{ width: '100%' }} 
                  />
                </Form.Group> </Col></Row>

                <Row><Col md={6}><Form.Group>
                  <Form.Label>PhilHealth Number</Form.Label>
                  <Form.Control
                    type="number"
                    name="philHealthNumber"
                    style={{ width: '100%' }} 
                    value={newRecord.philHealthNumber}
                    onChange={handleInputChange}
                  />
                </Form.Group> </Col>
                <Col md={6}> <Form.Group>
                  <Form.Label>Comorbidity</Form.Label>
                  <Form.Control
                    type="text"
                    name="comorbidity"
                    style={{ width: '100%' }} 
                    value={newRecord.comorbidity}
                    onChange={handleInputChange}
                  />
                </Form.Group> </Col></Row>
                <Row>
                <Form.Group>
                  <Form.Label>Allergy</Form.Label>
                  <Form.Control
                    type="text"
                    name="allergy"
                    placeholder="Allergy"
                    style={{ width: '100%' }} 
                    value={newRecord.allergy}
                    onChange={handleInputChange}
                  />
                </Form.Group></Row>

                <Row>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Obstetric Score G</Form.Label>
                      <Form.Control
                        type="number"
                        name="obstetricScoreG"
                        value={newRecord.obstetricScoreG}
                        onChange={handleInputChange}
                        style={{ width: '100%' }} 
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Obstetric Score T</Form.Label>
                      <Form.Control
                        type="number"
                        name="obstetricScoreT"
                        value={newRecord.obstetricScoreT}
                        onChange={handleInputChange}
                        style={{ width: '100%' }} 
                      />
                    </Form.Group>
                  </Col>

                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Obstetric Score P</Form.Label>
                      <Form.Control
                        type="number"
                        name="obstetricScoreP"
                        value={newRecord.obstetricScoreP}
                        onChange={handleInputChange}
                        style={{ width: '100%' }} 
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Obstetric Score A</Form.Label>
                      <Form.Control
                        type="number"
                        name="obstetricScoreA"
                        value={newRecord.obstetricScoreA}
                        onChange={handleInputChange}
                        style={{ width: '100%' }} 
                      />
                    </Form.Group>
                  </Col>

                  <Col md={2}>
                    <Form.Group>
                      <Form.Label>Obstetric Score L</Form.Label>
                      <Form.Control
                        type="number"
                        name="obstetricScoreL"
                        value={newRecord.obstetricScoreL}
                        onChange={handleInputChange}
                        style={{ width: '100%' }} 
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col md={6}><Form.Group>
                  <Form.Label>Last Menstrual Period (LMP)</Form.Label>
                  <Form.Control
                    type="date"
                    name="lmp"
                    value={newRecord.lmp}
                    onChange={handleInputChange}
                  />
                </Form.Group></Col>
                <Col md={6}> <Form.Group>
                  <Form.Label>Estimated Date of Confinement (EDC)</Form.Label>
                  <Form.Control
                    type="date"
                    name="edc"
                   
                    value={newRecord.edc}
                    onChange={handleInputChange}
                  />
                </Form.Group>  </Col> </Row>
                <Row> <Form.Group>
                  <Form.Label>TD Shots History</Form.Label>
                  <Form.Control
                    type="text"
                    name="tdShotsHistory"
                    style={{ width: '100%' }} 
                    value={newRecord.tdShotsHistory}
                    onChange={handleInputChange}
                  />
                </Form.Group></Row>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button className="btn btn-primary" onClick={addNewRecord}>
                 ADD RECORD
                </Button>    </Modal.Footer>
             
    </Modal>
  )}         <div className="user-table">

      <table>
        <thead className="thead-dark">
          <tr>
            <th>First Name</th>
            <th>M.I</th>
            <th>Last Name</th>
            <th>Date of Birth</th>
            <th>Contact Number</th>
            <th>Key</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {prenatalRecords.map((record) => (
            <tr key={record._id}>
              <td>{record.fName}</td>
              <td>{record.mName}</td>
              <td>{record.lName}</td>
              <td>{record.dob}</td>
              <td>{record.contactNumber}</td>
              <td>{record.prenatalRecordId?.substring(0, 10)}</td>
              <td className='fixed-action-th'>
                <button
                  className="btn btn-sm btn-primary me-2"
                  onClick={() => handleViewRecordDetails(record._id)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              {/*  <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDeleteRecord(record._id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
     
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      </div>
    </div>
  );
};

export default HealthWorkerPrenatalRecordUI;
