import { firebaseConfig } from '../firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Login function
async function login(username, password) {
  try {
    if (!username || !password) {
      throw new Error('Email and password are required');
    }

    // Sign in the user with Firebase Auth
    let userCredential;
    try {
      userCredential = await firebase.auth().signInWithEmailAndPassword(username, password);
    } catch (firebaseError) {
      // Handle Firebase-specific errors
      if (firebaseError.code === 'auth/invalid-email') {
        throw new Error('The email address is badly formatted.');
      }
      if (firebaseError.code === 'auth/wrong-password') {
        throw new Error('Incorrect password. Please try again.');
      }
      if (firebaseError.code === 'auth/user-not-found') {
        throw new Error('No user found with this email.');
      }
      if (firebaseError.code === 'auth/invalid-credential') {
        throw new Error('Invalid credentials. Please try again.');
      }
      if (firebaseError.code === 'auth/too-many-requests') {
        throw new Error('Account temporarily disabled due to too many failed attempts. Reset your password or try later.');
      }
      throw new Error(firebaseError.message);
    }

    const token = await userCredential.user.getIdToken();

    // Send the token to the backend
    const response = await fetch('https://onecare-web-36d0b9bac382.herokuapp.com/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `Server returned status: ${response.status}`);
    }

    const data = await response.json();

    // Assuming the response includes the user role
    return { token, role: data.role };
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}

// Reset Password function
async function resetPassword(email) {
  try {
    if (!email) {
      throw new Error('Email is required.');
    }

    // Send the email to the backend for processing
    const response = await fetch('https://onecare-web-36d0b9bac382.herokuapp.com/api/auth/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }), // Send email to the backend
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `Server returned status: ${response.status}`);
    }

    return { message: 'Password reset email sent.' };
  } catch (error) {
    console.error('Error sending password reset request:', error);
    throw error;
  }
}

export { login, resetPassword };
