import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faChartLine, faBoxes, faCalendar, faCalendarCheck, faFileLines, faFilePrescription, faDesktop, faPrescriptionBottleMedical } from '@fortawesome/free-solid-svg-icons';
import './sideBar.css';

function Sidebar({ userRole }) {
  return (
    <div className="sidebar">
      <div className="sidebar-header"></div>
      <ul>
        {userRole === 'Admin' && (
          <>
            <div className="sidebar-section">MAIN</div>
            <li>
              <Link to="/admin-dashboard" className="sidebar-link">
                <FontAwesomeIcon icon={faDesktop} className="sidebar-icon" /> Dashboard
              </Link>
            </li>
            <li>
              <Link to="/dashboard" className="sidebar-link">
                <FontAwesomeIcon icon={faChartLine} className="sidebar-icon" /> Health Trend
              </Link>
            </li>
            <div className="sidebar-section">MANAGE</div>
            <li>
              <Link to="/user-management" className="sidebar-link">
                <FontAwesomeIcon icon={faUsers} className="sidebar-icon" /> User Management
              </Link>
            </li>
            <li>
              <Link to="/schedule-management" className="sidebar-link">
                <FontAwesomeIcon icon={faCalendar} className="sidebar-icon" /> Schedule Management
              </Link>
            </li>

            <li>
              <Link to="/patient-record" className="sidebar-link">
                <FontAwesomeIcon icon={faFileLines} className="sidebar-icon" /> Patient Record
              </Link>
            </li>
            <li>
              <Link to="/inventory-management" className="sidebar-link">
                <FontAwesomeIcon icon={faBoxes} className="sidebar-icon" /> Inventory
              </Link>
            </li>
            <li>
              <Link to="/admin-distributed-medicine" className="sidebar-link">
              <FontAwesomeIcon icon={faFilePrescription} className="sidebar-icon" /> Distribution Records
              </Link>
            </li>
            

          </>
        )}
         {userRole === 'Super Admin' && (
          <>
            <div className="sidebar-section">MAIN</div>
            <li>
              <Link to="/admin-dashboard" className="sidebar-link">
                <FontAwesomeIcon icon={faDesktop} className="sidebar-icon" /> Dashboard
              </Link>
            </li>
            <li>
              <Link to="/dashboard" className="sidebar-link">
                <FontAwesomeIcon icon={faChartLine} className="sidebar-icon" /> Health Trend
              </Link>
            </li>
            <div className="sidebar-section">MANAGE</div>
            <li>
              <Link to="/super-user-management" className="sidebar-link">
                <FontAwesomeIcon icon={faUsers} className="sidebar-icon" /> User Management
              </Link>
            </li>
    
            <li>
              <Link to="/schedule-management" className="sidebar-link">
                <FontAwesomeIcon icon={faCalendar} className="sidebar-icon" /> Schedule Management
              </Link>
            </li>
      
            <li>
              <Link to="/patient-record" className="sidebar-link">
                <FontAwesomeIcon icon={faFileLines} className="sidebar-icon" /> Patient Record
              </Link>
            </li>
            <li>
              <Link to="/inventory-management" className="sidebar-link">
                <FontAwesomeIcon icon={faBoxes} className="sidebar-icon" /> Inventory
              </Link>
            </li>
            <li>
              <Link to="/admin-distributed-medicine" className="sidebar-link">
              <FontAwesomeIcon icon={faFilePrescription} className="sidebar-icon" /> Distribution Records
              </Link>
            </li>
          </>
        )}
        {userRole === 'Health Worker' && (
          <>  <div className="sidebar-section">MAIN</div>
          <li>
            <Link to="/health-worker-dashboard" className="sidebar-link">
              <FontAwesomeIcon icon={faDesktop} className="sidebar-icon" /> Dashboard
            </Link>
          </li>
            <div className="sidebar-section">MANAGE</div>
            <li>
              <Link to="/child-health" className="sidebar-link">
                <FontAwesomeIcon icon={faFileLines} className="sidebar-icon" /> Child Health Record
              </Link>
            </li>
            <li>
              <Link to="/prenatal-record" className="sidebar-link">
                <FontAwesomeIcon icon={faFileLines} className="sidebar-icon" /> Prenatal Record
              </Link>
            </li>
            <li>
              <Link to="/family-planning" className="sidebar-link">
                <FontAwesomeIcon icon={faFileLines} className="sidebar-icon" /> Family Planning Record
              </Link>            </li>

              <li>
              <Link to="/medicine-dispense" className="sidebar-link">
              <FontAwesomeIcon icon={faPrescriptionBottleMedical} className="sidebar-icon" /> Medicine and Vitamins
              </Link>
            </li>
        

            
          </>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
