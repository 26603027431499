import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild, faPersonPregnant, faHeart } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'; 

function PatientRecord() {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="patient-module-bg">
         <div className="container">
        <div className="patient-module-header">PATIENT RECORD TYPES</div>
        <div className="row">
          <div className="col-md-4">
            <div className="patient-module-card" onClick={() => handleNavigate('/child-health')}>
              <FontAwesomeIcon icon={faChild} size="3x" className="module-icon" />
              <h4>Child Health Record</h4>
              <p>Manage child health records.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="patient-module-card" onClick={() => handleNavigate('/prenatal-record')}>
              <FontAwesomeIcon icon={faPersonPregnant} size="3x" className="module-icon" />
              <h4>Prenatal Record</h4>
              <p>Manage prenatal records.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="patient-module-card" onClick={() => handleNavigate('/family-planning')}>
              <FontAwesomeIcon icon={faHeart} size="3x" className="module-icon" />
              <h4>Family Planning Record</h4>
              <p>Manage family planning records.</p>
            </div>
          </div>
          </div>
        </div>
      </div>
  );
}

export default PatientRecord;
