import React, { useState, useEffect } from 'react';
import { Table, Button, Alert, Modal, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faLock, faUser,faPlus } from '@fortawesome/free-solid-svg-icons';
import { getAdminUsers, addAdminUser, updateAdminUser, deleteAdminUser, resetAdminPassword,  resendVerificationEmail, checkAdminVerificationStatus } from '../Controller/admin_controller';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function AdminPage() {
  const [showModal, setShowModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null); // For add/update modal
  const [passwordResetError, setPasswordResetError] = useState(null); // For password reset modal
  
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    fName: '',
    mName: '',
    lName: '',
    address: '',
    role: 'Admin',
  });

  const [passwordResetData, setPasswordResetData] = useState({
    newPassword: '',
    confirmNewPassword: ''
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getAdminUsers();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching admin:', error);
      setError('Failed to fetch admin.');
    }
  };

  const handleShowModal = (user = null) => {
    if (user) {
      setIsUpdating(true);
      setFormData({
        email: user.email,
        password: '',
        confirmPassword: '',
        fName: user.fName,
        mName: user.mName,
        lName: user.lName,
        address: user.address,
        role: 'Admin',
      });
      setCurrentUser(user);
    } else {
      setIsUpdating(false);
      setFormData({
        email: '',
        password: '',
        confirmPassword: '',
        fName: '',
        mName: '',
        lName: '',
        address: '',
        role: 'Admin',
      });
      setCurrentUser(null);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleShowPasswordResetModal = (user) => {
    setCurrentUser(user); // Set the current user
    setPasswordResetData({
      newPassword: '',
      confirmNewPassword: ''
    });
    setShowPasswordResetModal(true);
  };

  const handleClosePasswordResetModal = () => {
    setShowPasswordResetModal(false);
    setError(null); // Clear error when closing
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePasswordResetInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordResetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null); // Clear previous error
  
    if (!isUpdating) {
      if (formData.password.length < 10) {
        setFormError('Password must be at least 10 characters long.');
        return;
      }
  
      if (formData.password !== formData.confirmPassword) {
        setFormError('Passwords do not match.');
        return;
      }
    }
  
    try {
      const dataToSubmit = { ...formData };
      if (isUpdating) {
        delete dataToSubmit.email;
      }
  
      if (isUpdating) {
        await updateAdminUser(currentUser._id, dataToSubmit);
        setSuccessMessage('Admin user updated successfully!');
      } else {
        await addAdminUser(formData);
        setSuccessMessage('Admin user added successfully!');
      }
      fetchUsers();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving admin:', error);
      setFormError('Failed to save admin.');
    }
  };
  
  const handlePasswordResetSubmit = async (e) => {
    e.preventDefault();
    setPasswordResetError(null); // Clear previous error
  
    if (!currentUser) {
      setPasswordResetError('No user is currently selected for password reset.');
      return;
    }
  
    if (passwordResetData.newPassword.length < 10) {
      setPasswordResetError('Password must be at least 10 characters long.');
      return;
    }
  
    if (passwordResetData.newPassword !== passwordResetData.confirmNewPassword) {
      setPasswordResetError('Passwords do not match.');
      return;
    }
  
    try {
      await resetAdminPassword(currentUser.email, passwordResetData.newPassword);
      setSuccessMessage('Password reset successfully!');
      handleClosePasswordResetModal();
    } catch (error) {
      console.error('Error resetting password:', error);
      setPasswordResetError('Failed to reset password.');
    }
  };
  

  const handleDelete = async (id) => {
    const confirmation = window.prompt("Please type DELETE ADMIN to confirm deletion.");
  if (confirmation === "DELETE ADMIN") {
    if (window.confirm("Are you absolutely sure you want to delete this admin? This action cannot be undone!")) {
      try {
        await deleteAdminUser(id);
        setSuccessMessage('Admin deleted successfully!');
        fetchUsers();
      } catch (error) {
        setError('Failed to delete admin.');
      }
    }
  } else {
    alert("Deletion canceled. Please type DELETE ADMIN to confirm.");
  }

   
  };
  
  const [globalError, setGlobalError] = useState(null); // Added for global error messages
  const [globalSuccessMessage, setGlobalSuccessMessage] = useState(''); // Added for global success messages
  const handleCheckVerificationStatus = async (user) => {
    try {
      const response = await checkAdminVerificationStatus(user.uid);
  
      if (response.message === 'Email is verified and status updated to verified.') {
        setGlobalSuccessMessage(`Admin ${user.fName} ${user.lName}'s email is verified.`);
        setTimeout(() => setGlobalError(false), 3000);
      } else if (response.message === 'Email is not verified yet.') {
  
        // prompt admin to resend verification
        const confirmResend = window.confirm('Email is not verified. Do you want to resend the verification email?');
        if (confirmResend) {
          await resendVerificationEmail(user.uid);
          setGlobalSuccessMessage('Verification email resent successfully.');
          setTimeout(() => setGlobalSuccessMessage(false), 3000);
        }
      } else {
        setGlobalError(`${response.message}`);
        setTimeout(() => setGlobalError(false), 3000);
      }
  
      fetchUsers(); // Refresh the user list to show updated status
    } catch (error) {
      console.error('Error checking verification status:', error);
      setGlobalError('Failed to check verification status.');
      setTimeout(() => setGlobalError(false), 3000);
    }
  };

  return (
    <div className="main-content">
      <div className="page-header">
        <FontAwesomeIcon icon={faUser} className="user-header-icon" />
        ADMIN MANAGEMENT
      </div>
      <div className='msg'>
      {globalError && <Alert variant="danger" className="mt-3">{globalError}</Alert>}
      {globalSuccessMessage && <Alert variant="success" className="mt-3">{globalSuccessMessage}</Alert>}</div>
      <div className="container-usertable">
        <div className="row">
          <div className="col-md-12"> <div className="add-user-button">
            <Button className="btn btn-primary" onClick={() => handleShowModal()}>
            <FontAwesomeIcon icon={faPlus} /> ADD ADMIN
            </Button>
          </div></div>
        </div>
      </div>

      <div className="container-usertable">
        {successMessage && !showPasswordResetModal && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
        {error && !showPasswordResetModal && <Alert variant="danger" className="mt-3">{error}</Alert>}
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th class="fixed-header">Address</th>
                <th>Role</th>
                <th>Created</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.email}</td>
                  <td>{user.fName}</td>
                  <td>{user.mName}</td>
                  <td>{user.lName}</td>
                  <td className="fixed-cell">
                <div className="scrollable-content">{user.address}</div></td>
                  <td>{user.role}</td>
                  <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                  <td>
                    <Button className="btn btn-sm btn-primary me-2" onClick={() => handleShowModal(user)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button className="btn btn-sm btn-danger me-2" onClick={() => handleDelete(user._id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  {/*  <Button className="btn btn-sm btn-secondary me-2" onClick={() => handleShowPasswordResetModal(user)}>
                      <FontAwesomeIcon icon={faLock} />
                    </Button> */}
                    <Button className="btn btn-sm btn-success " onClick={() => handleCheckVerificationStatus(user)}>
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Main Modal for Add/Update */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdating ? 'Update Admin' : 'Add Admin'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {formError && <Alert variant="danger" className="mb-3">{formError}</Alert>}
        <Form  id="my-form" onSubmit={handleSubmit} >
            {!isUpdating && (
              <Form.Group controlId="formEmail">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            )}
            {!isUpdating && (
              <>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </>
            )}
            <Row>
              <Col>
              <Form.Group controlId="formFName">
              <Form.Label>First Name <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                name="fName"
                value={formData.fName}
                onChange={handleInputChange}
                required
              />
            </Form.Group></Col>
            <Col><Form.Group controlId="formMName">
              <Form.Label>Middle Initial</Form.Label>
              <Form.Control
                type="text"
                name="mName"
                value={formData.mName}
                maxLength={1}
                onChange={handleInputChange}
              />
            </Form.Group></Col>
            <Col><Form.Group controlId="formLName">
              <Form.Label>Last Name  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                name="lName"
                value={formData.lName}
                onChange={handleInputChange}
                required
              />
            </Form.Group></Col> </Row>
            <Form.Group controlId="formAddress">
              <Form.Label>Address  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                name="role"
                value={formData.role}
                readOnly
              />
            </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" type="submit" className="mt-3" form ="my-form">
              {isUpdating ? 'Update' : 'Add'}
            </Button> </Modal.Footer>
         
      </Modal>

      {/* Password Reset Modal */}
      <Modal show={showPasswordResetModal} onHide={handleClosePasswordResetModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {passwordResetError && <Alert variant="danger" className="mb-3">{passwordResetError}</Alert>}
        <Form onSubmit={handlePasswordResetSubmit}>
            <Form.Group controlId="formNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={passwordResetData.newPassword}
                onChange={handlePasswordResetInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formConfirmNewPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmNewPassword"
                value={passwordResetData.confirmNewPassword}
                onChange={handlePasswordResetInputChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Reset Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AdminPage;