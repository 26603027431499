import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTrash, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Alert, Pagination } from 'react-bootstrap';
import { deleteDispensedRecord, searchDistributedRecords, getDispensedRecordsByDate, fetchMedAndVitRecordsToday } from './Controller/inventory_controller';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import PaginationComponent from './UI/Pagination'; 

const MedicineDispensedRecords = () => {
  const [dispensedRecords, setDispensedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setStartDate(today);
    setEndDate(today);
    fetchDispensedRecords(1, today, today);
  }, []); // empty dependency array to run only once on mount

  const fetchDispensedRecords = async (page = 1, start = startDate, end = endDate) => {
    try {
      const { records, totalPages } = await getDispensedRecordsByDate(page, start, end);
      setDispensedRecords(records);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error(error.message);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Handle page data loading or fetching here
    console.log(`Selected page: ${page}`);
  };
  const handleDeleteRecord = async (recordId) => {
    const confirmDelete = window.confirm('Are you sure you want to archive this record?');
    if (!confirmDelete) {
      return;
    }
    try {
      await deleteDispensedRecord(recordId);
      fetchDispensedRecords(currentPage, startDate, endDate); // Refresh after deletion
      setSuccessMessage('Record deleted successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error(error.message);
      setFailedMessage('Record deletion failed!');
      setTimeout(() => setFailedMessage(''), 3000);
    }
  };

  const handleSearch = async () => {
    try {
      const filteredRecords = await searchDistributedRecords(searchQuery, startDate, endDate);
      setDispensedRecords(filteredRecords);
      setTotalPages(1); // search results are shown on one page
      setCurrentPage(1); // reset to page 1
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (startDate || endDate) {
      fetchDispensedRecords(currentPage, startDate, endDate); // fetch records when date range or page changes
    }
  }, [startDate, endDate, currentPage]); 

  const exportToExcel = async () => {

    const confirmExport = window.confirm('Do you want to export data as excel file?');
    if (!confirmExport) {
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Dispensed Records');
  
    //  row
    worksheet.mergeCells('A1:L1');
    const titleRow = worksheet.getCell('A1');
    titleRow.value = `Dispensed Medicine and Vitamins Report (${startDate} to ${endDate})`;
    titleRow.font = { bold: true, size: 16 };
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
  
    const headers = [
      "Name", "Generic Name", "Brand", "Manufacturer", "Batch No.", "Located", 
      "Source", "Patient Name", "Quantity", "Date Given", "Given By", "Notes"
    ];
    
    worksheet.addRow(headers);
    worksheet.getRow(2).eachCell(cell => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
  
    dispensedRecords.forEach(record => {
      worksheet.addRow([
        record.name, record.genericName, record.brand, record.manufacturer,
        record.batchNumber, record.storageLocation, record.source,
        `${record.patientFName} ${record.patientLName}`, record.qtyToGive,
        record.dispenseDate, record.dispenseBy, record.remarks
      ]);
    });
  
    worksheet.columns = [
      { width: 15 }, { width: 15 }, { width: 10 }, { width: 15 }, { width: 15 },
      { width: 10 }, { width: 10 }, { width: 20 }, { width: 10 }, { width: 15 },
      { width: 20 }, { width: 40 }
    ];
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    saveAs(blob, `Dispensed_Medicine_Records_${startDate}_to_${endDate}.xlsx`);
  };
  return (
    <div className="main-content">
      <div className="page-header">DISTRIBUTED MEDICINE AND VITAMINS RECORDS</div>
      <div className="container-usertable">
      <div className="row align-items-center">
        <div className="col-md-6 d-flex align-items-center">
          <div className="d-flex align-items-center me-3">
            <p className="mb-0 me-2">From:</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
              className="form-control date-input"
            />
          </div>
          <div className="d-flex align-items-center">
            <p className="mb-0 me-2">To:</p>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
              className="form-control date-input"
            />
          </div>
        </div>

        <div className="col-md-6 d-flex justify-content-end align-items-center">
          <div className="search-bar me-3">
            <div className="search-bar-inner d-flex">
              <input
                type="text"
                placeholder="Search record"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <button className="search-button" onClick={handleSearch}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </div>
          </div>
          <button className="btn btn-lg btn-success export-btn" onClick={exportToExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
          </button>
        </div>
      </div>

        <div className="msg">
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
              {successMessage}
            </Alert>
          )}
        </div>
        <div className="msg">
          {failedMessage && (
            <Alert variant="danger" onClose={() => setFailedMessage('')} dismissible>
              {failedMessage}
            </Alert>
          )}
        </div>
        
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Generic Name</th>
                <th>Brand</th>
                <th>Manufacturer</th>
                <th>Batch No.</th>
                <th>Located</th>
                <th>Source</th>
                <th>Patient Name</th>
                <th>QTY</th>
                <th>Date Given</th>
                <th>Given By</th>
                <th className="fixed-header-2">Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dispensedRecords.length === 0 ? (
                <tr>
                  <td colSpan="13" className="text-center">No records found</td>
                </tr>
              ) : (
                dispensedRecords.map((record) => (
                  <tr key={record._id}>
                    <td>{record.name}</td>
                    <td>{record.genericName}</td>
                    <td>{record.brand}</td>
                    <td>{record.manufacturer}</td>
                    <td>{record.batchNumber}</td>
                    <td>{record.storageLocation}</td>
                    <td>{record.source}</td>
                    <td>{`${record.patientFName} ${record.patientLName}`}</td>
                    <td>{record.qtyToGive}</td>
                    <td>{record.dispenseDate}</td>
                    <td>{record.dispenseBy}</td>
                    <td class="fixed-cell">
                    <div class="scrollable-content">{record.remarks}</div></td>
                    <td>
                      <button className="btn btn-sm btn-danger me-2" onClick={() => handleDeleteRecord(record._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      </div>
    </div>
  );
};

export default MedicineDispensedRecords;
