import axios from 'axios';

const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com';

export const getPatients = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/records/patients`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addPatient = async (patient) => {
  try {
    const response = await axios.post(`${BASE_URL}/records/patient_records`, patient);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePatient = async (id) => {
  try {
    console.log(`Deleting patient with ID: ${id}`); 
    await axios.delete(`${BASE_URL}/records/patients/${id}`);
    console.log(`Successfully deleted patient with ID: ${id}`);
  } catch (error) {
    console.error(`Error deleting patient with ID: ${id}`, error); 
  }
};


export const getPatientById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/records/patients/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const addVaccineRecord = async (patientId, record) => {
  try {
    const response = await axios.post(`${BASE_URL}/records/patients/${patientId}/vaccines`, record);
    console.log('Add Vaccine Record Request:', `${BASE_URL}/records/patients/${patientId}/vaccines`, record);
    console.log('Add Vaccine Record Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding vaccine record:', error);
    throw error;
  }
};

export const deleteVaccineRecord = async (id) => {
  try {
    console.log(`Deleting vaccine record with ID: ${id}`);
    await axios.delete(`${BASE_URL}/records/patients/${id}/vaccineRecords`);
    console.log(`Successfully deleted vaccine record with ID: ${id}`);
  } catch (error) {
    console.error(`Error deleting vaccine record with ID: ${id}`, error);
    throw error;
  }
};

export const fetchVaccineRecord = async (patientId) => {
  try {
    const response = await axios.get(`${BASE_URL}/records/patients/${patientId}/vaccineRecords`);
    return response.data || [];
  } catch (error) {
    throw error;
  }
};
export async function updateVaccineRecord(recordId, updatedRecord) {
  try {
    //copy of the updatedRecord without _id field
    const { _id, ...recordToUpdate } = updatedRecord;
    await axios.put(`https://onecare-web-36d0b9bac382.herokuapp.com/records/patients/${updatedRecord.patientId}/vaccineRecords/${recordId}`, recordToUpdate);
  } catch (error) {
    console.error('Error saving vaccine record:', error.message, error.stack);
    throw error;
  }
}

export const updatePatientDetails = async (id, updatedDetails) => {
  try {
    // exclude _id field from the updatedDetails object
    const { _id, ...rest } = updatedDetails;
    
    const response = await axios.put(`${BASE_URL}/records/patients/${id}`, rest, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
