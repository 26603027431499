import axios from 'axios';

const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api'; 

export const getInventory = async (page = 1, limit = 20, startDate = '', endDate = '') => {
  try {
    
    console.log("Start datse",startDate );
    console.log("End date",endDate );
    const response = await axios.get(`${BASE_URL}/inventory/get-inventory`, {
      params: { page, limit, startDate, endDate } // Send page, limit, startDate, and endDate as query parameters
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};


export const getInventoryByHealthCenter = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/get-inventory-by-healthcenter`, {
      params: { page, limit }, // Send page and limit as query parameters
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};

export const addItem = async (item) => {
  try {
    console.log("Adding item:", item);
    const response = await axios.post(`${BASE_URL}/inventory/add-inventory`, item);
    return response.data;
  } catch (error) {
    console.error('Error adding item:', error);
    throw error;
  }
};

export const updateItem = async (itemId, item) => {
  try {
    console.log("Updating item:", itemId, item);
    const response = await axios.put(`${BASE_URL}/inventory/update-inventory/${itemId}`, item);
    return response.data;
  } catch (error) {
    console.error('Error updating item:', error);
    throw error;
  }
};

export const deleteItem = async (itemId) => {
  try {
    console.log("Deleting item:", itemId);
    await axios.post(`${BASE_URL}/inventory/archive-inventory/${itemId}`);
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const searchItem = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/search-inventory`, {
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching search item:", error);
    return { items: [], totalItems: 0, totalPages: 0, currentPage: 1 }; // Ensure consistent structure
  }
};


export const searchItemByHealthCenter = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/search-inventory-by-healthcenter`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching search item:", error);
    return { items: [], totalItems: 0, totalPages: 0, currentPage: 1 }; // Ensure consistent structure
  }
};

export const dispenseMedicine = async (dispenseData) => {
  try {
    const response = await axios.post(`${BASE_URL}/inventory/give-medicine`, dispenseData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // Handle specific case for 400 status code (insufficient stock)
      console.error('Error updating item: Insufficient stock');
      throw new Error('Insufficient stock');
    } else {
    console.error('Error dispensing medicine:', error);
    throw error; }
  }
};

export const getDispensedRecords = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/dispensed-records`, {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching dispensed records:', error);
  }
};

export const getDispensedRecordsByDate = async (page = 1, startDate, endDate, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/dispensed-records-by-date`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: { page, startDate, endDate, limit } // Send page, date range, and limit as query parameters
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dispensed records by date:', error);
    throw error;
  }
};
//admin side
export const getDispensedRecordsByDateAll = async (page = 1, startDate, endDate, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/all-dispensed-records-by-date`, {
    
      params: { page, startDate, endDate, limit } // Send page, date range, and limit as query parameters
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching dispensed records by date:', error);
    throw error;
  }
};
// Function to delete a dispensed record
export const deleteDispensedRecord = async (recordId) => {
  try {
    await axios.post(`${BASE_URL}/inventory/archive-dispensed-records/${recordId}`);
  } catch (error) {
    console.log(error);
    throw new Error('Error archiving record:', error);
  }
};
//health worker side
export const searchDistributedRecords = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/search-distributed-records`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: { search: searchQuery }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching search item:", error);
    return [];
  }
};
//admin side of searching med and vit distribution record
export const searchDistributedRecordsAll = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/all-search-distributed-records`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching search item:", error);
    return [];
  }
};
//fethc number of medicine and vitamins records distributed today
export const fetchMedAndVitRecordsToday = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/get-med-vit-records-today`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch medicine and vitamins records today:', error);
    throw error;
  }
};

// Fetch archived dispensed records with pagination
export const getArchivedDispensedRecords = async (page = 1, limit = 20, startDate = '', endDate = '') => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/archived-dispensed-records`, {
      params: { 
        page,
        limit,
        startDate,
        endDate 
      } // Send page, limit, startDate, and endDate as query parameters
    });
    return response.data; // Assuming response.data contains { records, totalPages }
  } catch (error) {
    console.error('Error fetching archived records:', error);
    throw error;
  }
};

// Restore an archived dispensed record by ID
export const restoreDispensedRecord = async (recordId) => {
  try {
    const response = await axios.post(`${BASE_URL}/inventory/restore-dispensed-record`, {
      recordId
    });
    return response.data; // Assuming response.data contains the success message
  } catch (error) {
    console.error('Error restoring dispensed record:', error);
    throw error;
  }
};


export const searchArchivedDistributedRecordsAll = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/search-archived-distributed-records`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: { search: searchQuery }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching search item:", error);
    return [];
  }
};

// Fetch archived inventory records with pagination
export const getArchivedInventoryRecords = async (page = 1, limit = 20, startDate = '', endDate = '') => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/archived-inventory-records`, {
      params: { 
        page,
        limit,
        startDate,
        endDate 
      } // Send page, limit, startDate, and endDate as query parameters
    });
    return response.data; // Assuming response.data contains { records, totalPages }
  } catch (error) {
    console.error('Error fetching archived records:', error);
    throw error;
  }
};

// Restore an archived inventory record by ID
export const restoreInventoryRecord = async (recordId) => {
  try {
    const response = await axios.post(`${BASE_URL}/inventory/restore-inventory-record`, {
      recordId
    });
    return response.data; // Assuming response.data contains the success message
  } catch (error) {
    console.error('Error restoring inventory record:', error);
    throw error;
  }
};


export const searchArchivedInventoryRecords   = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/inventory/search-archived-inventory-records`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      },
      params: { search: searchQuery }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching search item:", error);
    return [];
  }
};