import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { fetchDescriptiveResponse, fetchAnalyticsData, fetchTimeSeriesData } from './Controller/analytics_controller';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, 
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation'; 
import './mapStyle.css';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFileExcel } from '@fortawesome/free-solid-svg-icons';
ChartJS.register(annotationPlugin, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement);

const chartOptions = {
  responsive: true,
  scales: {
    x: { stacked: true },
    y: { stacked: true },
  },
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

const DataAnalytics = ({ selectedIllness, selectedMonth, selectedYear }) => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [timeSeriesData, setTimeSeriesData] = useState(null);
  const [descriptiveData, setDescriptiveData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      try {
        const analyticsResponse = await fetchAnalyticsData(selectedIllness, selectedMonth, selectedYear);
        const timeSeriesResponse = await fetchTimeSeriesData(selectedIllness, selectedYear);
        const descriptiveResonse = await fetchDescriptiveResponse(selectedIllness, selectedMonth, selectedYear);

        setAnalyticsData(analyticsResponse);
        setTimeSeriesData(timeSeriesResponse);
        setDescriptiveData(descriptiveResonse); 
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedIllness && selectedMonth && selectedYear) {
      fetchData();
    }
  }, [selectedIllness, selectedMonth, selectedYear]);

  const prepareSitioCountsChartData = (sitioCounts) => {
    const diagnosisTypes = [
      ...new Set(sitioCounts.flatMap(sitio => Object.keys(sitio.diagnosisBreakdown || {}))),
    ];

    const datasets = diagnosisTypes.map((diagnosisType, index) => ({
      label: diagnosisType,
      data: sitioCounts.map(sitio => sitio.diagnosisBreakdown[diagnosisType] || 0),
      backgroundColor: getColorForDiagnosis(index),
      borderColor: 'rgba(255, 255, 255, 1)',
      borderWidth: 1,
    }));

    return {
      labels: sitioCounts.map(sitio => sitio.sitio),
      datasets: datasets,
    };
  };

  const getColorForDiagnosis = (index) => {
    const colors = [
      'rgba(75, 192, 192, 0.6)',
      'rgba(255, 99, 132, 0.6)',
      'rgba(54, 162, 235, 0.6)',
      'rgba(255, 206, 86, 0.6)',
      'rgba(153, 102, 255, 0.6)',
      'rgba(255, 159, 64, 0.6)',
    ];
    return colors[index % colors.length];
  };

  // Prepare Pie chart data for Sex Distribution
  const prepareSexDistributionData = () => {
    if (!analyticsData || !analyticsData.sexDistribution) return {};

    return {
      labels: ['Male', 'Female'],
      datasets: [
        {
          data: [analyticsData.sexDistribution.male, analyticsData.sexDistribution.female],
          backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
          borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
          borderWidth: 1,
        },
      ],
    };
  };

  // Prepare Bar chart data for Age Range Distribution
  const prepareAgeRangeData = () => {
    if (!analyticsData || !analyticsData.ageRangeDistribution) return {};

    const labels = ['UNDER1', '1-4', '5-9', '10-14', '15-17'];
    const maleCounts = [
      analyticsData.ageRangeDistribution.UNDER1_M,
      analyticsData.ageRangeDistribution['1-4_M'],
      analyticsData.ageRangeDistribution['5-9_M'],
      analyticsData.ageRangeDistribution['10-14_M'],
      analyticsData.ageRangeDistribution['15-17_M'],
    ];
    const femaleCounts = [
      analyticsData.ageRangeDistribution.UNDER1_F,
      analyticsData.ageRangeDistribution['1-4_F'],
      analyticsData.ageRangeDistribution['5-9_F'],
      analyticsData.ageRangeDistribution['10-14_F'],
      analyticsData.ageRangeDistribution['15-17_F'],
    ];

    return {
      labels,
      datasets: [
        {
          label: 'Male',
          data: maleCounts,
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
        {
          label: 'Female',
          data: femaleCounts,
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareTimeSeriesData = () => {
    if (!timeSeriesData || !timeSeriesData.timeSeries) return {};

    const { monthlyCounts, labels, movingAverage } = timeSeriesData.timeSeries;

    return {
      labels,
      datasets: [
        {
          label: 'Number of Diagnosed Monthly',
          data: monthlyCounts,
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.1,
        },
        {
          label: 'Moving Average',
          data: movingAverage,
          fill: false,
          borderColor: 'rgba(255, 99, 132, 1)',
          tension: 0.1,
        },
      ],
    };
  };
  
  const exportToExcel = () => {
    const confirmExport = window.confirm('Do you want to export data as excel file?');
    if (!confirmExport) {
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Analytics Data');

    // Define column headers
    worksheet.columns = [
        { header: 'Sitio', key: 'sitio', width: 30 },
        { header: 'Diagnosis', key: 'diagnosis', width: 30 },
        { header: 'Total Count', key: 'totalCount', width: 15 },
        { header: 'UNDER1_M', key: 'UNDER1_M', width: 15 },
        { header: 'UNDER1_F', key: 'UNDER1_F', width: 15 },
        { header: '1-4_M', key: '1-4_M', width: 15 },
        { header: '1-4_F', key: '1-4_F', width: 15 },
        { header: '5-9_M', key: '5-9_M', width: 15 },
        { header: '5-9_F', key: '5-9_F', width: 15 },
        { header: '10-14_M', key: '10-14_M', width: 15 },
        { header: '10-14_F', key: '10-14_F', width: 15 },
        { header: '15-17_M', key: '15-17_M', width: 15 },
        { header: '15-17_F', key: '15-17_F', width: 15 },
    ];

    // Prepare the data
    analyticsData.sitioCounts.forEach(sitioData => {
        const { sitio, diagnosisBreakdown, diagnosisAgeRange } = sitioData;

        Object.keys(diagnosisBreakdown).forEach(diagnosis => {
            const totalCount = diagnosisBreakdown[diagnosis];

            // Get age range data for the current diagnosis
            const ageRangeData = diagnosisAgeRange[diagnosis] || {};

            // Add a row for the current sitio and diagnosis
            worksheet.addRow({
                sitio,
                diagnosis,
                totalCount,
                UNDER1_M: ageRangeData.UNDER1_M || 0,
                UNDER1_F: ageRangeData.UNDER1_F || 0,
                '1-4_M': ageRangeData['1-4_M'] || 0,
                '1-4_F': ageRangeData['1-4_F'] || 0,
                '5-9_M': ageRangeData['5-9_M'] || 0,
                '5-9_F': ageRangeData['5-9_F'] || 0,
                '10-14_M': ageRangeData['10-14_M'] || 0,
                '10-14_F': ageRangeData['10-14_F'] || 0,
                '15-17_M': ageRangeData['15-17_M'] || 0,
                '15-17_F': ageRangeData['15-17_F'] || 0,
            });
        });
    });

    // Save to file
    workbook.xlsx.writeBuffer().then(buffer => {
        const fileName = `analytics_data_${new Date().toISOString().slice(0, 10)}.xlsx`;
        saveAs(new Blob([buffer]), fileName);
    });
};

  return (
    <div className="data-analytics">
      {loading && <p>Loading data...</p>}
      {error && <p className="error">{error}</p>}
      {descriptiveData && ( 
        <div className="chart-item">
         <div className='sum-con'>
          <h4>Summary:</h4>
          <p className='desc-p'>{descriptiveData.summary.description}</p> 
          <p className='desc-p'><strong>Total Cases:</strong> {descriptiveData.summary.totalCases}</p>
          <p className='desc-p'><strong>Most Common Diagnosis:</strong>  {descriptiveData.summary.mostCommonDiagnosis}</p>
        </div>  </div> 
      )}
    {analyticsData && (
      <div className='row mb-4 d-flex align-items-stretch'> 
        <div className="col-md-8">
          <div className="chart-item">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h3 className="mb-0">Diagnosed Per Sitio</h3>
            <button className="btn btn-sm btn-success export-btn me-3" onClick={exportToExcel}>
                Export <FontAwesomeIcon icon={faFileExcel} />
            </button>
        </div>
            <Bar
              data={prepareSitioCountsChartData(analyticsData.sitioCounts)}
              options={chartOptions}
            />
          </div>
        </div>

    <div className="col-md-4">
      <div className="chart-item">
        <h3>Distribution by Sex</h3>
        <Pie data={prepareSexDistributionData()} options={{ responsive: true }} />
      </div>
    </div>
  </div>
)}

{analyticsData && (
  <div className='row mb-4 d-flex align-items-stretch'> 
    <div className="col-md-9">
      <div className="chart-item">
        <h3>Distribution by Age</h3>
        <Bar
          data={prepareAgeRangeData()}
          options={{
            responsive: true,
            scales: { x: { stacked: true }, y: { stacked: true } },
          }}
        />
      </div>
    </div>

    <div className="col-md-3">
  <div className="statistics-item chart-item">
    <h3>Age Statistics</h3>
    <table className="age-statistics-table">
      <tbody>
        <tr>
          <td><strong>Mean Age:</strong></td>
          <td>{(analyticsData?.ageStatistics?.mean ?? 0).toFixed(2)}</td>
        </tr>
        <tr>
          <td><strong>Median Age:</strong></td>
          <td>{analyticsData?.ageStatistics?.median ?? 'N/A'}</td>
        </tr>
        <tr>
          <td><strong>Mode:</strong></td>
          <td>{Array.isArray(analyticsData?.ageStatistics?.mode)
            ? analyticsData.ageStatistics.mode.join(', ')
            : analyticsData?.ageStatistics?.mode ?? 'N/A'}</td>
        </tr>
        <tr>
          <td><strong>Variance:</strong></td>
          <td>{(analyticsData?.ageStatistics?.variance ?? 0).toFixed(2)}</td>
        </tr>
        <tr>
          <td><strong>Standard Deviation:</strong></td>
          <td>{(analyticsData?.ageStatistics?.stdDev ?? 0).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
)}
      {timeSeriesData && (
        <div className='row mb-4 d-flex align-items-stretch'>
          <div className="chart-container">
            <div className="time-chart-item">
              <h3>Time Series Analysis</h3>
              <Line data={prepareTimeSeriesData()} options={{ responsive: true }} />
            </div>
            <div className="time-chart-item mt-3 mb-3">
              <h3>Summary</h3>
              <p className='desc-p'>{timeSeriesData.interpretation}</p>
            </div> 
          </div>
        </div>
      )}
  
      {!loading && !analyticsData && " "}
    </div>
  );
  
};  
export default DataAnalytics;
