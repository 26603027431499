import { MarkerClusterer } from "@googlemaps/markerclusterer";

 

export const clearMarkers = (markers) => {
  markers.forEach(marker => marker.setMap(null)); // Remove each marker from the map
  return []; // Clear marker array
};

export const geocodeAddress = (address, map, item, mapMarkers, placeMarker) => {
  const { streetName, sitio, houseBuildingNumber } = item;
  let position = null; // Declare position at the top

  // Handling for 'Dalig I' Sitio
  if (sitio === 'Dalig I') {
    if (streetName === 'Sampaguita') {
      const coordinates = [
        { lat: 14.560593563879625, lng: 121.19101136323405},
        { lat: 14.56087563797003, lng: 121.1907533805256 },
        { lat: 14.561055535626638, lng: 121.19042337072369},
        { lat: 14.561272146896032, lng: 121.19042337072369},
        { lat: 14.562054148531358, lng: 121.19004404912015},
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } else if (streetName === 'Carnation Avenue') {
      position = { lat: 14.569807934875117, lng: 121.18950681350931 };
    } else if (streetName === 'Rose Street') {
      const coordinates = [
        { lat: 14.561130002338624, lng: 121.18937409381098 },
        { lat: 14.56094539421883, lng: 121.18938023625431},
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } else if (streetName === 'Orchids St') {
      const coordinates = [
        { lat: 14.561121823737533, lng: 121.18976727351993 },
        { lat:  14.561195047992689, lng: 121.18983542222053},
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } else if (streetName === 'Dahlia Street') {
      const coordinates = [
        { lat: 14.560903152344581, lng: 121.18853927186991 },
        { lat: 14.561088578035355, lng: 121.18887573851624 },
        { lat: 14.561073892520069, lng: 121.18900091464803 },
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } else if (streetName === 'Calachuchi St.') {
      position = { lat: 14.560479068367558, lng: 121.19068850963228 };
    } else if (streetName === 'Manuel L. Quezon Ext') {
      const coordinates = [
        { lat: 14.560620150292422, lng: 121.18843617062834 },
        { lat: 14.560485155012126, lng: 121.18865611175293 },
        { lat: 14.559871073701897, lng: 121.18951635369311 },
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } else {
      position = { lat: 14.562449941461463, lng: 121.18935453846181 }; // Default for Dalig I
    }
  } 
  else if (sitio === 'Tubigan') {
    let coordinates = [];
    if (streetName === 'S. Pedracio Street') {
      coordinates = [
        { lat: 14.579681905639989, lng: 121.18109820800255 },
        { lat: 14.578660998356499, lng: 121.1818331350958 },
        { lat: 14.579600539741204, lng: 121.18122678149231 },
      ];
    } else if (streetName === 'I. Tapales Street') {
      coordinates = [
        { lat: 14.579065796297652, lng: 121.1790810142929 },
        { lat: 14.579398064215418, lng: 121.1797622953374 },
      ];
    } else {
      coordinates = [
        { lat: 14.578534542330361, lng: 121.17999158856684 },
        { lat: 14.57866433485813, lng: 121.18048666429937 },
        { lat: 14.578300915585848, lng: 121.18052957969672 },
        { lat: 14.57894468646004, lng: 121.17940036968398 },
      ];
    }
    const randomIndex = Math.floor(Math.random() * coordinates.length);
    position = coordinates[randomIndex]; 
  }
  else if (sitio === 'Kamatisan') {
    let coordinates = [];
    
    if (streetName === 'Kamatisan Rd') {
      coordinates = [
        { lat: 14.57691472512102, lng: 121.17705343686045 },
        { lat: 14.576873191190533, lng: 121.17735384425016 },
        { lat: 14.576748589352075, lng: 121.17782591300539 },
        { lat: 14.577010166384579, lng:121.17823328382366 },
      ];
    } else {
      coordinates = [
        { lat: 14.577002984697312, lng: 121.17821215107787 },
        { lat: 14.577231421083548, lng: 121.17757914979244 },
        { lat: 14.57763637591341, lng: 121.17767570931055 },
      ];
    }
    const randomIndex = Math.floor(Math.random() * coordinates.length);
     position = coordinates[randomIndex];
  }
  else if (sitio === 'Avida') {
    position = { lat: 14.57313010495209, lng: 121.1954211918518 };
  } else if (sitio === 'Birmingham') {
    position = { lat: 14.564863321084237, lng: 121.18352669166812 };
  } else if (sitio === 'Cuesta Verde') {
    position = streetName === 'Ford Street'
      ? { lat: 14.575320710705366, lng: 121.18360281488474 }
      : streetName === 'Carter St'
      ? { lat: 14.574074680354896, lng: 121.18359745047954 }
      : streetName === 'Hoover St.'
      ? { lat: 14.576910032982255, lng: 121.18354072175228 }
      : { lat: 14.574905368043435, lng: 121.18292153384022 }; 
  } else if (sitio === 'Dalaya') {
    position = { lat: 14.576564714399689, lng: 121.17663736923527 };
  } else if (sitio === 'Dalig II') {
    if (streetName==="Dandelion St") {
      position = { lat: 14.57078846302825, lng: 121.18966121553638 };}
    else if (streetName==="Blossom Street") {
      position = { lat: 14.570497717270273, lng: 121.18956465605177 };}
    else if (streetName==="Rosal Street") {
      position = { lat: 14.569916224581965, lng: 121.18909795175591 };}
    else if (streetName==="Camia Street") {
      position = { lat: 14.569069942329161, lng: 121.18976850396507 };}
    else if (streetName==="Iris Street") {
       position = { lat: 14.5701862035128, lng: 121.19095940468853 };}  
    else if (streetName==="Iris Street") {
      position = { lat:  14.570061597883388, lng: 121.19129736313388 };}      
    else if (streetName==="St. Ursula") {
      position = { lat:  14.572010256057329, lng: 121.18676770212831 };} 
    else if (streetName==="San Estaban") {
      position = { lat: 14.571200324975257, lng: 121.18668723586322 };}     
    else { position = { lat: 14.57060710240066, lng: 121.1867652990777 }; }
  } else if (sitio === 'Dalig III') {
    position = { lat: 14.570752475256564, lng: 121.18546711000079 };
  } else if (sitio === 'Gloria Heights') {
    position = { lat: 14.569901004308226, lng: 121.18328915632347 };
  } else if (sitio === 'Junmar Subd') {
    position = { lat: 14.560120084839008, lng: 121.19020831175153 };
  } else if (sitio === 'Kaytinaga') {
    position = { lat: 14.576637398872187, lng: 121.17828692772932 };
  } else if (sitio === 'Lower Zigzag') {
    position = { lat: 14.571523116084064, lng: 121.2004705370559 };
  } else if (sitio === 'Lugo') {
    position = { lat: 14.578561532103345, lng: 121.18467349119916 };
  } else if (sitio === 'Maia Alta') {
    position = { lat: 14.567185113164477, lng: 121.18907791567617 };
  } else if (sitio === 'Milagros') {
    position = { lat: 14.573281782243487, lng: 121.18642785334877 };
  } else if (sitio === 'Nayong Silangan') {
    if (streetName === 'Atis Street') {
      position = { lat: 14.571940729589622, lng: 121.17969922208515 };
    } else if (streetName === 'Kaimito Street') {
      position = { lat: 14.571434522852675, lng: 121.17991111660858 };
    } else if (streetName === 'Kasoy St.') {
      position = { lat: 14.572455183280518, lng: 121.17938028317724 };
    } else if (streetName === 'Manuel L. Quezon Ext') {
      const coordinates = [
        { lat: 14.57096253757427, lng: 121.17854768697534 },
        { lat: 14.570863891812227, lng: 121.17863351765811 },
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } 
    else {
      position = { lat: 14.57179535751742, lng: 121.17910913614111 };
    }
  } else if (sitio === 'Parugan Blk I') {
    if (streetName === 'Sapang Buho') {
      const coordinates = [
        { lat: 14.57770335778973, lng: 121.1869480172053 },
        { lat: 14.57715303454516, lng: 121.1869802037113 },
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } else {
      position = { lat: 14.57772931639939, lng: 121.18722160250661 };
    }
  } else if (sitio === 'Parugan Blk II') {
    position = { lat: 14.575606335292358, lng: 121.19169387731397 };
  } else if (sitio === 'Payong') {
    const coordinates = [
      { lat: 14.56406325335466, lng: 121.19723126492609 },
      { lat: 14.563725770267464, lng: 121.19801446990637 },
      { lat: 14.564094405613584, lng: 121.19673773850015 },
    ];
    const randomIndex = Math.floor(Math.random() * coordinates.length);
    position = coordinates[randomIndex];
  } else if (sitio === 'Robbis/Courtyard') {
    position = { lat: 14.569726463110918, lng: 121.18694473689226 };
  } else if (sitio === 'San Antonio') {
    position = { lat: 14.574596315883, lng: 121.19160879397039 };
  } else if (sitio === 'Sta. Maria') {
    position = { lat: 14.575766586777732, lng: 121.19044979692899 };
  } else if (sitio === 'Sto. Niño') {
    position = { lat: 14.575800809135114, lng: 121.19071830063662 };
  } else if (sitio === 'Synergy') {
    position = { lat: 14.568923846438308, lng: 121.1871076926395 };
  } 
  else if (sitio === 'Sto. Niño') {
    position = { lat: 14.575800809135114, lng: 121.19071830063662 };
  } 
  else if (sitio === 'Terrazas Verde') {
    position = { lat:  14.569880643354274, lng: 121.19559993089052 };
  } 
  else if (sitio === 'Lores Executive') { 
    position = { lat:  14.574277633627483, lng:  121.17964542485323 };
  }
  else if (sitio === 'Upper Zigzag') {
    position = { lat: 14.571824244213454, lng: 121.19801363376158 };
  } else if (sitio === 'Woodrow') {
    if (streetName === 'El Toro Drive') {
      const coordinates = [
        { lat: 14.56813287356161, lng: 121.18564233782902 },
        { lat: 14.567797992674945, lng: 121.18557260036877 },
        { lat: 14.567289180431597, lng: 121.18584618567812 },
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    } else {
      position = { lat: 14.567945179412265, lng: 121.18585389727023 };
    }
  }
  else if (sitio === 'Aba Homes') {
    if (streetName === 'Lourdes St.') {
      position = { lat: 14.56283643349958, lng: 121.18726677165999 };
    } else if (streetName === 'Manuel L. Quezon Ext') {
      const coordinates = [
        { lat: 14.562909122502827, lng: 121.18534631004457 },
        { lat: 14.563376408370234, lng: 121.18471330875911 },
      ];
      const randomIndex = Math.floor(Math.random() * coordinates.length);
      position = coordinates[randomIndex];
    }
  }
  // Finally place the marker if position is defined
  if (position) {
    placeMarker(position.lat, position.lng, map, item, mapMarkers);
  } else {
    position = { lat: 14.571775163007898, lng: 121.19103330640952 };
    placeMarker(position.lat, position.lng, map, item, mapMarkers);
  }
};


export const placeMarker = (lat, lng, map, item, mapMarkers) => {
  const baseOffset = 0.00006;
  const maxAttempts = 100;
  const offsetIncrement = 0.00001;
  let offsetX = 0;
  let offsetY = 0;
  let attempts = 0;

  const findUniquePosition = () => {
    const position = {
      lat: lat + offsetY,
      lng: lng + offsetX,
    };

    let isUnique = true;
    mapMarkers.forEach(marker => {
      const markerPos = marker.getPosition();
      const distance = Math.sqrt(
        Math.pow(position.lat - markerPos.lat(), 2) +
        Math.pow(position.lng - markerPos.lng(), 2)
      );
      if (distance < baseOffset) {
        isUnique = false;
      }
    });

    if (isUnique) {
      return position;
    } else {
      offsetX += offsetIncrement;
      if (offsetX > baseOffset) {
        offsetX = -baseOffset;
        offsetY += offsetIncrement;
        if (offsetY > baseOffset) {
          offsetY = -baseOffset;
        }
      }
      attempts++;
      if (attempts < maxAttempts) {
        return findUniquePosition();
      } else {
        return { lat, lng };
      }
    }
  };

  const uniquePosition = findUniquePosition();

  const marker = new window.google.maps.Marker({
    position: uniquePosition,
    map: map,
    title: item.houseBuildingNumber ? `${item.houseBuildingNumber}, ${item.streetName}` : item.streetName,
  });

  const infoWindow = new window.google.maps.InfoWindow({
    content: `
      <div class="info-window-content">
        <div class="info-window-title"> ${item.houseBuildingNumber ? `${item.houseBuildingNumber}` : ''} ${item.streetName}, ${item.sitio} </div>
        <div class="info-window-date"><strong>Diagnosis:</strong> ${item.diagnosis}</div>
        <div class="info-window-date"><strong>Date:</strong> ${item.visitDate}</div>
        <div class="info-window-date"><strong>Age:</strong> ${item.age}</div>
        </div>
    `,
  });

  marker.addListener('mouseover', () => {
    infoWindow.open(map, marker);
  });

  marker.addListener('mouseout', () => {
    infoWindow.close();
  });

  marker.addListener('click', () => {
    infoWindow.open(map, marker);
  });

  mapMarkers.push(marker);
};

export const createMarkerCluster = (mapInstance, mapMarkers) => {
  return new MarkerClusterer(mapInstance, mapMarkers, {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  });
};
