
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AdminDashboard from './UI/adminDashboard';
import Dashboard from './Dashboard';
import UserManagement from './UI/UserManagement';
import InventoryManagement from './InventoryManagement';
import ScheduleManagement from './ScheduleManagement';
import BookedPatients from './UI/BookedPatient';
import PatientRecord from './UI/patientRecord';
import PatientDetails from './UI/patientDetails';
import HealthWorkerBookedPatients from './HealthWorkerBookedPatients';
import HealthWorkerPatientRecord from './HealthWorkerPatientRecord';
import HealthWorkerDashboard from './UI/healthworkerDashboard';
import ChildHealthRecord from './UI/childHealthRecord';
import PrenatalRecord from './UI/prenatalRecord';
import ChildHealthRecordDetails from './UI/childHealthRecordDetails';
import PrenatalRecordDetails from './UI/prenatalRecordDetails';
import FamilyPlanningRecord from './UI/familyPlanningRecord';
import FamilyPlanningRecordDetails from './UI/familyRecordDetails';
import HealthWorkerManagement from './UI/HealthWorkerManagement';
import PatientManagement from './UI/PatientManagement';
import AdminManagement from './UI/AdminManagement';
import SuperAdminManagement from './UI/superAdminManagement';
import SuperUserManagement from './UI/superUserManagement';
import MedicineDispense from './MedicineDispense';
import DistributedMedicine from './MedicineDistributionRecord';
import AdminDistributedMedicine from './AdminMedicineDistributionRecord';
import ArchivedChildHealthRecord from './UI/archivedChildHealthRecord';
import ArchivedFamilyPlanningRecord from './UI/archivedFamilyPlanning';
import ArchivedPrenatalRecord from './UI/archivedPrenatalRecord';
import ArchivedMedicineDistributionRecord from './ArchivedMedicineDistributionRecord';
import ArchivedInventoryRecord from './ArchivedInventoryItems';
import HealthWorkerChildHealthRecord from './UI/HealthWorkerchildHealthRecord';
import HealthWorkerprenatalRecord from './UI/HealthWorkerprenatalRecord';
import HealthWorkerFamilyPlanningRecord from './UI/HealthWorkerfamilyPlanningRecord';


import Sidebar from './Sidebar';
function AuthenticatedApp({ userRole }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      if (userRole === 'Super Admin') {
        navigate('/admin-dashboard');
      } 
      else if (userRole === 'Admin') {
        navigate('/admin-dashboard');
      }
      else if (userRole === 'Health Worker') {
        navigate('/health-worker-dashboard');
      }
    }
  }, [navigate, userRole]);

  return (
    <React.Fragment>
      <Sidebar userRole={userRole} />
      <div className="main-content">
        <Routes>
        {userRole === 'Health Worker' ? (
    <Route path="/child-health" element={<HealthWorkerChildHealthRecord />} />
  ) : (
    <Route path="/child-health" element={<ChildHealthRecord />} />
  )}  
  
  {userRole === 'Health Worker' ? (
     <Route path="/prenatal-record" element={<HealthWorkerprenatalRecord />} />
  ) : (
    <Route path="/prenatal-record" element={<PrenatalRecord />} />
  )}

  {userRole === 'Health Worker' ? (
     <Route path="/family-planning" element={<HealthWorkerFamilyPlanningRecord />} />
  ) : (
    <Route path="/family-planning" element={<FamilyPlanningRecord />} />
  )}
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/inventory-management" element={<InventoryManagement />} />
          <Route path="/schedule-management" element={<ScheduleManagement />} />
          <Route path="/booked-patients" element={<BookedPatients />} />
          <Route path="/patient-record" element={<PatientRecord />} />
          <Route path="/patients/:id" element={<PatientDetails />} />
          <Route path="/health-worker-appointment" element={<HealthWorkerBookedPatients />} />
          <Route path="/health-worker-patient-record" element={<HealthWorkerPatientRecord />} />
          <Route path="/health-worker-dashboard" element={<HealthWorkerDashboard />} />
          <Route path="/childHealthRecords/:id" element={<ChildHealthRecordDetails />} />
          <Route path="/prenatal-record/:id" element={<PrenatalRecordDetails />} />
          <Route path="/family-planning-record-details/:id" element={<FamilyPlanningRecordDetails />} />
          <Route path="/health-worker-accounts" element={<HealthWorkerManagement/>} />
          <Route path="/patient-accounts" element={<PatientManagement/>} />
          <Route path="/admin-accounts" element={<AdminManagement/>} />
          <Route path="/super-admin-accounts" element={<SuperAdminManagement/>} />
          <Route path="/super-user-management" element={<SuperUserManagement/>} />
          <Route path="/medicine-dispense" element={<MedicineDispense/>} />
          <Route path="/distributed-medicine" element={<DistributedMedicine/>} />
          <Route path="/admin-distributed-medicine" element={<AdminDistributedMedicine/>} />
          <Route path="/archived-child-health-record" element={<ArchivedChildHealthRecord/>} />
          <Route path="/archived-family-planning-record" element={<ArchivedFamilyPlanningRecord/>} />
          <Route path="/archived-prenatal-record" element={<ArchivedPrenatalRecord/>} />
          <Route path="/archived-medicine-distribution-record" element={<ArchivedMedicineDistributionRecord/>} />
          <Route path="/archived-inventory-record" element={<ArchivedInventoryRecord/>} />
          </Routes>
      </div>
    </React.Fragment>
  );
}

export default AuthenticatedApp;
