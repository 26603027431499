import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import StreetDropdown from './streetDropdownBtn';

const sitiosOptions = [
  'Lower Zigzag', 'Parugan Blk II', 'Sto. Niño', 'Dalig III', 'Tubigan', 'San Antonio',
  'Nayong Silangan', 'Woodrow', 'Maia Alta', 'Lores Executive', 'Dalaya', 'Sta. Maria',
  'Gloria Heights', 'Cuesta Verde', 'Terrazas Verde', 'Lugo', 'Payong', 'Dalig II', 'Kamatisan',
  'Milagros', 'Aba Homes', 'Upper Zigzag', 'Parugan Blk I', 'Kaytinaga', 'Dalig I',
  'Birmingham', 'Junmar Subd', 'La Terraza de Antipolo', 'Robbis/Courtyard', 'Avida', 'Synergy'
];

const ChildHealthRecordForm = ({
  record,
  editMode,
  setEditMode,
  handleInputChange,
  handleUpdateRecord,
  handleCancelEdit,
}) => (
  <Container>
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="fName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="fName"
              value={record.fName}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="mName">
            <Form.Label>Middle Initial</Form.Label>
            <Form.Control
              type="text"
              name="mName"
              value={record.mName}
              onChange={handleInputChange}
              maxLength={1}
              disabled={!editMode} 
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="lName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lName"
              value={record.lName}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="sex">
            <Form.Label>Sex</Form.Label>
            <Form.Control
              as="select"
              name="sex"
              value={record.sex}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            >
              <option value="">Select Sex</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="dateOfBirth">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="date"
              name="dateOfBirth"
              value={record.dateOfBirth}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="motherFirstName">
            <Form.Label>Mother's First Name</Form.Label>
            <Form.Control
              type="text"
              name="motherFirstName"
              value={record.motherFirstName}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="motherLastName">
            <Form.Label>Mother's Last Name</Form.Label>
            <Form.Control
              type="text"
              name="motherLastName"
              value={record.motherLastName}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="motherOccupation">
            <Form.Label>Mother's Occupation</Form.Label>
            <Form.Control
              type="text"
              name="motherOccupation"
              value={record.motherOccupation}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="fatherFirstName">
            <Form.Label>Father's First Name</Form.Label>
            <Form.Control
              type="text"
              name="fatherFirstName"
              value={record.fatherFirstName}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="fatherLastName">
            <Form.Label>Father's Last Name</Form.Label>
            <Form.Control
              type="text"
              name="fatherLastName"
              value={record.fatherLastName}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="fatherOccupation">
            <Form.Label>Father's Occupation</Form.Label>
            <Form.Control
              type="text"
              name="fatherOccupation"
              value={record.fatherOccupation}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="houseBuildingNumber">
            <Form.Label>House Building Number</Form.Label>
            <Form.Control
              type="text"
              name="houseBuildingNumber"
              value={record.houseBuildingNumber}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
        <Form.Group controlId="streetName">
            <Form.Label>Street Name</Form.Label>
            <StreetDropdown
              name="streetName"
              value={record.streetName}
              onChange={handleInputChange}
              disabled={!editMode} // Disable dropdown if editMode is false
            />
          </Form.Group>
        </Col>

        <Col>
        <Form.Group controlId="sitio">
            <Form.Label>Sitio</Form.Label>
            <Form.Control
              as="select"
              name="sitio"
              value={record.sitio}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            >
              <option value="">Select Sitio</option>
              {sitiosOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
      <Col>
          <Form.Group controlId="contactPerson">
            <Form.Label>Contact Person</Form.Label>
            <Form.Control
              type="text"
              name="contactPerson"
              value={record.contactPerson}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      <Col>
          <Form.Group controlId="contactNo">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="number"
              name="contactNo"
              value={record.contactNo}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      </Row>
      <div className='record-divider'>
      <Row>
      <div className='form-divider'><h3>NEW BORN INFORMATION</h3> </div>
        <Col>
          <Form.Group controlId="typeOfDelivery">
                      <Form.Label>Type of Delivery</Form.Label>
                      <Form.Control
                        as="select"
                        name="typeOfDelivery"
                        value={record.typeOfDelivery}
                        onChange={handleInputChange}
                        disabled={!editMode} // Disable input in view mode
                        >
                        <option value="">Select Type</option>
                        <option value="NSD">NSD</option>
                        <option value="CS">CS</option>
                      </Form.Control>
                    </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="birthWT">
            <Form.Label>Birth Weight</Form.Label>
            <Form.Control
              type="text"
              name="birthWT"
              value={record.birthWT}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="birthHT">
            <Form.Label>Birth Height</Form.Label>
            <Form.Control
              type="text"
              name="birthHT"
              value={record.birthHT}
              onChange={handleInputChange}
              disabled={!editMode} 
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="muac">
            <Form.Label>MUAC</Form.Label>
            <Form.Control
              type="text"
              name="muac"
              value={record.muac}
              onChange={handleInputChange}
              disabled={!editMode}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="cpab">
            <Form.Label>CPAB</Form.Label>
            <Form.Control
              as="select"
              name="cpab"
              value={record.cpab}
              onChange={handleInputChange}
              disabled={!editMode}
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="newbornScreeningDate">
            <Form.Label>Newborn Screening Date</Form.Label>
            <Form.Control
              type="date"
              name="newbornScreeningDate"
              value={record.newbornScreeningDate}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="seStatus">
            <Form.Label>SE Status</Form.Label>
            <Form.Control
               as="select"
                 name="seStatus"
               value={record.seStatus}
                onChange={handleInputChange}
               disabled={!editMode}  >
               <option value="">Select SE Status</option>
               <option value="Hospital">Hospital</option>
              <option value="Lying-in">Lying-in</option>
              <option value="Home">Home</option>
              <option value="Others">Others</option>
           </Form.Control>  </Form.Group>

        </Col>
        <Col>
          <Form.Group controlId="birthAttendant">
               <Form.Label>Birth Attendant</Form.Label>
                <Form.Control
                  as="select"
                  name="birthAttendant"
                  value={record.birthAttendant}
                  onChange={handleInputChange}
                 disabled={!editMode} >
                  <option value="">Select Attendant</option>
                  <option value="MD">MD</option>
                  <option value="N">N</option>
                  <option value="MW">MW</option>
                  <option value="Others">Others</option>
          </Form.Control>   </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="headCircumference">
            <Form.Label>Head Circumference</Form.Label>
            <Form.Control
              type="number"
              name="headCircumference"
              value={record.headCircumference}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="chestCircumference">
            <Form.Label>Chest Circumference</Form.Label>
            <Form.Control
              type="number"
              name="chestCircumference"
              value={record.chestCircumference}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="abdomenCircumference">
            <Form.Label>Abdomen Circumference</Form.Label>
            <Form.Control
              type="number"
              name="abdomenCircumference"
              value={record.abdomenCircumference}
              onChange={handleInputChange}
              disabled={!editMode} // Disable input in view mode
            />
          </Form.Group>
        </Col>
      </Row> </div>
    
      <Row>
        <Col>
          {editMode ? (
            <div className="text-right mt-3">
                  <Button variant="success" className="mr-2" style={{ marginRight: '10px' }}onClick={handleUpdateRecord}>
                  <FontAwesomeIcon icon={faSave} />  Save
              </Button>
              <Button variant="danger"  className="ml-2"onClick={handleCancelEdit}>
              <FontAwesomeIcon icon={faTimes} /> Cancel
              </Button>
            </div>
          ) : (
            <div className="add-user-button"><div className="text-right mt-3">
            <Button variant="info" onClick={() => setEditMode(true)} >
            <FontAwesomeIcon icon={faEdit}  /> UPDATE
            </Button> </div></div>
          )}
        </Col>
      </Row>
    </Form>
  </Container>
);

export default ChildHealthRecordForm;
