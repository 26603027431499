import React, { useState } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import "../App.css";

function VaccineRecordsTable({
  vaccineRecords,
  handleEditClick,
  handleDeleteClick,
  editIndex
}) {
  const [successMessage, setSuccessMessage] = useState('');

  const handleDelete = (index) => {
    const confirmation = window.prompt("Please type DELETE RECORD to confirm deletion.");
    if (confirmation === "DELETE RECORD") {
      if (window.confirm("Are you absolutely sure you want to delete this visit record? This action cannot be undone!")) {
        handleDeleteClick(index);  // Pass index to the parent component's handler
        setSuccessMessage('Vaccine record deleted successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } else {
      alert("Deletion canceled. Please type DELETE RECORD to confirm.");
    }
   
  };

  return (
    <>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <div className="container-usertable">
        <div className="user-table">
          <table>
            <thead className="table-light">
              <tr>
                <th className="fixed-name-th">Vaccine Name</th>
                <th className="fixed-dose-th">Dose</th>
                <th className="fixed-date-th">Date</th>
                <th>Remarks</th>
                <th className="fixed-action-th">Actions</th>
              </tr>
            </thead>
            <tbody>
              {vaccineRecords.length > 0 ? (
                vaccineRecords.map((record, index) => (
                  <tr key={record._id}>
                    <td>{record.vaccine_name}</td>
                    <td>{record.dose}</td>
                    <td>{record.date}</td>
                    <td>{record.remarks}</td>
                    <td>
                      <div className="action-buttons">
                        <Button
                          className="btn btn-sm btn-primary me-2"
                          onClick={() => handleEditClick(index)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDelete(index)}  // Call handleDelete with index
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No vaccine records yet.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default VaccineRecordsTable;
