import React, { useState, useEffect } from 'react';
import { Button, Alert, Modal, Form, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt,faUser,faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { getPatients, searchPatientUser, updatePatient, deletePatient } from '../Controller/patientUser_controller';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import PaginationComponent from './Pagination'; 

function PatientUserPage() {
  const [showModal, setShowModal] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [patients, setPatients] = useState([]);
  const [paginatedPatients, setPaginatedPatients] = useState([]);
  const [modalSuccessMessage, setModalSuccessMessage] = useState('');
  const [modalError, setModalError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");

  const [formData, setFormData] = useState({
    fName: '',
    mName: '',
    lName: '',
    sex: 'female',
    dob: '',
    houseBuildingNumber: '',
    streetName: '',
    sitio: 'Aba Homes',
  });

  const sitios = [
   'Aba Homes', 'Avida', 'Birmingham', 'Cuesta Verde', 'Dalaya', 'Dalig I', 'Dalig II', 
'Dalig III', 'Gloria Heights', 'Junmar Subd', 'Kamatisan', 'Kaytinaga', 'La Terraza de Antipolo', 
'Lores Executive', 'Lower Zigzag', 'Lugo', 'Maia Alta', 'Milagros', 'Nayong Silangan', 
'Parugan Blk I', 'Parugan Blk II', 'Payong', 'Robbis/Courtyard', 'San Antonio', 'Sta. Maria', 
'Sto. Niño', 'Synergy', 'Terrazas Verde', 'Tubigan', 'Upper Zigzag', 'Woodrow'
  ];

  useEffect(() => {
    fetchPatients(currentPage);
  }, [currentPage]);  

  const fetchPatients = async (page = 1, limit = 20) => {
    try {
      const response = await getPatients(page, limit);
      setPaginatedPatients(response.users);
      setTotalPages(response.totalPages); // Set the total pages based on backend response
    } catch (error) {
      console.error('Error fetching patients:', error);
      setModalError('Failed to fetch patients.');
    }
  }; 
  const handleSearch = async () => {
    const filteredRecords = await searchPatientUser(searchQuery);
    setPaginatedPatients(filteredRecords);
  };
 {/*  const handleShowModal = (patient = null) => {
    if (patient) {
      setFormData({
        fName: patient.fName,
        mName: patient.mName,
        lName: patient.lName,
        sex: patient.sex,
        dob: patient.dob,
        houseBuildingNumber: patient.houseBuildingNumber,
        streetName: patient.streetName,
        sitio: patient.sitio,
      });
      setCurrentPatient(patient);
    } else {
      setFormData({
        fName: '',
        mName: '',
        lName: '',
        sex: 'female',
        dob: '',
        houseBuildingNumber: '',
        streetName: '',
        sitio: 'Lower Zigzag',
      });
      setCurrentPatient(null);
    }
    setShowModal(true);
  }; *

  const handleCloseModal = () => {
    setShowModal(false);
    // Clear messages after a short delay to ensure the modal closes first
    setTimeout(() => {
      setModalSuccessMessage('');
      setModalError(null);
    }, 300); // Adjust delay if necessary
  };

   const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await updatePatient(currentPatient._id, formData);
      setShowModal(false);
      setModalSuccessMessage('Patient updated successfully!');
      fetchPatients(currentPage); 
  
      setTimeout(() => setModalSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating patient:', error);
      setShowModal(false);
      setModalError('Failed to update patient.');
  
      setTimeout(() => setModalError(null), 3000);
    }
  }; 
 
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this patient?');

    if (!confirmDelete) {
      return;
    }

    try {
      await deletePatient(id);
      setModalSuccessMessage('Patient deleted successfully!');
      fetchPatients(currentPage); // Refresh the list of patients
    } catch (error) {
      setModalError('Failed to delete patient.');
    }
  }; */ }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="main-content">
      <div className="page-header">
        <FontAwesomeIcon icon={faUser} className="user-header-icon" />
        PATIENT USER MANAGEMENT
      </div>
      <div className="row">
          <div className="col-md-6">
            {/* Other content  */}
          </div>
          <div className="col-md-6 d-flex justify-content-end">
                  <div className="search-bar">
                    <div className="search-bar-inner">
                      <input
                        type="text"
                        placeholder="Search user by name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                      />
                      <button
                        className="search-button"
                        onClick={() => handleSearch(searchQuery)}
                      >
                     <span> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                      </button>
                    </div>
                </div>  
          </div> 
          </div>   
      {/* Global Messages */}
      {modalError && <Alert variant="danger" className="mt-3">{modalError}</Alert>}
      {modalSuccessMessage && <Alert variant="success" className="mt-3">{modalSuccessMessage}</Alert>}

      <div className="container-usertable">
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Sex</th>
                <th>Address</th>
                <th>Date of Birth</th>
               {/* <th>Actions</th> */ }
              </tr>
            </thead>
            <tbody>
              {paginatedPatients.length > 0 ? (
                paginatedPatients.map((patient) => (
                  <tr key={patient._id}>
                    <td>{patient.fName}</td>
                    <td>{patient.mName}</td> 
                    <td>{patient.lName}</td>
                    <td>{patient.sex}</td>
                    
                    <td class="fixed-cell-2"><div class="scrollable-content-2">
                      {patient.houseBuildingNumber 
                        ? `${patient.houseBuildingNumber}, ${patient.streetName} - ${patient.sitio}` 
                        : `${patient.streetName} - ${patient.sitio}`}
                    </div></td>    
                    <td>{new Date(patient.dob).toLocaleDateString()}</td>                {/* <td>
                     <Button className="btn btn-sm btn-primary me-2" onClick={() => handleShowModal(patient)}>
                        <FontAwesomeIcon icon={faEdit} /> 
                      </Button>
                      <Button className="btn btn-sm btn-danger" onClick={() => handleDelete(patient._id)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </td>*/ }
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No patients found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />

      {/* Edit Patient Modal 
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentPatient ? 'Edit Patient' : 'Add Patient'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="fName"
                value={formData.fName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formMiddleName">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                name="mName"
                value={formData.mName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lName"
                value={formData.lName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formSex">
              <Form.Label>Sex</Form.Label>
              <Form.Control
                as="select"
                name="sex"
                value={formData.sex}
                onChange={handleInputChange}
                required
              >
                <option value="female">Female</option>
                <option value="male">Male</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formDateOfBirth">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formHouseBuildingNumber">
              <Form.Label>House/Building Number</Form.Label>
              <Form.Control
                type="text"
                name="houseBuildingNumber"
                value={formData.houseBuildingNumber}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formStreetaAme">
              <Form.Label>Street Name</Form.Label>
              <Form.Control
                type="text"
                name="streetName"
                value={formData.streetName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formSitio">
              <Form.Label>Sitio</Form.Label>
              <Form.Control
                as="select"
                name="sitio"
                value={formData.sitio}
                onChange={handleInputChange}
                required
              >
                {sitios.map((sitio, index) => (
                  <option key={index} value={sitio}>
                    {sitio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button className="modal-btn" variant="primary" type="submit">
              {currentPatient ? 'Update Patient' : 'Add Patient'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>  */}
    </div>
  );
}

export default PatientUserPage;
