import React, { useEffect, useRef, useState, useCallback } from 'react';
import { clearMarkers, geocodeAddress, placeMarker, createMarkerCluster } from './mapUtils';
import { fetchAddressData } from './Controller/map_controller'; 

import './mapStyle.css';

const illnessTypes = [
  "Respiratory System (Infectious)", "Respiratory System (Non-Infectious)", "Gastrointestinal System (Infectious)",
  "Gastrointestinal System (Non-Infectious)", "Dermatological Conditions", "Diseases of the Heart",
 "Malnutrition", "Fever", "Chickenpox", "Dengue", "Urinary Tract Infection", 
  "Diphtheria", "Leprosy", "Leptospirosis", "Malaria", "Measles", "Schislosomiasis", "Viral Hepatitis",  "Viral Meningitis"
];

const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 
  'September', 'October', 'November', 'December'
];

const years = Array.from({ length: 51 }, (_, i) => 2010 + i); // 2010 to 2060

export const fetchFilteredData = async (illnessType, selectedMonth, selectedYear, setMapData, setRecordCount) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await fetchAddressData(illnessType, selectedMonth, selectedYear);
      setMapData(data || []);
      setRecordCount(data ? data.length : 0); // Set the number of records
      resolve(); // Resolve the promise after fetching data
    } catch (error) {
      console.error('Error fetching filtered address data:', error);
      reject(error); // Reject the promise if there's an error
    }
  });
};

const GoogleMapComponent = ({ onFiltersChange }) => {
  const googleMapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapData, setMapData] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [illnessType, setIllnessType] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [error, setError] = useState('');
  const [recordCount, setRecordCount] = useState(0);

  const boundaryCoords = [        
    { lat: 14.580725750188794, lng: 121.17586274144645 },
    { lat: 14.58118261500367, lng: 121.18015427550156 },
    { lat: 14.577194397952766, lng: 121.18894606216979 },
    { lat: 14.57530819901927, lng: 121.19328878181541 },
    { lat: 14.571673816303273, lng: 121.1965098954048 },
    { lat: 14.572455855480278, lng: 121.19821623429223 },
    { lat: 14.571399973405892, lng: 121.19817386629569 },
    { lat:  14.572938136989517, lng: 121.2001313592922 }, 
    { lat: 14.570113760917657, lng: 121.20081800473669 }, 
    { lat:  14.569033842855518, lng: 121.20257753368817 }, 
    { lat: 14.570478880677518, lng: 121.20237581340155 },
    { lat: 14.571733628090932, lng: 121.20279211038955 },
    { lat: 14.57104049871273, lng: 121.20303244737272 },
    { lat: 14.569158449055761, lng:  121.20399373991741 }, 
    { lat: 14.568394969673587, lng: 121.20352110521175 },
    { lat: 14.56792686938245, lng: 121.20278018025854 },
    { lat: 14.562649388522427, lng: 121.19914503247107 },
    { lat: 14.559700881662705, lng: 121.18954516979318 },
    { lat: 14.5643238746609, lng: 121.1830286291985 },
    { lat:  14.569142198131686, lng: 121.17967575739823},
    { lat:  14.571260492959123, lng: 121.17815226278682},
    { lat: 14.5733437489792, lng: 121.1770266538319 },
    { lat: 14.580732778475976, lng: 121.17587238853554 },
  ];

  const initializeMap = useCallback(() => {
    if (window.google && window.google.maps) {
      const googleMap = new window.google.maps.Map(googleMapRef.current, {
        center: { lat: 14.5720397, lng: 121.1910819 },
        zoom: 15.3,
        streetViewControl: false,
      });

      new window.google.maps.Polygon({
        paths: boundaryCoords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'transparent',
        fillOpacity: 0,
        map: googleMap,
      });

      setMapInstance(googleMap);
    }
  }, []);
  useEffect(() => {
    const loadMapWithMarkers = () => {
      // Clear existing markers
      const updatedMarkers = clearMarkers(markers);
      setMarkers(updatedMarkers);
    
      if (mapInstance && mapData.length > 0) {
        const mapMarkers = [];

        mapData.forEach((item) => {
          const address = item.houseBuildingNumber && item.streetName 
            ? `${item.houseBuildingNumber}, ${item.streetName},  Dalig, Antipolo, Rizal`
            : item.streetName
              ? `${item.streetName}, Dalig, Antipolo, Rizal`
              : null;

          if (address) {
            geocodeAddress(address, mapInstance, item, mapMarkers, placeMarker);
          }
        });

        // Set markers state and cluster them
        setMarkers(mapMarkers);
        createMarkerCluster(mapInstance, mapMarkers);
      }
    };

    loadMapWithMarkers();
  }, [mapData, mapInstance]);

  const handleSearchClick = () => {
    if (!illnessType || !selectedMonth || !selectedYear) {
      setError('Please select an illness type, month, and year.');
      return;
    }

    setError(''); // Clear error if valid
    fetchFilteredData(illnessType, selectedMonth, selectedYear, setMapData, setRecordCount)
      .then(() => {
        // Call the callback function with selected values
        onFiltersChange(illnessType, selectedMonth, selectedYear);
      });
  };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src*="maps.googleapis.com/maps/api/js"]`);
        if (existingScript) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC3z2lXM5KHqIcGoO7nSv6QKDnKkTBACBg&libraries=places`; 
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Error loading Google Maps script.'));
        document.head.appendChild(script);
      });
    };

    loadGoogleMapsScript().then(() => {
      initializeMap();
    }).catch((error) => {
      console.error(error.message);
    });
  }, [initializeMap]);

  return (
    <div className="container mt-4">
      <div className="row mb-3">
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="illnessType">Select Illness Type:</label>
            <select
              id="illnessType"
              className="form-control"
              value={illnessType}
              onChange={(e) => setIllnessType(e.target.value)}
            >
              <option value="">Select Illness</option>
              {illnessTypes.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="month">Select Month:</label>
            <select
              id="month"
              className="form-control"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Select Month</option>
              {months.map((month, index) => (
                <option key={index} value={index + 1}>{month}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label htmlFor="year">Select Year:</label>
            <select
              id="year"
              className="form-control"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="map-search-div">
            <button className="map-search-btn" onClick={handleSearchClick}>
              Search
            </button>
          </div>
        </div>
      </div>
     

      {/*  validation error message */}
      {error && <div className="alert alert-danger">{error}</div>}

      <div id="map" ref={googleMapRef} style={{ height: '600px', width: '100%' }}></div>
    </div>
  );
};


export default GoogleMapComponent;
