import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Alert, Pagination } from 'react-bootstrap';
import { getArchivedDispensedRecords, restoreDispensedRecord, searchArchivedDistributedRecordsAll } from './Controller/inventory_controller';
import PaginationComponent from './UI/Pagination'; 

const ArchivedMedicineDispensedRecords = () => {
  const [archivedRecords, setArchivedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Function to fetch records based on current filters and pagination
  const fetchArchivedRecords = async (page = currentPage) => {
    try {
      const { records, totalPages } = await getArchivedDispensedRecords(page, 20, startDate, endDate);
      console.log('Fetched Records:', records); // Debugging line
      setArchivedRecords(records);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching records:', error.message);
      setFailedMessage('Failed to fetch archived records.');
    }
  };

  // Fetch archived records on component mount and whenever date filters or page changes
  useEffect(() => {
    fetchArchivedRecords();
  }, [startDate, endDate, currentPage]);

  // Handle search with date filtering
  const handleSearch = async () => {
    try {
      const filteredRecords = await searchArchivedDistributedRecordsAll(searchQuery, startDate, endDate);
      console.log('Filtered Records:', filteredRecords); // Debugging line
      setArchivedRecords(filteredRecords);
      setTotalPages(1); // Assuming search results are shown on one page
      setCurrentPage(1); // Reset to page 1
    } catch (error) {
      console.error('Error fetching search results:', error.message);
      setFailedMessage('Failed to fetch search results.');
    }
  };

  // Handle restore action
  const handleRestoreRecord = async (recordId) => {
    const confirmRestore = window.confirm('Are you sure you want to restore this record?');
    if (!confirmRestore) {
      return;
    }
    try {
      await restoreDispensedRecord(recordId);
      fetchArchivedRecords(currentPage); // Refresh after restoration
      setSuccessMessage('Record restored successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error restoring record:', error.message);
      setFailedMessage('Record restoration failed!');
      setTimeout(() => setFailedMessage(''), 3000);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Handle page data loading or fetching here
    console.log(`Selected page: ${page}`);
  };
  return (
    <div className="main-content">
      <div className="page-header">ARCHIVED DISTRIBUTED MEDICINE AND VITAMINS RECORDS</div>
      <div className="row align-items-center">
      <div className="col-md-3 d-flex align-items-center">
        <p className="mb-0 me-2">From:</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
              className="form-control date-input"
            />
          </div>
          <div className="col-md-3 d-flex align-items-center">
          <p className="mb-0 me-2 text-nowrap">To:</p>
          <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
              className="form-control date-input"
            />
          </div>
        <div className="col-md-6">
          <div className="search-bar" style={{ marginTop: '15px' }}>
            <div className="search-bar-inner">
              <input
                type="text"
                placeholder="Search record"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <button
                className="search-button"
                onClick={handleSearch}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-usertable">
        <div className="msg">
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
              {successMessage}
            </Alert>
          )}
        </div>
        <div className="msg">
          {failedMessage && (
            <Alert variant="danger" onClose={() => setFailedMessage('')} dismissible>
              {failedMessage}
            </Alert>
          )}
        </div>

        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Generic Name</th>
                <th>Batch No.</th>
                <th>Located</th>
                <th>Source</th>
                <th>Patient Name</th>
                <th>QTY</th>
                <th>Date Given</th>
                <th>Given By</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {archivedRecords.length > 0 ? (
                archivedRecords.map((record) => (
                  <tr key={record._id}>
                    <td>{record.name}</td>
                    <td>{record.genericName}</td>
                    <td>{record.batchNumber}</td>
                    <td>{record.storageLocation}</td>
                    <td>{record.source}</td>
                    <td>{`${record.patientFName} ${record.patientLName}`}</td>
                    <td>{record.qtyToGive}</td>
                    <td>{record.dispenseDate}</td>
                    <td>{record.dispenseBy}</td>
                    <td>{record.remarks}</td>
                    <td className='fixed-action-th'>
                      <button className="btn btn-sm btn-success" onClick={() => handleRestoreRecord(record._id)}>
                      <FontAwesomeIcon icon={faArrowRotateLeft} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center">No records found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      </div>
    </div>
  );
};

export default ArchivedMedicineDispensedRecords;
