import React from 'react';
import { Table, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt,faPlus } from '@fortawesome/free-solid-svg-icons';

const ServiceVisitsTable = ({ visits, recordId, onAddVisitClick, onUpdateVisitClick, onDeleteVisitClick }) => {
  // Function to handle the delete action with confirmation
  const handleDelete = (visitId) => {
    const confirmation = window.prompt("Please type DELETE RECORD to confirm deletion.");
    if (confirmation === "DELETE RECORD") {
      if (window.confirm("Are you absolutely sure you want to delete this visit record? This action cannot be undone!")) {
        onDeleteVisitClick(visitId);
      }
    } else {
      alert("Deletion canceled. Please type DELETE RECORD to confirm.");
    }

  };

  return (
    <>
    <div className="container-usertable">
    <div className="d-flex justify-content-between align-items-center mb-3">
    <div className="page-header">SERVICE VISITS</div>
    <div className="add-user-button">
      <Button variant="info"  onClick={onAddVisitClick} className="mb-3">
      <FontAwesomeIcon icon={faPlus} /> ADD A VISIT</Button></div>
       </div>
      <div className="user-table">
         <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Methods Used/Supplies Given</th>
            <th>Remarks</th>
            <th>Name of Provider</th>
            <th>Next Service Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {visits.map((visit) => (
            <tr key={visit._id}>
              <td>{visit.date}</td>
              <td>{visit.methodsAndSupplies}</td>
              <td>{visit.remarks}</td>
              <td>{visit.providerName}</td>
              <td>{visit.nextServiceDate}</td>
              <td>
              {/*  <Button
              className="btn btn-sm btn-primary me-2"
              onClick={() => onUpdateVisitClick(visit)} >
                <FontAwesomeIcon icon={faEdit} />
                </Button> */}
                <Button
                   className="btn btn-sm btn-danger" onClick={() => handleDelete(visit._id)} >
                       <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table> </div> </div>
    </>
  );
};

export default ServiceVisitsTable;
