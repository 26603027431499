import axios from 'axios';
import PrenatalRecordModel from '../Model/PrenatalRecordModel';

const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api'; // Update with your API base URL

// Function to add a prenatal record
export const addPrenatalRecord = async (record) => {
  try {
    // Create an instance of PrenatalRecordModel using record data
    const prenatalRecord = new PrenatalRecordModel(record);
    console.log('adding controller record:', prenatalRecord);

    // Send POST request to API endpoint with the serialized data from the model instance
    const response = await axios.post(`${BASE_URL}/prenatalRecords/prenatalRecords`, prenatalRecord);
    
    return response.data; // Return response data
  } catch (error) {
    console.error('Error adding prenatal record:', error);
    throw error;
  }
};

// delete the record
export const deletePrenatalRecord = async (id) => {
  try {
    await axios.put(`${BASE_URL}/prenatalRecords/archive-prenatalRecords/${id}`);
  } catch (error) {
    console.error('Error archiving prenatal record:', error);
    throw error;
  }
};

// Function to fetch prenatal records
export const getPrenatalRecords = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/prenatalRecords/prenatalRecords`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching prenatal records:', error);
    throw error;
  }
};


// Function to fetch a single prenatal record by ID
export const getPrenatalRecordById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/prenatalRecords/prenatalRecords/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching prenatal record by ID:', error);
    throw error;
  }
};

// Function to update a prenatal record by ID
export const updatePrenatalRecord = async (id, updatedRecord) => {
  try {
    console.log('controller record:', updatedRecord);

    const prenatalRecord = new PrenatalRecordModel(updatedRecord);

    const response = await axios.put(`${BASE_URL}/prenatalRecords/prenatalRecords/${id}`, prenatalRecord, {
    headers: {
      'Content-Type': 'application/json'
  }
});
    return response.data; // Return response data
  } catch (error) {
    console.error('Error updating prenatal record:', error);
    throw error;
  }
};

export const fetchLabResults = async (patientId) => {
  try {
    const response = await axios.get(`${BASE_URL}/prenatalRecords/labResults/${patientId}`);
    console.log("data:", response.data);
    return response.data; // Return the fetched data
  } catch (error) {
    console.error('Error fetching lab results:', error);
    throw error;
  }
};

export const updateLabResults = async (patientId, updatedLabResults) => {
  try {
    const response = await axios.put(`${BASE_URL}/prenatalRecords/labResults/${patientId}`, updatedLabResults);
    console.log("update response:", response.data);
    return response.data; // Return the updated data
  } catch (error) {
    console.error('Error updating lab results:', error);
    throw error;
  }
};

export const getCheckUpsByPrenatalRecordId = async (prenatalRecordId) => {
  try {
    console.log("fetching for: ", prenatalRecordId);

    const response = await axios.get(`${BASE_URL}/prenatalRecords/${prenatalRecordId}/prenatalCheckups`);
    return response.data;
  } catch (error) {
    console.error('Error fetching check-ups:', error);
    throw error;
  }
};

export const addCheckUp = async (checkUp) => {
  try {
    console.log("cotroller recieving: ", checkUp);
    const response = await axios.post(`${BASE_URL}/prenatalRecords/prenatalCheckups`, checkUp);
    return response.data;
  } catch (error) {
    console.error('Error adding check-up:', error);
    throw error;
  }
};

export const searchPrenatalRecords = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/prenatalRecords/search-prenatal-records`, {
      params: { search: searchQuery }
    });
    console.log (response);
    return response.data;
  } catch (error) {
    console.error("Error fetching prenatal health records:", error);
    return [];
  }
};
export const updateCheckUp = async (checkUpId, updatedCheckUp) => {
  try {
    const response = await axios.put(`${BASE_URL}/prenatalRecords/prenatalCheckups/${checkUpId}`, updatedCheckUp);
    return response.data;
  } catch (error) {
    console.error('Error updating check-up:', error);
    throw error;
  }
};

export const deleteCheckUp = async (id) => {
  try {
    await axios.delete(`${BASE_URL}/prenatalRecords/delete-checkup/${id}`);
  } catch (error) {
    console.error('Error deleting prenatal checkup record:', error);
    throw error;
  }
};


export const searchArchivedPrenatalRecords = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/prenatalRecords/search-archived-prenatal-records`, {
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching prenatal records:", error);
    return [];
  }
};

export const getArchivedPrenatalRecords = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/prenatalRecords/archivedPrenatalRecords`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching archived prenatal records:', error);
    throw error;
  }
};

// Restore archived prenatal record
export const restorePrenatalRecord = async (id) => {
  try {
    const response = await axios.put(`${BASE_URL}/prenatalRecords/restore-prenatalRecords/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error restoring prenatal record:', error);
    throw error;
  }
};