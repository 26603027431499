class PrenatalRecordModel {
  constructor({
    fName = '',
    mName  = '',
    lName = '',
    dob = '',
    houseNumber = '',
    streetName = '',
    sitio = '',
    occupation = '',
    contactNumber = '',
    emergencyContactPerson = '',
    emergencyContactPersonNumber = '',
    philHealthNumber = '',
    comorbidity = '',
    allergy = '',
    obstetricScoreG = '',
    obstetricScoreT = '',
    obstetricScoreP = '',
    obstetricScoreA = '',
    obstetricScoreL = '',
    lmp = '',
    edc = '',
    tdShotsHistory = '',
  }) {
    this.fName = fName;
    this.mName  = mName ;
    this.lName = lName;
    this.dob = dob;
    this.houseNumber = houseNumber;
    this.streetName = streetName;
    this.sitio = sitio;
    this.occupation = occupation;
    this.contactNumber = contactNumber;
    this.emergencyContactPerson = emergencyContactPerson;
    this.emergencyContactPersonNumber = emergencyContactPersonNumber;
    this.philHealthNumber = philHealthNumber;
    this.comorbidity = comorbidity;
    this.allergy = allergy;
    this.obstetricScoreG = obstetricScoreG; 
    this.obstetricScoreT = obstetricScoreT;
    this.obstetricScoreP = obstetricScoreP;
    this.obstetricScoreA = obstetricScoreA;
    this.obstetricScoreL = obstetricScoreL;
    this.lmp = lmp;
    this.edc = edc;
    this.tdShotsHistory = tdShotsHistory;
  }
}

export default PrenatalRecordModel;
