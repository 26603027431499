import axios from "axios";

const API_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api'; 

export const createSchedule = async (scheduleData) => {
  try {
    console.log("controller");
      const response = await axios.post(`${API_URL}/timeSlots/schedule`, scheduleData);  // Check that the URL matches the mounted route
      return response.data;
  } catch (error) {
      console.error("Error creating schedule:", error);
      throw error;
  }
};
export const getScheduleForDate = async (date) => {
  try {
    console.log(date);
    const response = await axios.get(`${API_URL}/timeSlots/schedule/${date}`);
    console.log('fetch',response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching schedule for date:', error);
    throw error;
  }
};

export const deleteSchedule = async (date, purpose) => {
  try {
    console.log("delete");
    const response = await axios.delete(`${API_URL}/timeSlots/schedule`, {
      data: { date, purpose },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting schedule:', error);
    throw error;
  }
};