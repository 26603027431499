import axios from 'axios';

const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api';


export const fetchAnalyticsData = async (illnessType, month, year) => {
  try {
    const params = new URLSearchParams();
    
    // Only append illnessType if it's provided
    if (illnessType) {
      params.append('illness', illnessType);
    }
    
    if (month && year) {
      params.append('month', month);
      params.append('year', year);
    }

    const response = await axios.get(`${BASE_URL}/map/analytics`, {
      params: params
    });
    
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch analytics data:`, error);
    return null;
  }
};
export const fetchDescriptiveResponse = async (illnessType, month, year) => {
  try {
    const params = new URLSearchParams();
    
    // Only append illnessType if it's provided
    if (illnessType) {
      params.append('illness', illnessType);
    }
    
    if (month && year) {
      params.append('month', month);
      params.append('year', year);
    }

    const response = await axios.get(`${BASE_URL}/map/get-descriptive-response`, {
      params: params
    });
    
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch descriptive response data:`, error);
    return null;
  }
};
export const fetchTimeSeriesData = async (illnessType, year) => {
  try {
    const params = new URLSearchParams();
    
    // Only append illnessType if it's provided
    if (illnessType) {
      params.append('illness', illnessType);
    }
    
    if (year) {
      params.append('year', year);
    }

    const response = await axios.get(`${BASE_URL}/map/time-series-analytics`, {
      params: params
    });

    return response.data;
  } catch (error) {
    console.error(`Failed to fetch time series data:`, error);
    return null;
  }
};
