import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../recordStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fetchLabResults,updateLabResults  } from '../Controller/PrenatalRecord_controller'; // Adjust path as needed

const LaboratoryTestResults = ({ prenatalRecordId }) => {
  //  lab results
  const [labResults, setLabResults] = useState({
    cbc: {
      date: '',
      wbc: '',
      rbc: '',
      hb: '',
      hct: '',
      platelets: ''
    },
    urinalysis: {
      date: '',
      color: '',
      clarity: '',
      pH: '',
      glucosePositive: '',
      glucoseNegative: '',
      bloodPositive: '',
      bloodNegative: '',
      ketonesPositive: '',
      ketonesNegative: '',
      proteinPositive: '',
      proteinNegative: '',
      bilirubinPositive: '',
      bilirubinNegative: '',
      wbc: '',
      rbc: '',
      sGravity: ''
    },
    ogtt: {
      result: ''
    },
    vdrl: {
      result: ''
    },
    hiv: {
      result: ''
    }
  });

  // manage edit mode
  const [editable, setEditable] = useState(false);
  // manage loading
  const [loading, setLoading] = useState(true);

  // handle form field changes
  const handleFieldChange = (event, category) => {
    const { name, value } = event.target;
    setLabResults((prevResults) => ({
      ...prevResults,
      [category]: {
        ...prevResults[category],
        [name]: value
      }
    }));
  };

  const toggleEditMode = () => {
    setEditable(true);
  };

  const cancelEditMode = () => {
    setEditable(false);
  };
  const [successMessage, setSuccessMessage] = useState('');

  const saveChanges = async () => {
    try {
      await updateLabResults(prenatalRecordId, labResults);
      setEditable(false);
     //refresh the UI
      await fetchLabResultsData();
      setSuccessMessage('Laboratory results updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); 
    } catch (error) {
      console.error('Error saving lab results:', error);
     
    }
  };

  const fetchLabResultsData = async () => {
    try {
      const fetchedLabResults = await fetchLabResults(prenatalRecordId);
      console.log("fetch results: ", fetchedLabResults);

      if (fetchedLabResults && fetchedLabResults.length > 0) {
        setLabResults(fetchedLabResults[0]); // Assuming the fetched results are an array with one object
      }

      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error('Error fetching lab results:', error);
      setLoading(false); // Ensure loading is set to false in case of error
    
    }
  };

  // Fetch lab results on component mount
  useEffect(() => {
    fetchLabResultsData();
  }, [prenatalRecordId]); // Include prenatalRecordId as dependency

  // Display loading spinner or message while fetching data
  if (loading) {
    return (
      <Container className="lab-test-results">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="lab-test-results">
      <Form>
      <div className = "msg">{successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>  )} </div>
        <Row>
          <Col className="left-column">
            {/* CBC Test Results */}
            <fieldset>
              <legend>CBC</legend>
              <Row>
                <Col>
                  <Form.Group controlId="cbcDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      value={labResults.cbc.date} 
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'cbc')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="wbc">
                    <Form.Label>WBC</Form.Label>
                    <Form.Control
                      type="text"
                      name="wbc"
                      value={labResults.cbc.wbc}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'cbc')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="rbc">
                    <Form.Label>RBC</Form.Label>
                    <Form.Control
                      type="text"
                      name="rbc"
                      value={labResults.cbc.rbc}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'cbc')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="hb">
                    <Form.Label>Hb</Form.Label>
                    <Form.Control
                      type="text"
                      name="hb"
                      value={labResults.cbc.hb}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'cbc')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="hct">
                    <Form.Label>Hct</Form.Label>
                    <Form.Control
                      type="text"
                      name="hct"
                      value={labResults.cbc.hct}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'cbc')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="platelets">
                    <Form.Label>Platelets</Form.Label>
                    <Form.Control
                      type="text"
                      name="platelets"
                      value={labResults.cbc.platelets}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'cbc')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            {/* URINALYSIS Test Results */}
            <fieldset>
            <div className = "record-divider">  <legend>URINALYSIS</legend>
              <Row>
                <Col>
                  <Form.Group controlId="urinalysisDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      value={labResults.urinalysis.date}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="color">
                    <Form.Label>Color</Form.Label>
                    <Form.Control
                      type="text"
                      name="color"
                      value={labResults.urinalysis.color}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="clarity">
                    <Form.Label>Clarity</Form.Label>
                    <Form.Control
                      type="text"
                      name="clarity"
                      value={labResults.urinalysis.clarity}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="pH">
                    <Form.Label>pH</Form.Label>
                    <Form.Control
                      type="text"
                      name="pH"
                      value={labResults.urinalysis.pH}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="glucosePositive">
                    <Form.Label>Glucose (+)</Form.Label>
                    <Form.Control
                      type="text"
                      name="glucosePositive"
                      value={labResults.urinalysis.glucosePositive}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="glucoseNegative">
                    <Form.Label>Glucose (-)</Form.Label>
                    <Form.Control
                      type="text"
                      name="glucoseNegative"
                      value={labResults.urinalysis.glucoseNegative}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="bloodPositive">
                    <Form.Label>Blood (+)</Form.Label>
                    <Form.Control
                      type="text"
                      name="bloodPositive"
                      value={labResults.urinalysis.bloodPositive}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="bloodNegative">
                    <Form.Label>Blood (-)</Form.Label>
                    <Form.Control
                      type="text"
                      name="bloodNegative"
                      value={labResults.urinalysis.bloodNegative}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="ketonesPositive">
                    <Form.Label>Ketones (+)</Form.Label>
                    <Form.Control
                      type="text"
                      name="ketonesPositive"
                      value={labResults.urinalysis.ketonesPositive}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="ketonesNegative">
                    <Form.Label>Ketones (-)</Form.Label>
                    <Form.Control
                      type="text"
                      name="ketonesNegative"
                      value={labResults.urinalysis.ketonesNegative}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="proteinPositive">
                    <Form.Label>Protein (+)</Form.Label>
                    <Form.Control
                      type="text"
                      name="proteinPositive"
                      value={labResults.urinalysis.proteinPositive}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="proteinNegative">
                    <Form.Label>Protein (-)</Form.Label>
                    <Form.Control
                      type="text"
                      name="proteinNegative"
                      value={labResults.urinalysis.proteinNegative}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <Form.Group controlId="bilirubinPositive">
                    <Form.Label>Bilirubin (+)</Form.Label>
                    <Form.Control
                      type="text"
                      name="bilirubinPositive"
                      value={labResults.urinalysis.bilirubinPositive}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="bilirubinNegative">
                    <Form.Label>Bilirubin (-)</Form.Label>
                    <Form.Control
                      type="text"
                      name="bilirubinNegative"
                      value={labResults.urinalysis.bilirubinNegative}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="urinalysisWBC">
                    <Form.Label>WBC</Form.Label>
                    <Form.Control
                      type="text"
                      name="wbc"
                      value={labResults.urinalysis.wbc}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="urinalysisRBC">
                    <Form.Label>RBC</Form.Label>
                    <Form.Control
                      type="text"
                      name="rbc"
                      value={labResults.urinalysis.rbc}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="sGravity">
                    <Form.Label>Specific Gravity</Form.Label>
                    <Form.Control
                      type="text"
                      name="sGravity"
                      value={labResults.urinalysis.sGravity}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'urinalysis')}
                    />
                  </Form.Group>
                </Col>
              </Row></div>
            </fieldset> 
          </Col>
          <Col className="right-column">
            {/* OGTT Test Results */}
            <fieldset>
           <legend>OGTT</legend>
              <Row>
                <Col>
                  <Form.Group controlId="ogttResult">
                    <Form.Label>Result</Form.Label>
                    <Form.Control
                      type="text"
                      name="result"
                      value={labResults.ogtt.result}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'ogtt')}
                    />
                  </Form.Group>
                </Col>
              </Row> 
            </fieldset>

            {/* VDRL Test Results */}
            <fieldset>
            <div className ="record-divider"> <legend>VDRL</legend>
              <Row>
                <Col>
                  <Form.Group controlId="vdrlResult">
                    <Form.Label>Result</Form.Label>
                    <Form.Control
                      type="text"
                      name="result"
                      value={labResults.vdrl.result}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'vdrl')}
                    />
                  </Form.Group>
                </Col>
              </Row> </div>
            </fieldset>

            {/* HIV Test Results */}
            <fieldset>
            <div className ="record-divier"> <legend>HIV Screening</legend>
              <Row>
                <Col>
                  <Form.Group controlId="hivResult">
                    <Form.Label>Result</Form.Label>
                    <Form.Control
                      type="text"
                      name="result"
                      value={labResults.hiv.result}
                      disabled={!editable}
                      onChange={(e) => handleFieldChange(e, 'hiv')}
                    />
                  </Form.Group>
                </Col>
              </Row> </div>
            </fieldset>
          </Col>
        </Row>

        {/* Buttons for Edit/Save/Cancel */}
        <Row className="mt-3">
          <Col>
            {!editable && (
                    <div className="text-right mt-3">
                <div className="add-user-button"><Button variant="info" onClick={toggleEditMode}>
                  <FontAwesomeIcon icon={faEdit} /> UPDATE
              </Button> </div></div> 
            )}
            {editable && (
              <>
                <Button variant="success" onClick={saveChanges} className="me-2">
                <FontAwesomeIcon icon={faSave} />  Save
                </Button>
                <Button variant="danger"   onClick={cancelEditMode}>
                <FontAwesomeIcon icon={faTimes} />  Cancel
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default LaboratoryTestResults;
