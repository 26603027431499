import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getCheckUpsByPrenatalRecordId, addCheckUp, updateCheckUp, deleteCheckUp } from '../Controller/PrenatalRecord_controller'; // Adjust import path as needed
import '../App.css';

const CheckUpTable = ({ prenatalRecordId }) => {
  const [checkUps, setCheckUps] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCheckUp, setCurrentCheckUp] = useState(null);
  const [newCheckUp, setNewCheckUp] = useState({
    prenatalRecordId,
    date: '',
    aog: '',
    bp: '',
    wt: '',
    bmi: '',
    fh: '',
    fht: '',
    remarks: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCheckUps();
  }, [prenatalRecordId]);

  const fetchCheckUps = async () => {
    try {
      const data = await getCheckUpsByPrenatalRecordId(prenatalRecordId);
      setCheckUps(data);
    } catch (error) {
      console.error('Error fetching check-ups:', error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!newCheckUp.date) newErrors.date = 'Date is required';
    if (!newCheckUp.aog) newErrors.aog = 'AOG is required';
    if (!newCheckUp.bp) newErrors.bp = 'BP is required';
    if (!newCheckUp.wt) newErrors.wt = 'WT is required';
    if (!newCheckUp.bmi) newErrors.bmi = 'BMI is required';
    if (!newCheckUp.fh) newErrors.fh = 'FH is required';
    if (!newCheckUp.fht) newErrors.fht = 'FHT is required';
    if (!newCheckUp.remarks) newErrors.remarks = 'Remarks is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddCheckUp = async () => {
    if (!validateForm()) return;

    try {
      await addCheckUp(newCheckUp);
      fetchCheckUps();
      handleCloseModal();
      setSuccessMessage('Check-up added successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); // Hide message after 3 seconds
    } catch (error) {
      console.error('Error adding check-up:', error);
    }
  };

  const handleEditCheckUp = async () => {
    if (!validateForm()) return;

    try {
      await updateCheckUp(currentCheckUp._id, newCheckUp);
      fetchCheckUps();
      handleCloseModal();
      setSuccessMessage('Check-up updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); // Hide message after 3 seconds
    } catch (error) {
      console.error('Error updating check-up:', error);
    }
  };

  const handleDeleteCheckUp = async (id) => {
      const confirmation = window.prompt("Please type DELETE RECORD to confirm deletion.");
      if (confirmation === "DELETE RECORD") {
        if (window.confirm("Are you absolutely sure you want to delete this visit record? This action cannot be undone!")) {
          try {
            await deleteCheckUp(id);
            fetchCheckUps();
            setSuccessMessage('Check-up deleted successfully!');
            setTimeout(() => setSuccessMessage(''), 3000); // Hide message after 3 seconds
          } catch (error) {
            console.error('Error deleting check-up:', error);
          }
        }
      } else {
        alert("Deletion canceled. Please type DELETE RECORD to confirm.");
      }
  
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCheckUp(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const openAddModal = () => {
    setIsEditing(false);
    setShowModal(true);
  };

  const openEditModal = (checkUp) => {
    setCurrentCheckUp(checkUp);
    setNewCheckUp(checkUp);
    setIsEditing(true);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(() => {
      setIsEditing(false);
      setCurrentCheckUp(null);
      setNewCheckUp({
        prenatalRecordId,
        date: '',
        aog: '',
        bp: '',
        wt: '',
        bmi: '',
        fh: '',
        fht: '',
        remarks: ''
      });
      setErrors({});
    }, 300); // Delay state reset until modal is fully closed
  };

  return (
    <>
      <div className="container-usertable">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="page-header">CHECK UP RECORDS</div>
        <div className="add-user-button"> 
          <Button variant="info" className='all-button' onClick={openAddModal}>
          <FontAwesomeIcon icon={faPlus} /> ADD CHECK UP</Button>   </div>
      </div>

        <div className="msg">
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
              {successMessage}
            </Alert>
          )}
        </div>
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th className='prenatal-tbl'>Date </th>
                <th className='prenatal-tbl'>AOG </th>
                <th className='prenatal-tbl'>Blood Pressure <span>(mmHg)</span></th>
                <th className='prenatal-tbl'>Weight <span>(kg)</span></th>
                <th className='prenatal-tbl'>BMI <span>(kg/m²)</span></th>
                <th className='prenatal-tbl'>Fundal Height <span>(cm)</span></th>
                <th className='prenatal-tbl'>Fetal Heart Tones <span>(bpm)</span></th>
                <th className="fixed-header">Remarks</th>
                <th className='prenatal-tbl'>Action</th>
              </tr>
            </thead>
            <tbody>
              {checkUps.map((checkUp, index) => (
                <tr key={index}>
                  <td>{checkUp.date}</td>
                  <td>{checkUp.aog}</td>
                  <td>{checkUp.bp}</td>
                  <td>{checkUp.wt}</td>
                  <td>{checkUp.bmi}</td>
                  <td>{checkUp.fh}</td>
                  <td>{checkUp.fht}</td>
                  <td class="fixed-cell">
                <div class="scrollable-content">{checkUp.remarks} </div></td>
                  <td>
                   {/*  <Button className="btn btn-sm btn-primary me-2" onClick={() => openEditModal(checkUp)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button> */}
                    <Button className="btn btn-sm btn-danger" onClick={() => handleDeleteCheckUp(checkUp._id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Update Checkup Record' : 'Check-up Information Form'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Row>
            <Col>  
            <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
              <Form.Control 
                type="date" 
                name="date" 
                value={newCheckUp.date} 
                isInvalid={!!errors.date}
                onChange={handleInputChange} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group></Col>
            <Col>  
            <Form.Group controlId="formAog">
              <Form.Label>AOG <span>(weeks)</span></Form.Label>
              <Form.Control 
                type="number" 
                name="aog" 
                value={newCheckUp.aog} 
                isInvalid={!!errors.aog}
                onChange={handleInputChange} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.aog}
              </Form.Control.Feedback>
            </Form.Group></Col></Row>
            <Row>
              <Col>
             <Form.Group controlId="formBp">
              <Form.Label>Blood Pressure <span>(mmHg)</span></Form.Label>
               <Form.Control 
                type="number" 
                name="bp" 
                value={newCheckUp.bp} 
                isInvalid={!!errors.bp}
                onChange={handleInputChange} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.bp}
              </Form.Control.Feedback>
            </Form.Group></Col>
            <Col><Form.Group controlId="formWt">
              <Form.Label>Weight  <span>(kg)</span></Form.Label>
              <Form.Control 
                type="number" 
                name="wt" 
                value={newCheckUp.wt} 
                isInvalid={!!errors.wt}
                onChange={handleInputChange} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.wt}
              </Form.Control.Feedback>
            </Form.Group></Col>
            </Row>
            <Row>
            <Col>
            <Form.Group controlId="formBmi">
              <Form.Label>BMI <span>(kg/m²)</span></Form.Label>
              <Form.Control 
                type="number" 
                name="bmi" 
                value={newCheckUp.bmi} 
                isInvalid={!!errors.bmi}
                onChange={handleInputChange} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.bmi}
              </Form.Control.Feedback>
            </Form.Group></Col>
            <Col>  <Form.Group controlId="formFh">
              <Form.Label>Fundal Height <span>(cm)</span></Form.Label>
              <Form.Control 
                type="number" 
                name="fh" 
                value={newCheckUp.fh} 
                isInvalid={!!errors.fh}
                onChange={handleInputChange} 
              />
              <Form.Control.Feedback type="invalid">
                {errors.fh}
              </Form.Control.Feedback>
            </Form.Group></Col>
            <Col> <Form.Group controlId="formFht">
              <Form.Label>Fetal Heart Tones <span>(bpm)</span></Form.Label>
              <Form.Control 
                type="number" 
                name="fht" 
                value={newCheckUp.fht} 
                onChange={handleInputChange} 
                isInvalid={!!errors.fht}

              />
               <Form.Control.Feedback type="invalid">
                {errors.fht}
              </Form.Control.Feedback>
            </Form.Group></Col></Row>
            <Form.Group controlId="formRemarks">
              <Form.Label>Remarks</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3} 
                name="remarks" 
                value={newCheckUp.remarks} 
                isInvalid={!!errors.remarks}
                onChange={handleInputChange} 
              />
               <Form.Control.Feedback type="invalid">
                {errors.remarks}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button 
            variant="primary" 
            onClick={isEditing ? handleEditCheckUp : handleAddCheckUp}
          >
            {isEditing ? 'Save Changes' : 'Add Check-Up'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CheckUpTable;
