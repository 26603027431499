import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

function HealthWorkerPatientRecord() {
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    // Dummy data for illustration purposes. Replace with real fetch logic.
    setPatients([
      { id: 1, fname: 'John', mname: 'Alexander', lname: 'Doe', email: 'john@example.com', contactNumber: '1234567890' },
      { id: 2, fname: 'Jane', mname: 'Anderson', lname: 'Smith', email: 'jane@example.com', contactNumber: '0987654321' }
    ]);
  };

  const handleViewPatientDetails = (id) => {
    navigate(`/patients/${id}`);
  };

  return (
    <div className="health-worker-precord-container">
      <div className="main-content">
        <div className='page-header'>Patient Records</div>
        <table className="patient-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {patients.map(patient => (
              <tr key={patient.id}>
                <td>{patient.fname}</td>
                <td>{patient.mname}</td>
                <td>{patient.lname}</td>
                <td>{patient.email}</td>
                <td>{patient.contactNumber}</td>
                <td>
                  <Button variant="info" onClick={() => handleViewPatientDetails(patient.id)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HealthWorkerPatientRecord;
