import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse,faArrowRotateLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Alert, Pagination } from 'react-bootstrap';
import { getArchivedInventoryRecords, restoreInventoryRecord, searchArchivedInventoryRecords } from './Controller/inventory_controller';
import PaginationComponent from './UI/Pagination'; 

const ArchivedInventoryRecords = () => {
  const [archivedRecords, setArchivedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Fetch archived inventory records based on pagination and date filters
  const fetchArchivedRecords = async (page = currentPage) => {
    try {
      const { records, totalPages } = await getArchivedInventoryRecords(page, 20, startDate, endDate);
      setArchivedRecords(records);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      setFailedMessage('Failed to fetch archived inventory records.');
    }
  };

  // Fetch records when the component mounts or when filters change
  useEffect(() => {
    fetchArchivedRecords();
  }, [startDate, endDate, currentPage]);

  // Search function
  const handleSearch = async () => {
    try {
      const filteredRecords = await searchArchivedInventoryRecords(searchQuery, startDate, endDate);
      setArchivedRecords(filteredRecords);
      setTotalPages(1); // Reset to 1 page for search results
      setCurrentPage(1); 
    } catch (error) {
      setFailedMessage('Failed to fetch search results.');
    }
  };

  // Handle record restoration
  const handleRestoreRecord = async (recordId) => {
    const confirmRestore = window.confirm('Are you sure you want to restore this record?');
    if (!confirmRestore) return;

    try {
      await restoreInventoryRecord(recordId);
      fetchArchivedRecords(currentPage); // Refresh records after restoration
      setSuccessMessage('Record restored successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      setFailedMessage('Failed to restore record.');
      setTimeout(() => setFailedMessage(''), 3000);
    }
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Handle page data loading or fetching here
    console.log(`Selected page: ${page}`);
  };
  return (
    <div className="page-container">
      <div className="page-header"><FontAwesomeIcon icon={faWarehouse} className="inventory-icon"/> ARCHIVED INVENTORY RECORDS</div>
      <div className="container-usertable">

      <div className="row align-items-center">
      <div className="col-md-3 d-flex align-items-center">
            <p className="mb-0 me-2 text-nowrap">From:</p>
            <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
                className="form-control"
            />
            </div>

            <div className="col-md-3 d-flex align-items-center">
            <p className="mb-0 me-2 text-nowrap">To:</p>
            <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
                className="form-control"
            />
            </div>

        <div className="col-md-6">
          <div className="search-bar" style={{ marginTop: '0px' }}>
            <div className="search-bar-inner">
              <input
                type="text"
                placeholder="Search record"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <button
                className="search-button"
                onClick={handleSearch}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </div>
          </div>
      </div>
      </div>

       <div className='msg'> {successMessage && (
          <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
            {successMessage}
          </Alert>
        )}
        {failedMessage && (
          <Alert variant="danger" onClose={() => setFailedMessage('')} dismissible>
            {failedMessage}
          </Alert>
        )}</div>

        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Generic Name</th>
                <th>Brand</th>
                <th>Expiration Date</th>
                <th>QTY</th>
                <th>Category</th>
                <th class="fixed-header">Description</th>
              <th class="fixed-header-2">Manufacturer</th>
                <th>Location</th>
                <th>Source</th>
                <th>Batch No.</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {archivedRecords.length > 0 ? (
                archivedRecords.map((record) => (
                  <tr key={record._id}>
                    <td>{record.name}</td>
                    <td>{record.genericName}</td>
                    <td>{record.brand}</td>
                    <td>{record.expirationDate}</td>
                    <td>{record.qty}</td>
                    <td>{record.category}</td>
                    <td class="fixed-cell">
                <div class="scrollable-content">{record.description}   </div></td>
                <td>{record.manufacturer}</td>
                    <td>{record.storageLocation}</td>
                    <td>{record.source}</td>
                    <td>{record.batchNumber}</td>
                    <td className='fixed-action-th'>
                      <button className="btn btn-sm btn-success" onClick={() => handleRestoreRecord(record._id)}>
                      <FontAwesomeIcon icon={faArrowRotateLeft} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">No records found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      </div>
    </div>
  );
};

export default ArchivedInventoryRecords;
