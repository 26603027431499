import React from 'react';
import ReactPaginate from 'react-paginate';
import '../recordStyles.css';

const PaginationComponent = ({ currentPage, totalPages, handlePageChange }) => {
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1; // Pages in react-paginate are zero-indexed, so add 1
    handlePageChange(selectedPage);
  };

  return (
    <ReactPaginate
      previousLabel={'Previous'}
      nextLabel={'Next'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={totalPages}
      marginPagesDisplayed={2} 
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      forcePage={currentPage - 1} //
      containerClassName={'pagination'}
      activeClassName={'active'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      nextClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextLinkClassName={'page-link'}
      breakLinkClassName={'page-link'}
    />
  );
};

export default PaginationComponent;
