import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { addFamilyPlanningRecord } from '../Controller/FamilyPlanningRecord_controller';
import '../recordStyles.css';
import StreetDropdown from './requiredStreetDrowdown'; 

function AddFamilyPlanningRecordModal({ show, handleClose, onSuccess, onError }) {
  const [formState, setFormState] = useState({
    lName: '', fName: '', mName: '', dob: '', highestEduc: '', occupation: '', spouseLname: '', spouseFname: '',
    spouseMname: '', spouseDob: '', spouseHighestEduc: '', spouseOccupation: '', houseBuildingNumber: '', streetName: '', sitio:'', income: '', clientNo: '',
    planMoreChildren: '', noOfChildren: '', fpReason: '', acceptorType: '', previousMethod: '',
    acceptedMethods: { STER: false, IUD: false, PILL: false, INJDMPA: false, NFP: false, CALRYTHM: false, CONDOM: false, OTHER: false },
    heent: { epilepsy: false, headache: false, visualDisturbance: false, yellowishConjunctiva: false, enlargedThyroid: false },
    chestHeart: { chestPain: false, shortnessOfBreath: false, breastMasses: false, nippleDischarges: false, systolicBP: false, diastolicBP: false, familyHistory: false },
    abdomen: { abdominalMass: false, gallbladderDisease: false, liverDisease: false },
    genital: { uterineMass: false, vaginalDischarge: false, intermenstrualBleeding: false, postcoitalBleeding: false },
    extremities: { varicosities: false, extremityPain: false },
    skin: { yellowishSkin: false },
    medicalHistory: { smoking: false, allergies: false, drugIntake: false, std: false, multiplePartners: false, bleedingTendencies: false, anemia: false, diabetes: false },
    obstetricalHistory: { pregnancies: '', fullTerm: '', premature: '', abortion: '', livingChildren: '', lastDeliveryDate: '', lastDeliveryType: '', pastMenstrualPeriod: '', lastMenstrualPeriod: '', menstrualBleeding: '', hydatidiformMole: false, ectopicPregnancy: false },
    physicalExamination: { bloodPressure: '', bweight: '', bpulseRate: '' },
    conjunctiva: { pale: false, yellowish: false },
    neck: { enlargeThyroid: false, enlargeLympNodes: false },
    breast: { leftBreast: {
      upperLeft: false,
      upperRight: false,
      lowerLeft: false,
      lowerRight: false,
    },
    rightBreast: {
      upperLeft: false,
      upperRight: false,
      lowerLeft: false,
      lowerRight: false,
    },
      mass: false, nippleDischarge: false, skin: false, enlargedAxillaryLympNodes: false },
    thorax: { abnormalHeartSounds: false, abnormalBreathSounds: false },
    physicalExaminationAbdomen: { enlargedLiver: false, abdominalMass: false, tenderness: false },
    physicalExaminationExtremities: { edema: false, varicosities: false },
    physicalExaminationOthers: '',
    perineum: { scars: false, warts: false, reddish: false, laceration: false, consistency: {firm:false, soft:false} },
    vagina: { congested: false, bartholinCyst: false, vaginalWarts: false, skenesGland: false, rectocele: false, cystocele: false },
    cervix: { congested: false, erosion: false, discharge: false, polyps: false, lacerationColor: false, pinkish: false, bluish: false },
    uterus: { position: '', size: '', mass: '' },
    adnexa: { normal: false, mass: false, tenderness: false }
  });
  const formatLabel = (label) => {
    return label.replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/_/g, ' ') // Handle snake_case if needed
                .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const nameParts = name.split('.');

    const updateNestedState = (obj, keys, newValue) => {
      if (keys.length === 1) {
        return { ...obj, [keys[0]]: newValue };
      }
      const key = keys[0];
      return {
        ...obj,
        [key]: updateNestedState(obj[key], keys.slice(1), newValue),
      };
    };

    const newValue = type === 'checkbox' ? checked : value;

    setFormState((prevState) => {
      return updateNestedState(prevState, nameParts, newValue);
    });
  };

  
  const [currentPage, setCurrentPage] = useState(1);

  const handlePrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const validatePage1 = () => {
    const { lName, fName, highestEduc, dob, occupation,spouseLname, spouseFname, spouseDob, 
      spouseHighestEduc, spouseOccupation, streetName, sitio, income, clientNo, planMoreChildren, 
      noOfChildren, fpReason, acceptorType, previousMethod, acceptedMethods}  = formState;
      
     // Convert numeric fields to numbers if they are in string format
      const parsedIncome = Number(income);
      const parsedClientNo = Number(clientNo); 
      // Validate all fields
  return lName.trim() !== '' && fName.trim() !== '' && highestEduc.trim() !== '' && dob.trim() !== '' &&
         occupation.trim() !== '' && spouseLname.trim() !== '' && spouseFname.trim() !== '' && spouseDob.trim() !== '' &&
         spouseHighestEduc.trim() !== '' && spouseOccupation.trim() !== '' && streetName.trim() !== '' &&
         sitio.trim() !== '' && !isNaN(parsedIncome) && parsedIncome > 0 && // Ensure income is a number and greater than 0
         !isNaN(parsedClientNo) &&  parsedClientNo> 0 &&
         planMoreChildren.trim() !== '' &&
         fpReason.trim() !== '' && acceptorType.trim() !== '' && previousMethod.trim() !== '';
};
  const handleNext = () => {
    if (currentPage === 1 && !validatePage1()) {
      alert('Please fill out all required fields.');
      return;
    }
  
    setCurrentPage((prevPage) => prevPage + 1);
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addFamilyPlanningRecord(formState);
      console.log('Form submitted successfully', response);
      onSuccess(); // Notify the parent component of success
      handleClose();      
    } catch (error) {
      console.error('Error submitting the form', error);
      if (error.response && error.response.data.message === 'Record already exists') {
        onError('Record already exists. Please check the details and try again.');
        handleClose();      
      } else {
        onError('An unexpected error occurred. Please try again.');
        handleClose();      
      }
    }
  };

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <div>
            <h5>Client Information</h5> <span style={{ color: 'red' }}>Required Fields (*)</span>
            <Row className='row-divider'>
              <Col>
                <Form.Group>
                  <Form.Label>First Name  <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control type="text" name="fName" value={formState.fName} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Middle Initial</Form.Label>
                  <Form.Control type="text" name="mName" value={formState.mName}  onChange={handleChange} maxLength={1} required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group> 
                  <Form.Label>Last Name <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control type="text" name="lName" value={formState.lName} onChange={handleChange} required  />
                </Form.Group>
              </Col> 
            </Row>
            <Row className='row-divider'> <Col> <Form.Group>
              <Form.Label>Date of Birth  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="date" name="dob" value={formState.dob} onChange={handleChange} required/>
            </Form.Group>  </Col>
            <Col><Form.Group>
              <Form.Label>Highest Education  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control as="select" name="highestEduc" value={formState.highestEduc} onChange={handleChange}required>
                <option value="">Select</option>
                <option value="None">None</option>
                <option value="Elementary">Elementary</option>
                <option value="HighSchool">High School</option>
                <option value="College">College</option>
                <option value="PostGraduate">Post Graduate</option>
              </Form.Control> 
            </Form.Group></Col> </Row>
           <Row className='row-divider'> <Form.Group>
              <Form.Label>Occupation  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="text" name="occupation" value={formState.occupation} onChange={handleChange}required />
            </Form.Group></Row>
            <div className='form-row-div'><h5>Spouse Information</h5>
            <Row className='row-divider'>
              <Col>
                <Form.Group>
                  <Form.Label>First Name  <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control type="text" name="spouseFname" value={formState.spouseFname}  onChange={handleChange} required/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control type="text" name="spouseMname" value={formState.spouseMname} onChange={handleChange}required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name  <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control type="text" name="spouseLname" value={formState.spouseLname} onChange={handleChange} required/>
                </Form.Group>
              </Col>
            </Row>
            <Row className='row-divider'> <Col>
            <Form.Group>
              <Form.Label>Spouse Date of Birth  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="date" name="spouseDob" value={formState.spouseDob} onChange={handleChange} required/>
            </Form.Group>  </Col>
            <Col> <Form.Group>
              <Form.Label>Spouse Highest Education  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control as="select" name="spouseHighestEduc" value={formState.spouseHighestEduc}  onChange={handleChange}required>
                <option value="">Select</option>
                <option value="None">None</option>
                <option value="Elementary">Elementary</option>
                <option value="HighSchool">High School</option>
                <option value="College">College</option>
                <option value="PostGraduate">Post Graduate</option>
              </Form.Control> 
            </Form.Group></Col> </Row>
            <Form.Group>
              <Form.Label>Spouse Occupation  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="text" name="spouseOccupation" value={formState.spouseOccupation}  onChange={handleChange} required/>
            </Form.Group> </div>
            <Row className='row-divider'>
            <Col><Form.Group>
              <Form.Label>House/Bldg Number </Form.Label>
              <Form.Control type="text" name="houseBuildingNumber" value={formState.houseBuildingNumber}  onChange={handleChange} required/>
            </Form.Group></Col>
            <Col>  <Form.Group controlId="streetName">
                  <Form.Label>Street Name <span style={{ color: 'red' }}>*</span></Form.Label>
                  <StreetDropdown
                    name="streetName"
                    value={formState.streetName}
                    onChange={handleChange}
                    required                  />
                </Form.Group>
            </Col>
             </Row>
             <Row className='row-divider'>
             <Col>
            <Form.Group>
            <Form.Label>Sitio/Subdivision <span style={{ color: 'red' }}>*</span></Form.Label>
            <Form.Control
              as="select"
              name="sitio"
              value={formState.sitio}
              onChange={handleChange}
              required
            >
              <option value="">Select Sitio</option>
              {[
                'Aba Homes', 'Avida', 'Birmingham', 'Cuesta Verde', 'Dalaya', 'Dalig I', 'Dalig II', 
                'Dalig III', 'Gloria Heights', 'Junmar Subd', 'Kamatisan', 'Kaytinaga', 'La Terraza de Antipolo', 
                'Lores Executive', 'Lower Zigzag', 'Lugo', 'Maia Alta', 'Milagros', 'Nayong Silangan', 
                'Parugan Blk I', 'Parugan Blk II', 'Payong', 'Robbis/Courtyard', 'San Antonio', 'Sta. Maria', 
                'Sto. Niño', 'Synergy', 'Terrazas Verde', 'Tubigan', 'Upper Zigzag', 'Woodrow'
              ].map((sitio) => (
                <option key={sitio} value={sitio}>
                  {sitio}
                </option>
              ))}
            </Form.Control>
          </Form.Group> </Col>
             </Row>
            <Row className='row-divider'>
            <Col><Form.Group>
              <Form.Label>Average Monthly Family Income <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="number" name="income" value={formState.income} onChange={handleChange} required/>
            </Form.Group> </Col>
            <Col>  <Form.Group>
              <Form.Label>Client No  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="number" name="clientNo" value={formState.clientNo} onChange={handleChange} required/>
            </Form.Group>
              </Col></Row>
           <Row className='row-divider'> 
              <Col> <Form.Group>
            <Form.Label> Plan for More Children <span style={{ color: 'red' }}>*</span> </Form.Label>
            <Form.Control
              as="select"
              name="planMoreChildren" value={formState.planMoreChildren} onChange={handleChange} required >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Control>  </Form.Group> </Col>
            <Col> <Form.Group>
                <Form.Label>No of Living Children</Form.Label>
                <Form.Control type="number" name="noOfChildren" value={formState.noOfChildren}onChange={handleChange} />
              </Form.Group> </Col></Row>
           <Row className='row-divider'> <Form.Group>
              <Form.Label>Reason for Practicing Family Planning  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="text" name="fpReason" value={formState.fpReason}  onChange={handleChange} required/>
            </Form.Group></Row>
            <Row className='row-divider'> <Col><Form.Group>
              <Form.Label>Type of Acceptor  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control as="select" name="acceptorType" value={formState.acceptorType}  onChange={handleChange}required>
                <option value="">Select</option>
                <option value="newAcceptor">New to Program</option>
                <option value="continuingUser">Continuing User</option>
              </Form.Control> 
            </Form.Group> </Col>
            <Col> <Form.Group>
              <Form.Label>Previous Method Used  <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control type="text" name="previousMethod" value={formState.previousMethod} onChange={handleChange}required/>
            </Form.Group> </Col> </Row>
            <Form.Group>
            <Row className='row-divider'>
              <div><div className="family-planning-form-check h-100">
              <h6>Methods Accepted</h6>
            <Row> {Object.keys(formState.acceptedMethods).map((method, index) => (
                <Col xs={6} key={method}>
                  <Form.Check
                    type="checkbox"
                    label={method.toUpperCase()}
                    name={`acceptedMethods.${method}`}
                    checked={formState.acceptedMethods[method]}
                    onChange={handleChange}
                  />
                </Col> ))}</Row></div></div></Row>
          </Form.Group>
          </div>
        );
      case 2:
        return (
          <div>
            <h5>Medical History</h5>
          <Row className="row-divider align-items-stretch">  <Col md={6}>
            <div className='family-planning-form-check h-100'>
              <h6>HEENT</h6>
              {Object.keys(formState.heent).map((method) => {
                const customLabels = {
                  epilepsy: 'Epilepsy/Convulsion/Seizure',
                  headache: 'Severe Headache/Dizziness',
                  visualDisturbance: 'Visual disturbance/blurring of vision',
                  nose: 'Nose',
                  throat: 'Throat',
                };

                const label = customLabels[method] || formatLabel(method);
                return (
                  <Form.Check
                    key={method}
                    type="checkbox"
                    label={label}
                    name={`heent.${method}`}
                    checked={formState.heent[method]}
                    onChange={handleChange}
                  />
                );
              })}
            </div>
          </Col>
          <Col md={6}>
              <div className='family-planning-form-check h-100'>
                <h6>Chest/Heart</h6>
                {Object.keys(formState.chestHeart).map((method) => {
                  const customLabels = {
                    chestPain: 'Severe chest pain',
                    shortnessOfBreath: 'Shortness of breath and easily fatigue',
                    breastMasses: 'Breast/axillary masses',
                    nippleDischarges: 'Nipple discharge',
                    systolicBP: 'Systolic of 140 and above',
                    diastolicBP: 'Diastolic of 90 and above',
                    familyHistory: 'Family history of CVA (stokes), hypertension, asthma, rheumatic heart disease',
                  };
                  const label = customLabels[method] || formatLabel(method);
                  return (
                    <Form.Check
                      key={method}
                      type="checkbox"
                      label={label}
                      name={`chestHeart.${method}`}
                      checked={formState.chestHeart[method]}
                      onChange={handleChange}
                    />
                  );
                })}
              </div>
            </Col>
            </Row>
           <Row className="row-divider align-items-stretch"> <Col>
              <div className='family-planning-form-check h-100'>
                <h6>Abdomen</h6>
                {Object.keys(formState.abdomen).map((method) => {
                  const customLabels = {
                    abdominalMass: 'Mass in abdomen',
                    gallbladderDisease: 'History of gallbladder disease',
                    liverDisease: 'History of liver disease',
                  };
                  const label = customLabels[method] || formatLabel(method);

                  return (
                    <Form.Check
                      key={method}
                      type="checkbox"
                      label={label}
                      name={`abdomen.${method}`}
                      checked={formState.abdomen[method]}
                      onChange={handleChange}
                    />
                  );
                })}
              </div>
            </Col>
            <Col> <div className='family-planning-form-check h-100'>
              <h6>Genital</h6>
            {Object.keys(formState.genital).map((method) => (
              <Form.Check
                key={method}
                type="checkbox"
                label={formatLabel(method)}
                name={`genital.${method}`}
                checked={formState.genital[method]}
                onChange={handleChange} />
            ))}  </div> </Col></Row> 
           <Row className="row-divider align-items-stretch"> 
           <Col>
          <div className='family-planning-form-check h-100'>
            <h6>Extremities</h6>

            {Object.keys(formState.extremities).map((method) => {
              const customLabels = {
                varicosities: 'Severe varicosities',
                extremityPain: 'Swelling or severe pain the legs not related to injuries',
              };

              const label = customLabels[method] || formatLabel(method);
              return (
                <Form.Check
                  key={method}
                  type="checkbox"
                  label={label}
                  name={`extremities.${method}`}
                  checked={formState.extremities[method]}
                  onChange={handleChange}
                />
              );
            })}
          </div>
        </Col>

            <Col><div className='family-planning-form-check h-100'><h6>Skin</h6>
            <Form.Check
              type="checkbox"
              label="Yellowish Skin"
              name="skin.yellowishSkin"
              checked={formState.skin.yellowishSkin}
              onChange={handleChange}/> 
              </div></Col> </Row> 
              <Row className="row-divider align-items-stretch">
                <div>
              <div className="family-planning-form-check h-100">
                <h6>History of Any of the Following</h6>
                <Form.Group>
                  <Row>
                    {Object.keys(formState.medicalHistory).map((method) => {
                      const customLabels = {
                        std: 'STD',
                        bleedingTendencies: 'Bleeding tendecies (nose, gums, etc.)',
                      };
                      const label = customLabels[method] || formatLabel(method);

                      return (
                        <Col xs={6} key={method} style={{ marginBottom: '3px' }}>
                          <Form.Check
                            type="checkbox"
                            label={label}
                            name={`medicalHistory.${method}`}
                            checked={formState.medicalHistory[method]}
                            onChange={handleChange}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Form.Group>
              </div></div>
            </Row>
            <div className='family-planning-form-check h-100'> 

            <Row className="row-divider align-items-stretch">
            <h5>Obstetrical History</h5>
            <Col><Form.Group>
              <Form.Label>No. of Pregnancies</Form.Label>
              <Form.Control type="number" name="obstetricalHistory.pregnancies" value={formState.obstetricalHistory.pregnancies}  onChange={handleChange} />
            </Form.Group> </Col>
            <Col> <Form.Group>
              <Form.Label>Full Term Deliveries</Form.Label>
              <Form.Control type="number" name="obstetricalHistory.fullTerm" value={formState.obstetricalHistory.fullTerm} onChange={handleChange} />
            </Form.Group></Col>
            <Col><Form.Group>
              <Form.Label>Premature Deliveries</Form.Label>
              <Form.Control type="number" name="obstetricalHistory.premature" value={formState.obstetricalHistory.premature} onChange={handleChange} />
            </Form.Group></Col> </Row>
            <Row> <Col><Form.Group>
              <Form.Label>Abortion</Form.Label>
              <Form.Control type="number" name="obstetricalHistory.abortion" value={formState.obstetricalHistory.abortion} onChange={handleChange} />
            </Form.Group></Col>
            <Col> <Form.Group>
              <Form.Label>No. of Living Children</Form.Label>
              <Form.Control type="number" name="obstetricalHistory.livingChildren" value={formState.obstetricalHistory.livingChildren} onChange={handleChange} />
            </Form.Group></Col> </Row>
            <Row className="row-divider align-items-stretch"><Col><Form.Group>
              <Form.Label>Last Delivery Date</Form.Label>
              <Form.Control type="date" name="obstetricalHistory.lastDeliveryDate" value={formState.obstetricalHistory.lastDeliveryDate}  onChange={handleChange} />
            </Form.Group></Col>
            <Col> <Form.Group>
              <Form.Label>Type of Last Delivery</Form.Label>
              <Form.Control as="select" name="obstetricalHistory.lastDeliveryType" value={formState.obstetricalHistory.lastDeliveryType}  onChange={handleChange}>
                <option value="">Select</option>
                <option value="normal">Normal</option>
                <option value="caesarian">Caesarian</option>
                <option value="forceps">Forceps</option>
              </Form.Control> 
            </Form.Group> </Col></Row>
            <Row> <Col>
              <Form.Group className="form-group-row">
                <Form.Label>Past Menstrual Period</Form.Label>
                <Form.Control 
                  type="date" 
                  name="obstetricalHistory.pastMenstrualPeriod" 
                  value={formState.obstetricalHistory.pastMenstrualPeriod} 
                  onChange={handleChange} 
                />
              </Form.Group>
            </Col><Col>
              <Form.Group className="form-group-row">
                <Form.Label>Last Menstrual Period</Form.Label>
                <Form.Control 
                  type="date" 
                  name="obstetricalHistory.lastMenstrualPeriod" 
                  value={formState.obstetricalHistory.lastMenstrualPeriod} 
                  onChange={handleChange} 
                />
              </Form.Group>
            </Col> <Col>
              <Form.Group className="form-group-row">
                <Form.Label>Days of Menstrual Bleeding</Form.Label>
                <Form.Control 
                  type="text" 
                  name="obstetricalHistory.menstrualBleeding" 
                  value={formState.obstetricalHistory.menstrualBleeding} 
                  onChange={handleChange} 
                />
              </Form.Group>
            </Col></Row></div>
           <Row className="row-divider align-items-stretch">
           <div> <div className='family-planning-form-check h-100'>
            <h6>History of Any of the Following</h6>
            <Form.Check
              type="checkbox"
              label="Hydatidiform Mole (within the last 12 months)"
              name="obstetricalHistory.hydatidiformMole"
              checked={formState.obstetricalHistory.hydatidiformMole}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="Ectopic Pregnancy"
              name="obstetricalHistory.ectopicPregnancy"
              checked={formState.obstetricalHistory.ectopicPregnancy}
              onChange={handleChange}
            /></div> </div></Row>
          </div>
        );
      case 3:
        return (
          <div>
            <h5>Physical Examination</h5>
        
            <div className='family-planning-form-check h-100'> 
            <Row className="row-divider align-items-stretch"><Col>
            <Form.Group>
              <Form.Label>BP (mmHg)</Form.Label>
              <Form.Control type="number" name="physicalExamination.bloodPressure"  value={formState.physicalExamination.bloodPressure}  onChange={handleChange} />
            </Form.Group></Col>
            <Col><Form.Group>
              <Form.Label>Weight (kg)</Form.Label>
              <Form.Control type="number" name="physicalExamination.weight"  value={formState.physicalExamination.weight}  onChange={handleChange} />
            </Form.Group></Col>
            <Col> <Form.Group>
              <Form.Label>Pulse Rate (/min)</Form.Label>
              <Form.Control type="number" name="physicalExamination.pulseRate"  value={formState.physicalExamination.pulseRate}  onChange={handleChange} />
            </Form.Group> </Col></Row></div>
            <Row className="row-divider align-items-stretch">
              <Col><div className='family-planning-form-check h-100'> 
            <h6>Conjunctiva</h6>
             {Object.keys(formState.conjunctiva).map((method) => (
              <Form.Check
                key={method}
                type="checkbox"
                label={formatLabel(method)}
                name={`conjunctiva.${method}`}
                checked={formState.conjunctiva[method]}
                onChange={handleChange}
              /> ))} </div></Col>
            <Col>
            <div className='family-planning-form-check h-100'> <h6>Neck</h6>
            {Object.keys(formState.neck).map((key) => (
              <Form.Check
                type="checkbox"
                label={formatLabel(key)}
                name={`neck.${key}`}
                onChange={handleChange}
                key={key}
              /> ))}</div></Col> 
              </Row>
           <div> 
           <Row className="family-planning-breast-form">
          <h6>Breast</h6>
          <Col md={3}>
            <div className='breast-container'>
              {['mass', 'nippleDischarge', 'skin', 'enlargedAxillaryLympNodes'].map((condition) => (
                <Form.Check
                  key={condition}
                  type="checkbox"
                  label={condition === 'skin' ? 'Skin - Orange Peel or Dimpling' : formatLabel(condition)}  // Custom label for 'skin'
                  name={`breast.${condition}`}
                  checked={formState.breast[condition]}
                  onChange={handleChange}
                />
              ))}
            </div>
          </Col>

        {/* Left Breast Circle */}
        <Col md={3}>
          <h6>Left Breast</h6>
          <div className="breast-diagram">
            <label className="breast-quadrant upper-left">
              <input
                type="checkbox"
                name="breast.leftBreast.upperLeft"
                checked={formState.breast.leftBreast.upperLeft}
                onChange={handleChange}
              />
              <span className='span-upper-left'>Upper Left</span>
            </label>

            <label className="breast-quadrant upper-right">
              <input
                type="checkbox"
                name="breast.leftBreast.upperRight"
                checked={formState.breast.leftBreast.upperRight}
                onChange={handleChange}
              />
              <span className='span-upper-right'>Upper Right</span>
            </label>

            <label className="breast-quadrant lower-left">
              <input
                type="checkbox"
                name="breast.leftBreast.lowerLeft"
                checked={formState.breast.leftBreast.lowerLeft}
                onChange={handleChange}
              />
              <span className='span-lower-left'>Lower Left</span>
            </label>

            <label className="breast-quadrant lower-right">
              <input
                type="checkbox"
                name="breast.leftBreast.lowerRight"
                checked={formState.breast.leftBreast.lowerRight}
                onChange={handleChange}
              />
              <span className='span-lower-right'>Lower Right</span>
            </label>
          </div>
        </Col>

        {/* Right Breast Circle */}
        <Col md={3}>
          <h6>Right Breast</h6>
          <div className="breast-diagram">
            <label className="breast-quadrant upper-left">
              <input
                type="checkbox"
                name="breast.rightBreast.upperLeft"
                checked={formState.breast.rightBreast.upperLeft}
                onChange={handleChange}
              />
              <span className='span-upper-left'>Upper Left</span>
            </label>

            <label className="breast-quadrant upper-right">
              <input
                type="checkbox"
                name="breast.rightBreast.upperRight"
                checked={formState.breast.rightBreast.upperRight}
                onChange={handleChange}
              />
              <span className='span-upper-right'>Upper Right</span>
            </label>

            <label className="breast-quadrant lower-left">
              <input
                type="checkbox"
                name="breast.rightBreast.lowerLeft"
                checked={formState.breast.rightBreast.lowerLeft}
                onChange={handleChange}
              />
              <span className='span-lower-left'>Lower Left</span>
            </label>

            <label className="breast-quadrant lower-right">
              <input
                type="checkbox"
                name="breast.rightBreast.lowerRight"
                checked={formState.breast.rightBreast.lowerRight}
                onChange={handleChange}
              />
              <span className='span-lower-right'>Lower Right</span>
            </label>
          </div>   
        </Col>
      </Row></div>
      <Row className="row-divider align-items-stretch">
           <Col> <div className='family-planning-form-check h-100'> <h6>Thorax</h6>
            {Object.keys(formState.thorax).map((method) => (
              <Form.Check
                key={method}
                type="checkbox"
                label={formatLabel(method)}
                name={`thorax.${method}`}
                checked={formState.thorax[method]}
                onChange={handleChange}
              /> ))}</div></Col>   
        
           <Col> <div className='family-planning-form-check h-100'> <h6>Abdomen</h6>
            {Object.keys(formState.physicalExaminationAbdomen).map((method) => (
              <Form.Check
                key={method}
                type="checkbox"
                label={formatLabel(method)}
                name={`physicalExaminationAbdomen.${method}`}
                checked={formState.physicalExaminationAbdomen[method]}
                onChange={handleChange}
              /> ))}</div></Col>   
       
           <Col> <div className='family-planning-form-check h-100'> <h6>Extremities</h6>
            {Object.keys(formState.physicalExaminationExtremities).map((method) => (
              <Form.Check
                key={method}
                type="checkbox"
                label={formatLabel(method)}
                name={`physicalExaminationExtremities.${method}`}
                checked={formState.physicalExaminationExtremities[method]}
                onChange={handleChange}
              /> ))}</div></Col>   
          </Row>
           <Row className="row-divider align-items-stretch">
           <div>

           <div className='family-planning-form-check h-100'><Form.Group>
              <Form.Label>Other Observations</Form.Label>
              <Form.Control type="text" name="physicalExaminationOthers" value={formState.physicalExaminationOthers} onChange={handleChange} />
            </Form.Group></div> </div></Row> 
          </div>
        );
      case 4:
        return (
          <div>
            <h5>Pelvic Examination</h5>
         
            <Row className="row-divider align-items-stretch"> 
            <div className='add-margin'>
               <div className='family-planning-form-check h-100'>
               <Row> 
               <Col md={6}>
                <h6>Perineum</h6>
                {Object.keys(formState.perineum).map((method) => {
                  if (method !== 'consistency') {
                    return (
                      <Form.Check
                        key={method}
                        type="checkbox"
                        label={formatLabel(method)}
                        name={`perineum.${method}`}
                        checked={formState.perineum[method]}
                        onChange={handleChange}
                      />
                    );
                  }
                  return null;
                })}
            </Col>

            {/* Right Column: Consistency checkboxes */}
            <Col md={6}>
                <h6>Consistency</h6>
                {Object.keys(formState.perineum.consistency).map((consistencyType) => (
                  <Form.Check
                    key={consistencyType}
                    type="checkbox"
                    label={formatLabel(consistencyType)}
                    name={`perineum.consistency.${consistencyType}`}
                    checked={formState.perineum.consistency[consistencyType]}
                    onChange={handleChange}
                  />
                ))}
            </Col>
          </Row> </div></div></Row> 
          <Col>
          <div className='family-planning-form-check h-100'>
            <h6>Vagina</h6>
            {Object.keys(formState.vagina).map((method) => (
              <Form.Check
                key={method}
                type="checkbox"
                label={formatLabel(method)} 
                name={`vagina.${method}`}
                checked={formState.vagina[method]}
                onChange={handleChange}
              />
            ))}
          </div>
        </Col>

           <Col><div className='family-planning-form-check h-100'><h6>Cervix</h6>
            {Object.keys(formState.cervix).map((method) => (
              <Form.Check
                key={method}
                type="checkbox"
                label={method === 'polyps' ? 'Polyps/Cysts' : formatLabel(method)}  
                name={`cervix.${method}`}
                checked={formState.cervix[method]}
                onChange={handleChange}
              />
            ))} </div></Col>
          <div className='family-planning-form-check h-100'> 
          <Row className="row-divider align-items-stretch">
          
          <h6>Uterus</h6>
            <Col> <Form.Group>
              <Form.Label>Position</Form.Label>
              <Form.Control as="select" name="uterus.position" value={formState.uterus.position}onChange={handleChange}>
                <option value="">Select</option>
                <option value="mid">Mid</option>
                <option value="anteflexed">Anteflexed</option>
                <option value="retroflexed">Retroflexed</option>
              </Form.Control>
            </Form.Group></Col>
            <Col> <Form.Group>
              <Form.Label>Size</Form.Label>
              <Form.Control as="select" name="uterus.size" value={formState.uterus.size} onChange={handleChange}>
                <option value="">Select</option>
                <option value="normal">Normal</option>
                <option value="small">Small</option>
                <option value="large">Large</option>
              </Form.Control>
            </Form.Group></Col>
            <Col>  <Form.Group>
              <Form.Label>Mass (cm)</Form.Label>
              <Form.Control type="number" name="uterus.mass" value={formState.uterus.mass}  onChange={handleChange} />
            </Form.Group></Col> </Row></div>
            <Row className="row-divider align-items-stretch"> <Col>
            <div className='family-planning-form-check h-100'> <h6>Adnexa</h6>
            {Object.keys(formState.adnexa).map((condition) => (
              <Form.Check
                key={condition}
                type="checkbox"
                label={condition}
                name={`adnexa.${condition}`}
                checked={formState.adnexa[condition]}
                onChange={handleChange}
              />
            ))} </div></Col></Row>
          </div>
        );
      default:
        return null;
    }
  };


  return (
    <div class="modal-dialog">
    <Modal show={show} onHide={handleClose}  >
      <Modal.Header closeButton>
        <Modal.Title>Add Family Planning Record</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} id="my-form">
          {renderPage()}
          </Form>
          </Modal.Body>
          <Modal.Footer>
          <div className="mt-3">
            {currentPage > 1 && (
              <Button variant="secondary" onClick={handlePrevious} className="me-2">
                Previous
              </Button>
            )}
            {currentPage < 4 && (
              <Button variant="primary" onClick={handleNext}>
                Next
              </Button>
            )}
            {currentPage === 4 && (
              <Button variant="success" type="submit" form="my-form">
                Submit
              </Button>
            )}
          </div>
          </Modal.Footer>
    </Modal> </div>
  );
}

export default AddFamilyPlanningRecordModal;