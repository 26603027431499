import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../appointmentCalendar.css'; // Import the CSS file

// Set up localizer for moment.js
const localizer = momentLocalizer(moment);

const AppointmentCalendar = ({ appointments }) => {
  const [events, setEvents] = useState([]);

  const parseTimeSlot = (dateString, timeSlot) => {
    const [startTime, endTime] = timeSlot.split(' - ');

    const start = moment(`${dateString} ${startTime}`, 'YYYY-MM-DD hh:mm A').toDate();
    const end = moment(`${dateString} ${endTime}`, 'YYYY-MM-DD hh:mm A').toDate();

    return { start, end };
  };

  // Group appointments by purpose and time slot
  const groupAppointments = (appointments) => {
    const grouped = {};

    appointments.forEach((appointment) => {
      const { date, timeSlot, purpose, patientName } = appointment;
      const key = `${date}-${timeSlot}-${purpose}`;

      if (!grouped[key]) {
        grouped[key] = {
          title: purpose,
          start: parseTimeSlot(date, timeSlot).start,
          end: parseTimeSlot(date, timeSlot).end,
          patients: [],
        };
      }

      grouped[key].patients.push(patientName);
    });

    return Object.values(grouped).map((event) => ({
      ...event,
      title: `${event.title}: ${event.patients.join(', ')}`, // Ensure patients are included in the title
    }));
  };

  useEffect(() => {
    if (appointments.length) {
      const formattedEvents = groupAppointments(appointments);
      setEvents(formattedEvents);
    }
  }, [appointments]);

  // Custom toolbar to hide navigation buttons but keep the date range
  const CustomToolbar = ({ label }) => {
    // Use moment.js to format the start and end of the week
    const startOfWeek = moment().startOf('week').format('MMMM DD');
    const endOfWeek = moment().endOf('week').format('DD');
    const formattedDate = `${startOfWeek} – ${endOfWeek}`;

    return (
      <div className="rbc-toolbar-label">
        {formattedDate}
      </div>
    );
  };

  // Custom event rendering with className for styling
  const CustomEvent = ({ event }) => (
    <span className="rbc-event-content">
      {event.title}
    </span>
  );

  return (
    <div className='calendar-main-content'>
      <div className="page-header">   <FontAwesomeIcon icon={faCalendarDays} className="inventory-icon" />Appointments This Week</div>
      <div className="calendar-container" style={{ height: '950px' }}>
        <Calendar
          localizer={localizer}
          events={events}
          defaultView="week"
          views={['week']}
          step={30}
          timeslots={1}
          min={new Date(2024, 9, 0, 8, 30)} // Start time for the day (8:30 AM)
          max={new Date(2024, 9, 0, 12, 0)} // End time for the day (12:00 PM)
          defaultDate={new Date()} // Default to today's date
          components={{
            event: CustomEvent,
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </div>
  );
};

export default AppointmentCalendar;
