import React, { useEffect, useState } from 'react';
import { Pagination, Alert, Button } from 'react-bootstrap';
import { getArchivedPrenatalRecords, restorePrenatalRecord, searchArchivedPrenatalRecords } from '../Controller/PrenatalRecord_controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFileLines,faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import PaginationComponent from './Pagination'; 

const ArchivedPrenatalTable = () => {
  const [archivedRecords, setArchivedRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchArchivedRecords = async () => {
      try {
        const data = await getArchivedPrenatalRecords(currentPage);
        if (data && Array.isArray(data.records)) {
          setArchivedRecords(data.records);
          setTotalPages(data.totalPages);
        } else {
          console.error('Unexpected data format:', data);
          setArchivedRecords([]); // Default to an empty array if format is incorrect
        }
      } catch (error) {
        console.error('Error fetching archived records:', error);
        setShowError(true);
      }
    };
    fetchArchivedRecords();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRestore = async (recordId) => {
    try {
      await restorePrenatalRecord(recordId);
      setArchivedRecords((prevRecords) => prevRecords.filter(record => record._id !== recordId));
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000); // Hide success message after 3 seconds
    } catch (error) {
      console.error('Error restoring record:', error);
      setShowError(true);
      setTimeout(() => setShowError(false), 3000); // Hide error message after 3 seconds
    }
  };

  const confirmRestore = (recordId) => {
    const userConfirmed = window.confirm("Are you sure you want to restore this record?");
    if (userConfirmed) {
      handleRestore(recordId);  // Pass the recordId directly here
    }
  };

  const handleSearch = async () => {
    try {
      const filteredRecords = await searchArchivedPrenatalRecords(searchQuery);
      setArchivedRecords(filteredRecords);
    } catch (error) {
      console.error('Error searching archived records:', error);
      setArchivedRecords([]); // Default to an empty array if there’s an error
    }
  };

  return (
    <div className="main-content">
      <div className="page-header">
        <FontAwesomeIcon icon={faFileLines} className="user-header-icon" />
        ARCHIVED PRENATAL RECORD
      </div>
       <div className="row">
          <div className="col-md-6">
            {/* Other content  */}
          </div>
          <div className="col-md-6 d-flex justify-content-end">
        <div className="search-bar">
          <div className="search-bar-inner">
            <input
              type="text"
              placeholder="Search by First Name or Last Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
            <button
              className="search-button"
              onClick={() => handleSearch(searchQuery)}
            >
              <span><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
            </button>
          </div>
        </div>
      </div>
      </div>
      {/* Success and error messages */}
      <div className='msg'>{showSuccess && <Alert variant="success">Record restored successfully!</Alert>}
      {showError && <Alert variant="danger">Error occurred while restoring the record.</Alert>}</div>

      <div className="container-usertable">
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>M.I</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {archivedRecords.length > 0 ? (
                archivedRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{record.fName}</td>
                    <td>{record.mName}</td>
                    <td>{record.lName}</td>
                    <td>{record.dob}</td>
                    <td className='fixed-action-th'>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => confirmRestore(record._id)}
                      >
                        <FontAwesomeIcon icon={faArrowRotateLeft} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">No archived records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      </div>
    </div>
  );
};

export default ArchivedPrenatalTable;
