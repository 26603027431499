import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './login.css';

function LoginForm({ onSubmit, error, setError, setUsername, setPassword, username, password, onForgotPassword, setEmail, email }) {
  const contentRef = useRef(null);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false); // State for "Forgot Password"

  useEffect(() => {
    const content = contentRef.current;
    function checkVisibility() {
      const rect = content.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (rect.top < windowHeight && rect.bottom >= 0) {
        content.classList.add('visible');
        window.removeEventListener('scroll', checkVisibility);
      }
    }
    window.addEventListener('scroll', checkVisibility);
    checkVisibility();
    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, []);

  // Email validation regex pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Function to handle mode switching
  const switchToForgotPassword = () => {
    setError(''); // Clear error message when switching
    setForgotPasswordMode(true);
  };

  const switchToLogin = () => {
    setError(''); // Clear error message when switching
    setForgotPasswordMode(false);
  };

  // Handle forgot password form submission
  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
  
    onForgotPassword(e);
  };

  return (
    <div className="login-form-content" ref={contentRef}>
      <form onSubmit={forgotPasswordMode ? handleForgotPasswordSubmit : onSubmit} className="login-form">
        {!forgotPasswordMode ? (
          <>
            <div className="login-form-group" id="login-email-field">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="input"
                placeholder="Username"
                required
              />
            </div>
            <div className="form-group" id="pass-field">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input"
                placeholder="Password"
                required
              />
            </div>
            <div className="forgot-password-link">
              <span 
                className="forgot-password-text" 
                onClick={switchToForgotPassword}
              >
                Forgot Password?
              </span>
            </div>
            <button type="submit" className="login-button">Login</button>
          </>
        ) : (
          <>
            <div className="form-group">
              <div className="forgot-password-instruction">
                <h5>Forgot Password</h5>
                <p>Please enter your registered email address</p>
              </div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Use parent's setEmail
                className="input"
                placeholder="Enter your email"
                required
              />
            </div>
            <button type="submit" className="forgot-password-button">Submit</button>
            <button
              type="button"
              className="btn btn-primary cancel-forgot-button"
              onClick={switchToLogin}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
          </>
        )}
        {!forgotPasswordMode && (
          <Link to="/" className="btn btn-primary home-button ml-2">
            <FontAwesomeIcon icon={faHome} className="mr-2" />  Back to Home
          </Link>
        )}
        {error && <div className="error">{error}</div>}
      </form>
    </div>
  );
}
export default LoginForm;
