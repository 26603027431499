import React, { useEffect, useRef, useState } from 'react';
import './landingpage.css';
import logo from './images/logo.png';
import screen from './images/screen.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle, faTimes, faEye, faBullseye, faHeart, faDownload } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
const translations = {
  en: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    login: 'Login',
    oneCareApp: 'OneCare App',
    introduction: 'Introducing OneCare a breakthrough in healthcare management for Barangay Dalig Health Center. This new platform provides an online appointment system that simplifies scheduling and enhances patient care. With OneCare, health workers can efficiently manage vital health data, leading to better patient outcomes. We are excited to empower the community with innovative healthcare solutions through OneCare.',
    download: 'Download',
    aboutUs: 'About Us',
    vision: 'Our Vision',
    mission: 'Our Mission',
    values: 'Our Values',
    visionText: 'The vision of Barangay Dalig Health Center is to be known as a friendly health facility with affordable and accessible primary health care in a manner that exceeds patient expectation.',
    missionText: 'It is the mission of Barangay Dalig Health Center to provide compassionate affordable and accessible health care to people of all ages, regardless of economic status.',
    valuesText: 'We value compassion, innovation, and integrity in delivering healthcare solutions that meet the needs of our community with respect and care.',
    contactUs: 'Contact Us',
    bhcs1: 'BHCS1',
    bhcs2: 'BHCS2',
    bhcs3: 'BHCS3',
    faqTitle: 'FAQs',
    faqQuestion1: 'What services do you offer?',
    faqAnswer1: 'Our health centers offer services such as Family Planning, Pediatrician, and Prenatal Care',
    faqQuestion2: 'What are your operating hours?',
    faqAnswer2: 'Our health centers are open from monday to friday, accommodating patients from 8:00 AM to 5:00 PM.',
    faqQuestion3: 'Will the residents of Barangay Dalig be able to use this?',
    faqAnswer3: 'Yes, this app is made for Barangay Dalig Health Centers.',
    ContactUs: 'Contact Us'
  },
  tl: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    login: 'Login',
    oneCareApp: 'OneCare App',
    introduction: 'Ipinapakilala ang OneCare, isang rebolusyon sa pamamahala ng kalusugan para sa Barangay Dalig Health Center. Ang bagong platform na ito ay nag-aalok ng isang online na appointment system na nagpapadali sa pag-schedule at nagpapabuti ng pangangalaga sa pasyente. Sa pamamagitan ng OneCare, mas madaling mapapamahalaan ng mga health workers ang mahalagang datos ng kalusugan, na nagdudulot ng mas magandang resulta para sa mga pasyente. Kami ay nasasabik na bigyan ng kapangyarihan ang komunidad gamit ang makabagong solusyon sa pamamahala ng kalusugan sa pamamagitan ng OneCare.',
    download: 'I-download',
    aboutUs: 'Tungkol sa Amin',
    vision: 'Aming Pananaw',
    mission: 'Aming Misyon',
    values: 'Aming Mga Halaga',
    visionText: 'Ang pananaw ng Barangay Dalig Health Center ay makilala bilang isang palakaibigang pasilidad na nagbibigay ng abot-kaya at madaling ma-access na pangunahing serbisyong pangkalusugan, na lumalampas sa inaasahan ng mga pasyente.',
    missionText: 'Misyon ng Barangay Dalig Health Center na magbigay ng may malasakit, abot-kaya, at madaling ma-access na serbisyong pangkalusugan para sa mga tao ng lahat ng edad, anuman ang kalagayang pang-ekonomiya.',
    valuesText: 'Pinahahalagahan namin ang pagmamalasakit, inobasyon, at integridad sa paghahatid ng mga solusyon sa pangangalagang pangkalusugan na tumutugon sa mga pangangailangan ng aming komunidad nang may respeto at pag-aalaga.',
    contactUs: 'Makipag-ugnayan sa Amin',
    bhcs1: 'BHCS1',
    bhcs2: 'BHCS2',
    bhcs3: 'BHCS3',
    faqTitle: 'Mga FAQs',
    faqQuestion1: 'Anong mga serbisyo ang inaalok ninyo?',
    faqAnswer1: 'Ang aming mga health centers ay nag-aalok ng mga serbisyo tulad ng Family Planning, Pediatrician, at Prenatal Care',
    faqQuestion2: 'Ano ang inyong mga oras ng operasyon?',
    faqAnswer2: 'Ang aming mga health centers ay bukas tuwing lunes hanggang biyernes, tumatanggap ng mga pasyente mula 8:00 AM hanggang 5:00 PM.',
    faqQuestion3: 'Magagamit ba ito ng mga residente ng Barangay Dalig?',
    faqAnswer3: 'Oo, ang app na ito ay ginawa para sa Barangay Dalig Health Centers.',
    ContactUs: 'Makipag-ugnayan sa Amin'
  }
};


function Navbar({ language, toggleLanguage }) {
  const t = translations[language];
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
      <span className="navbar-text">OneCare</span>
      
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="#mainSection">{t.home}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#About">{t.about}</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Contact">{t.contact}</a>
          </li>
          <li className="nav-item">
            <Link to="/login" className="btn btn-outline-light login-btn">{t.login}</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}


function MainSection({ language }) {
  return (
    <div className="carouselContainer" id="mainSection">
      <div className="plx-image1">
        <a className="navbar-brand" href="#">
          <img className="logo" src={logo} alt="Logo" />
        </a>
        <div className="overlay"></div>
      </div>
    </div>
  );
}

function AboutApp({ language }) {
  const t = translations[language];
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;

    function checkVisibility() {
      const rect = content.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight && rect.bottom >= 0) {
        content.classList.add('visible');
        window.removeEventListener('scroll', checkVisibility);
      }
    }

    window.addEventListener('scroll', checkVisibility);
    checkVisibility();

    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, []);

  return (
    <div className="content" id="fadeInContent" ref={contentRef}>
      <div className="row row1" id="About">
        <div className="col-lg-6 col-sm-12 custom-div1" id="div1">
          <img className="main_IMG" src={screen} alt="Image" />
          <div className="text-center">
            <button type="button" className="btn btn-dark btn-lg download-button">
            <FontAwesomeIcon icon={faDownload} /> {t.download}
            </button>
         
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 custom-div2" id="div2">
        <div className="custom-content">
          <h2>{t.oneCareApp}</h2>
          <p>{t.introduction}</p>
        </div>
        </div>
      </div>
    </div>
  );
}


function AboutUS({ language }) {
  const t = translations[language];
  const aboutRef = useRef(null);

  useEffect(() => {
    const section = aboutRef.current;

    function checkVisibility() {
      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight && rect.bottom >= 0) {
        section.classList.add('visible');
        window.removeEventListener('scroll', checkVisibility);
      }
    }

    window.addEventListener('scroll', checkVisibility);
    checkVisibility();

    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, []);

  return (
    <div className="about-section" id="About" ref={aboutRef}>
      <div className="plx-image2">
        <div className="about-container">
          <h2>{t.aboutUs}</h2>
          <div className="about-content">
            <div className="about-item">
              <FontAwesomeIcon icon={faEye} size="3x" className="about-icon" />
              <h3>{t.vision}</h3>
              <p>{t.visionText}</p>
            </div>
            <div className="about-item">
              <FontAwesomeIcon icon={faBullseye} size="3x" className="about-icon" />
              <h3>{t.mission}</h3>
              <p>{t.missionText}</p>
            </div>
            <div className="about-item">
              <FontAwesomeIcon icon={faHeart} size="3x" className="about-icon" />
              <h3>{t.values}</h3>
              <p>{t.valuesText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


function FAQ({ language }) {
  const t = translations[language]; 
  const [showChatbot, setShowChatbot] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const chatEndRef = useRef(null);

 
  const faqItems = [
    { question: t.faqQuestion1, answer: t.faqAnswer1 },
    { question: t.faqQuestion2, answer: t.faqAnswer2 },
    { question: t.faqQuestion3, answer: t.faqAnswer3 }
  ];

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  const handleQuestionClick = (item) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { type: 'question', text: item.question },
      { type: 'answer', text: item.answer }
    ]);
  };

  return (
    <>
      <div className="floating-question-icon" onClick={toggleChatbot}>
        <FontAwesomeIcon icon={faQuestionCircle} size="2x" />
      </div>
      {showChatbot && (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <h4>{t.faqTitle}</h4> {/* Title dynamically changes based on language */}
            <button onClick={toggleChatbot} className="chat-close-button">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="chatbot-content">
            <div className="chat-history">
              {chatHistory.map((chat, index) => (
                <div key={index} className={chat.type === 'question' ? 'user-bubble' : 'response-bubble'}>
                  {chat.text}
                </div>
              ))}
              <div className="question-center">
                {faqItems.map((item, index) => (
                  <div key={index} className="question-bubble" onClick={() => handleQuestionClick(item)}>
                    {item.question}
                  </div>
                ))}
              </div>
              <div ref={chatEndRef}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}


function Contact({ language }) {
  const t = translations[language] || translations['en']; 

  return (
    <div className="contact-section" id="Contact">
      <h2>{t.ContactUs}</h2>
      <div className="contact-info">
        <div className="contact-details">
          <div className="contact-item">
            <FontAwesomeIcon icon={faEnvelope} size="2x" className="contact-icon" />
            <p> <a href="mailto:jl.dalig@gmail.com">jl.dalig@gmail.com</a></p>
          </div>
          <div className="contact-item">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="contact-icon" />
            <p className="location-text"><strong>BHCS1:</strong> Milagros Subd., Barangay Dalig, Antipolo City</p>
            <p className="location-text"><strong>BHCS2:</strong> S. Pedracio Street, Antipolo, 1870 Rizal</p>
            <p className="location-text"><strong>BHCS3:</strong> Manuel L. Quezon Ext, Antipolo, 1870 Rizal</p>
          </div>
          <div className="contact-item">
          <FontAwesomeIcon icon={faPhoneAlt} size="2x" className="contact-icon" />
          <div className="phone-numbers">
            <p>
              <a href="tel:+8687-0362">8697-0362</a>
            </p>
            <p>
              <a href="tel:+8696-4097">8696-4097</a>
            </p>
          </div>
        </div>


        </div>
      </div>
    </div>
  );
}
function Footer({ language, toggleLanguage }) {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 OneCare. All rights reserved.</p>
        {/* language settings moved to footer */}
        <div className="language-toggle">
          <span>Language: </span>
          <button onClick={toggleLanguage} className="btn btn-light language-btn">
            {language === 'en' ? 'English' : 'Filipino'}
          </button>
        </div>
      </div>
    </footer>
  );
}
function LandingPage({ onLoginClick }) {
  const [language, setLanguage] = useState('en'); // English by default

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'tl' : 'en'));
  };

  return (
    <div>
      {/* Pass toggleLanguage and language to Navbar */}
      <Navbar language={language}/>
      <MainSection language={language} />
      <AboutApp language={language} />
      <AboutUS language={language} />
      <FAQ language={language} />
      <Contact language={language} />
      <Footer language={language} toggleLanguage={toggleLanguage} />

    </div>
  );
}



export default LandingPage;