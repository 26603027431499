import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddServiceVisitModal = ({ show, handleClose, recordId, handleAddVisit, initialVisit }) => {
  const [newVisit, setNewVisit] = useState({
    date: '',
    methodsAndSupplies: '',  // Combined field for methods and supplies
    remarks: '',
    providerName: '',
    nextServiceDate: '',
  });

  useEffect(() => {
    if (initialVisit) {
      setNewVisit(initialVisit);
    }
  }, [initialVisit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVisit({ ...newVisit, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add recordId to newVisit
      const visitWithId = { ...newVisit, recordId };
      await handleAddVisit(visitWithId);
      handleClose();
    } catch (error) {
      console.error('Error handling service visit:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{initialVisit ? 'Update Service Visit' : 'Service Visit Form'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} id='my-form'>
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={newVisit.date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Methods Used/Supplies Given</Form.Label>
            <Form.Control
              type="text"
              name="methodsAndSupplies"
              value={newVisit.methodsAndSupplies}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              as="textarea"
              name="remarks"
              value={newVisit.remarks}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Provider Name</Form.Label>
            <Form.Control
              type="text"
              name="providerName"
              value={newVisit.providerName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Next Service Date</Form.Label>
            <Form.Control
              type="date"
              name="nextServiceDate"
              value={newVisit.nextServiceDate}
              onChange={handleChange}
              required
            />
          </Form.Group>
          </Form>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" className="mt-4" form="my-form">
          {initialVisit ? 'Update Service Visit' : 'Add Service'}
        </Button> </Modal.Footer>
   
    </Modal>
  );
};

export default AddServiceVisitModal;
