import React, { useState, useEffect } from 'react';
import { fetchAppointmentsByDate, deleteAppointment } from '../Controller/appointment_controller';
import AppointmentTable from './appointmentTable';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const AppointmentsPage = () => {
  const [appointments, setAppointments] = useState([]); // ensure it's initialized as an empty array
  const [totalItems, setTotalItems] = useState(0); // track the total number of items
  const [currentPage, setCurrentPage] = useState(1); // track the current page
  const [itemsPerPage] = useState(20); // set items per page
  const [successMessage, setSuccessMessage] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  // Helper function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Get YYYY-MM-DD format
  };

  // Set initial dates to today when the component mounts
  useEffect(() => {
    const today = getTodayDate();
    setFromDate(today);
    setToDate(today);
  }, []);

  const loadAppointments = async (page = 1) => {
    try {
      const startDate = fromDate || getTodayDate(); // Default to today if fromDate is empty
      const endDate = toDate || getTodayDate(); // Default to today if toDate is empty

      const data = await fetchAppointmentsByDate(startDate, endDate, page, itemsPerPage);

      if (data && data.appointments) {
        setAppointments(data.appointments || []); // fallback to an empty array if undefined
        setTotalItems(data.totalItems || 0); // fallback to 0 if undefined
        setCurrentPage(data.currentPage || 1); // fallback to 1 if undefined
      } else {
        setAppointments([]); // ensure appointments is always an array
      }
    } catch (error) {
      console.error('Error loading appointments:', error);
    }
  };

  // Reload appointments when dates or page changes
  useEffect(() => {
    loadAppointments(currentPage);
  }, [fromDate, toDate, currentPage]);

  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this appointment?");
    if (confirmed) {
      try {
        await deleteAppointment(id);
        loadAppointments(currentPage); 
        setSuccessMessage('Appointment deleted successfully!');
        setTimeout(() => setSuccessMessage(''), 3000); 
      } catch (error) {
        console.error('Error deleting appointment:', error);
      }
    }
  };

  return (
    <div className="main-content">
      <div className="page-header"> Appointment Records</div>

      <div className="row mb-3">
      <div className="d-flex align-items-center mb-3">
        <div className="d-flex align-items-center me-3"> {/* Add margin-end to the first div */}
          <p className="mb-0 me-2 text-nowrap">From:</p>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="form-control date-input"
            style={{ width: 'auto' }} // Adjust width as needed
          />
        </div>
        <div className="d-flex align-items-center"> 
          <p className="mb-0 me-2 text-nowrap">To:</p>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="form-control date-input"
            style={{ width: 'auto' }} // Adjust width as needed
          />
        </div>
      </div>
      </div>
    <div className='msg'>
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )} </div>

      <AppointmentTable
        appointments={appointments}
        onDelete={handleDelete}
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default AppointmentsPage;
