import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getScheduleForDate, deleteSchedule } from './Controller/ScheduleManagement_controller'; // Adjust path as needed
import './ScheduleStyles.css';

const ScheduleCalendar = () => {
  const [scheduleDetails, setScheduleDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Optional: any initialization logic
  }, []);

  const handleDateChange = async (date) => {
    const dateString = formatDate(date);
    setSelectedDate(date);

    try {
      const data = await getScheduleForDate(dateString);
      console.log("Fetched Data:", data);

      if (Array.isArray(data)) {
        setScheduleDetails(data);
      } else if (data && typeof data === 'object') {
        setScheduleDetails([data]); 
      } else {
        setScheduleDetails([]);
        console.log("schedule details", scheduleDetails)
      }
      setCurrentIndex(0); // reset index when date is selected
    } catch (error) {
      console.error('Error fetching schedule details:', error);
      setScheduleDetails([]); // schedule details are cleared in case of error
      setCurrentIndex(0); // reset index in case of error
    }
  };

  const formatDate = (date) => {
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return utcDate.toISOString().split('T')[0];
  };

  const getTileClassName = ({ date }) => {
    const dateString = formatDate(date);
    return scheduleDetails.some(detail => detail.date === dateString) ? 'has-schedule' : '';
  };

  const handleDelete = async () => {
    const confirmation = window.prompt("Please type DELETE to confirm deletion.");
    if (confirmation === "DELETE") {
      if (window.confirm("Are you absolutely sure you want to delete this schedule? This action cannot be undone!")) {
        const details = scheduleDetails[currentIndex];
        if (details && details.purpose && selectedDate) {
          try {
            const response = await deleteSchedule(formatDate(selectedDate), details.purpose);
            console.log(response.message);
    
            // Remove the deleted schedule from the UI
            setScheduleDetails((prevDetails) =>
              prevDetails.filter((_, index) => index !== currentIndex)
            );
            setCurrentIndex(0);
          } catch (error) {
            console.error('Error deleting schedule:', error);
          }
        }
      }
    } else {
      alert("Deletion canceled. Please type DELETE to confirm.");
    }
   
  };

  const renderScheduleDetails = () => {
    if (scheduleDetails.length === 0) {
      return <p className="no-schedule">No schedule for this date.</p>;
    }
  
    const details = scheduleDetails[currentIndex] || {};
  
    return (
      <div className="schedule-details-container">
        <div className="schedule-header">
          <h4 className="schedule-purpose">{details.purpose || 'No Purpose Available'}</h4>
          <h4 className="schedule-title">{formatDate(selectedDate)}</h4>
          {scheduleDetails.length > 1 && (
            <div className="navigation-buttons">
              <button   
                className="nav-button" 
                disabled={currentIndex === 0} 
                onClick={() => setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0))}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button 
                className="nav-button" 
                disabled={currentIndex === scheduleDetails.length - 1} 
                onClick={() => setCurrentIndex(prevIndex => Math.min(prevIndex + 1, scheduleDetails.length - 1))}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
          
            </div>
          )}
        </div>
        <div className="health-centers">
          <div className="time-slot-container">
            {Object.keys(details.healthCenters || {}).map((timeSlot, index) => (
              <div key={index} className="time-slot-card">
                <h6 className="time-slot-title">{timeSlot.replace(/^0/, '')}</h6>
                <ul className="health-centers-list">
                  {Object.entries(details.healthCenters[timeSlot] || {}).map(([center, info]) => (
                    <li key={center} className="health-center-item">
                      <div className="health-center-info">
                        <span className="health-center-name">{center}</span>
                        <span className="health-center-slots">{info.slots || 0} slots</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="schedule-calendar-container">
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        tileClassName={getTileClassName}
        view="month"
      />
      <div className="details-container">
        {renderScheduleDetails()}
        {scheduleDetails.length > 0 && (
          <button className="delete-button" onClick={handleDelete}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
};
export default ScheduleCalendar;
