import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBoxArchive, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Alert, Pagination, Dropdown, DropdownButton } from 'react-bootstrap';
import { deleteDispensedRecord, searchDistributedRecordsAll, getDispensedRecordsByDateAll, getDispensedRecords } from './Controller/inventory_controller';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from './UI/Pagination'; 

const MedicineDispensedRecords = () => {
  const [dispensedRecords, setDispensedRecords] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch today's records when the component mounts
  useEffect(() => {
    const fetchDispensedRecords = async (page = 1) => {
      try {
        const { records, totalPages } = await getDispensedRecords(page);
        setDispensedRecords(records);
        setTotalPages(totalPages);
        setCurrentPage(page);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchDispensedRecords();
  }, []); // Empty dependency array to run only once on mount

  // Fetch dispensed records with pagination and optional date filter
  const fetchDispensedRecords = async (page = 1) => {
    try {
      const { records, totalPages } = await getDispensedRecordsByDateAll(page, startDate, endDate);
      setDispensedRecords(records);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Handle delete action
  const handleDeleteRecord = async (recordId) => {
    const confirmDelete = window.confirm('Are you sure you want to archive this record?');
    if (!confirmDelete) {
      return;
    }
    try {
      await deleteDispensedRecord(recordId);
      fetchDispensedRecords(currentPage); // Refresh after deletion
      setSuccessMessage('Record archived successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error(error.message);
      setFailedMessage('Record archiving failed!');
      setTimeout(() => setFailedMessage(''), 3000);
    }
  };

  // Handle search with date filtering
  const handleSearch = async () => {
    try {
      const filteredRecords = await searchDistributedRecordsAll(searchQuery, startDate, endDate);
      setDispensedRecords(filteredRecords);
      setTotalPages(1); // Assuming search results are shown on one page
      setCurrentPage(1); // Reset to page 1
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (startDate || endDate) {
      fetchDispensedRecords(); // Fetch records when date range changes
    }
  }, [startDate, endDate, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Handle page data loading or fetching here
    console.log(`Selected page: ${page}`);
  };
  const navigate = useNavigate();

  const redirectToArchivedPage = () => {
    navigate('/archived-medicine-distribution-record');
  };

  return (
    <div className="main-content">
            <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="page-header">DISTRIBUTED MEDICINE AND VITAMINS RECORDS</div>
 </div>
      <div className="container-usertable">
        <div className="row align-items-center">
        <div className="col-md-5 d-flex">
          <div className="d-flex align-items-center me-3">
        <p className="mb-0 me-2">From:</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
              className="form-control date-input"
            />
          </div>
          <div className="d-flex align-items-center me-3">
          <p className="mb-0 me-2">To: </p>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
              className="form-control date-input"
            />
          </div>  </div>
          <div className="col-md-7 d-flex align-items-center">

          <div className="archive-btn">
            <button className="btn btn-primary" onClick={redirectToArchivedPage}>
          <FontAwesomeIcon icon={faBoxArchive} /> ARCHIVE
        </button></div>
          <div className="search-bar">
              <div className="search-bar-inner">
                <input
                  type="text"
                  placeholder="Search record"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
                <button
                  className="search-button"
                  onClick={handleSearch}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </div>
            </div> 
          </div>
        </div>

        <div className="msg">
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
              {successMessage}
            </Alert>
          )}
        </div>
        <div className="msg">
          {failedMessage && (
            <Alert variant="danger" onClose={() => setFailedMessage('')} dismissible>
              {failedMessage}
            </Alert>
          )}
        </div>
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Generic Name</th>
                <th>Batch No.</th>
                <th>Located</th>
                <th>Source</th>
                <th>Patient Name</th>
                <th>QTY</th>
                <th>Date Given</th>
                <th>Given By</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dispensedRecords.map((record) => (
                <tr key={record._id}>
                  <td>{record.name}</td>
                  <td>{record.genericName}</td>
                  <td>{record.batchNumber}</td>
                  <td>{record.storageLocation}</td>
                  <td>{record.source}</td>
                  <td>{`${record.patientFName} ${record.patientLName}`}</td>
                  <td>{record.qtyToGive}</td>
                  <td>{record.dispenseDate}</td>
                  <td>{record.dispenseBy}</td>
                  <td class="fixed-cell">
                <div class="scrollable-content">{record.remarks}</div></td>
                <td className='fixed-action-th'>
                    <button className="btn btn-sm btn-danger me-2" onClick={() => handleDeleteRecord(record._id)}>
                    <FontAwesomeIcon icon={faTrash} />                  
                      </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      </div>
    </div>
  );
};

export default MedicineDispensedRecords;
