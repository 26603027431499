import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './userManagement.css';

function UserManagement() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="module-bg">
      <div className="container">
        <div className="module-header">USER MANAGEMENT</div>
        <div className="row">
          <div className="col-md-4">
            <div
              className="module-card"
              onClick={() => handleNavigation('/health-worker-accounts')}
            >
              <FontAwesomeIcon icon={faUserMd} size="2x" className="module-icon" />
              <h4>Health Worker Management</h4>
              <p>Manage health worker accounts.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="module-card"
              onClick={() => handleNavigation('/patient-accounts')}
            >
              <FontAwesomeIcon icon={faUser} size="2x" className="module-icon" />
              <h4>Patient Management</h4>
              <p>View patient user data and profiles.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="module-card"
              onClick={() => handleNavigation('/admin-accounts')}
            >
              <FontAwesomeIcon icon={faUserShield} size="2x" className="module-icon" />
              <h4>Admin Management</h4>
              <p>View admin accounts.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
