import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'; // Assuming you have an icon for the user
import logo from './images/logo.png';

function Header({ onLogout }) {
  return (
    <header className="header">
      <div className="header-left">
        <img src={logo} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
       <div className='system-name'>OneCare</div> 
      </div>
      <div className="header-right">
       
        <button onClick={onLogout} className="logout-button">
          <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} /> Logout
        </button>
      </div>
    </header>
  );
}

export default Header;
