import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import GoogleMapComponent from './GoogleMapComponent'; // Import Google Maps Component
import DataAnalytics from './DataAnalytics'; // Import Data Analytics Component
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function Dashboard() {
  const [selectedIllness, setSelectedIllness] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const handleFiltersChange = (illnessType, month, year) => {
    setSelectedIllness(illnessType);
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  useEffect(() => {
    console.log('Selected Filters:', { selectedIllness, selectedMonth, selectedYear });
  }, [selectedIllness, selectedMonth, selectedYear]);

  return (
    <div className="dashboard">
      <div className="page-header"><FontAwesomeIcon icon={faChartBar} /> HEALTH TRENDS</div>
      <div className="dashboard-content">
        <TransitionGroup component={null}>
          <CSSTransition classNames="fade" timeout={500} key="map">
            <div className="dashboard-box dashboard-map">
              <GoogleMapComponent onFiltersChange={handleFiltersChange} /> {/* Pass the function as a prop */}
            </div>
          </CSSTransition>
        </TransitionGroup>
        <DataAnalytics 
          selectedIllness={selectedIllness} 
          selectedMonth={selectedMonth} 
          selectedYear={selectedYear} 
        />
      </div>
    </div>
  );
}

export default Dashboard;
