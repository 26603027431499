import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBqP3EIAATMdNlJKsW_PoAqFZum5SUAkzc",
    authDomain: "onecare-application.firebaseapp.com",
    projectId: "onecare-application",
    storageBucket: "onecare-application.appspot.com",
    messagingSenderId: "842020441299",
    appId: "1:842020441299:web:10dee5dec122a176791826"
};

export { firebaseConfig };