
import axios from 'axios';

const API_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api'; 

export const fetchAllAppointmentToday = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/get-appointment-today`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch today\'s appointments:', error);
    throw error;
  }
};

export const fetchBhcs1Appointments = async (fromDate, toDate, page = 1, limit = 20) => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/get-bhcs1-appointments`, {
        params: { from: fromDate, to: toDate, page, limit },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
      throw error;
    }
  };
  
  export const fetchBhcs1AppointmentsToday = async () => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/get-bhcs1-appointments-today`);
      return response.data.totalAppointments;
    } catch (error) {
      console.error('Failed to fetch BHCS1 appointments for today:', error);
      throw error;
    }
  };

  export const fetchBhcs2Appointments = async (fromDate, toDate, page = 1, limit = 20) => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/get-bhcs2-appointments`, {
        params: { from: fromDate, to: toDate, page, limit },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
      throw error;
    }
  };
  
  export const fetchBhcs2AppointmentsToday = async () => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/get-bhcs2-appointments-today`);
      return response.data.totalAppointments;
    } catch (error) {
      console.error('Failed to fetch BHCS1 appointments for today:', error);
      throw error;
    }
  };
  export const fetchBhcs3Appointments = async (fromDate, toDate, page = 1, limit = 20) => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/get-bhcs3-appointments`, {
        params: { from: fromDate, to: toDate, page, limit },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch appointments:', error);
      throw error;
    }
  };
  
  export const fetchBhcs3AppointmentsToday = async () => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/get-bhcs3-appointments-today`);
      return response.data.totalAppointments;
    } catch (error) {
      console.error('Failed to fetch BHCS1 appointments for today:', error);
      throw error;
    }
  };

  export const fetchUsersRegisteredToday = async (page = 1, limit=20) => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/users-registered-today`, {
        params: { page,limit } // Pass the page parameter in the request
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch users registered today:', error);
      throw error;
    }
  };