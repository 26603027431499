import axios from 'axios';

const API_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api'; // replace with heroku soon


export const fetchAppointments = async () => {
  try {
    const response = await axios.get(`${API_URL}/appointments/get-appointment`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch appointments:', error);
    throw error;
  }
};
export const fetchAppointmentsByDate = async (fromDate, toDate, page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/get-appointment-by-date`, {
      params: { from: fromDate, to: toDate, page, limit },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch appointments:', error);
    throw error;
  }
};

export const deleteAppointment = async (id) => {
  try {
    console.log(id);
    await axios.delete(`${API_URL}/appointments/delete-appointment/${id}`);
  } catch (error) {
    console.error('Failed to delete appointment:', error);
    throw error;
  }
};

export const fetchAppointmentWeek= async () => {
  const token = sessionStorage.getItem('token'); // Get token from sessionStorage

  if (!token) {
    throw new Error('No token found');
  }

  try {
    const response = await axios.get(`${API_URL}/appointments/get-appointment-week`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    });
    console.log( response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch today\'s appointments:', error);
    throw error;
  }
};
