import React from 'react';
import { Form } from 'react-bootstrap';

const RequiredStreetDropdown = ({ name, value, onChange, disabled, required }) => {
  const streets = [
    "11 Fatima St", "1st Street", "A.J. Lico St", "Abner","Adams St", "Adelfa Street", "Alberts Street",
    "Alexandria Avenue", "Alejandro Reyes", "Almaciga St.", "Anthurium St", "Arancia", "Ascension Rd.", "Aster Sreet",
    "Atis Street", "Balthazar Street", "Bari", "Birmingham Drive", "Blk-13", "Blossom Street", "Brescia", "Bush St.",
    "Calachuchi St.", "Camia Street", "Carnation Avenue", "Carter St", "Catleya St.", "Chinesis", "Clethra",
    "Daffodil St.", "Daisy Street", "Dalia", "Dahlia Street", "Dandelion St", "Daphne", "Demetria", "Donatello Street", 
    "E Panganiban", "E. Rodriguez Avenue",   "El Toro Drive", "El Grico Steet", "F. Manalo St.", 
    "Filmore Street", "Firenza", "Florence St.","Ford Street", "Forteza Loop", "Fragola", "Gaudi St.", 
    "Gardenia", "Gauras", "Gladiola St.", "Goya St.", "Harrison St.", "Hoover St.", 
    "I. Tapales Street", "Ilang-ilang St.","Iris Street", "Ivy Street", "Jasmine St", "Jefferson St.", 
    "Jose R. De Jesus Street", "Kaimito Street","Kamatisan Rd", "Kasoy St.", "Kennedy St.", "Langka Rd", 
    "Lazones Street", "Leonardo Street", "Lincoln St", "Lirio", "Lourdes St.", "Lychee Street", 
    "Madison St.", "Magnolia Street", "Manuel L. Quezon Ext", "Maia Alta", "Mahinhin", "Maganda St", 
    "Mangga Dr", "Mango Street", "Maria Dizon St.", "Marigold St.", "Matalino St.", "Mayor F. De Jesus", 
    "Mela", "Melone", "Messina St.", "Michelangelo Street", "Milan","Milagros Avenue", "Monreale", "Mums Street", 
    "Myrtus St", "Narra St.", "Octave St.", "Olympus Drive", "Orchids St", "Palermo", "Picassso Avenue", "Piedmont St.", 
    "Pine Street", "Polk Street", "Portofino", "Primitivo Street", "Primrose St.", "R. Martinez St.", 
    "Raphael St.", "Ravenna St.", "Reagan St.", "Remedios St.", "Robbies Homes", "Rome", "Romagna St.", 
    "Roosevelt St.", "Rosal Street", "Rose Avenue", "Rose Street", "Rosetti Street", "S. Pedracio Street", 
    "Sampaguita", "Sapang Buho", "San Esteban","San Lorenzo Ruiz St.", "San Marino", "Santan Steet", 
    "Santo Rosario St.", 
    "Shasta St.", "Sicily", "Sorrento", "Sr. S. Pedracio Street", "St. Andrew St", "St. Anthony St", 
    "St. Gabriel St", "St. Ignatius St", "St. Michael", "St. Stephen St", "St. Ursula", "St. Vincent St.", 
    "Sta. Elena St", "Sto. Nino St.", "Tulip Street", "Turin", "Uva", "Velasques Street", "Venice", 
    "Washington St.", "Wilson St.", "Xavier St.",

  ];

  return (
    <Form.Control
      as="select"
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required} // Handle required fields
    >
      <option value="">Select a street</option>
      {streets.map((street, index) => (
        <option key={index} value={street}>
          {street}
        </option>
      ))}
    </Form.Control>
  );
};

export default RequiredStreetDropdown;
