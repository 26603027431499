import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getPatientById, addVaccineRecord, fetchVaccineRecord, updatePatientDetails, updateVaccineRecord, deleteVaccineRecord } from '../Model/api';
import PatientDetailsForm from './patientDetailsForm';
import VaccineRecordsTable from './vaccineRecordTable';
import PatientVisitList from './patientVisits';
import '../App.css';

const availableVaccines = [
  { name: 'BCG vaccine' },
  { name: 'Hepatitis B vaccine' },
  { name: 'Pentavalent vaccine' },
  { name: 'Oral Polio Vaccine (OPV)' },
  { name: 'Inactive Polio Vaccine (IPV)' },
  { name: 'Pneumococcal Conjugate Vaccine (PCV)' },
  { name: 'Measles, Mumps, Rubella Vaccine (MMR)' }
];

function PatientDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patient, setPatient] = useState({});
  const [vaccineRecords, setVaccineRecords] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedVaccine, setSelectedVaccine] = useState('');
  const [doses, setDoses] = useState('');
  const [date, setDate] = useState('');
  const [remarks, setRemarks] = useState('');
  const [showVaccineModal, setShowVaccineModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const patientData = await getPatientById(id);
        setPatient(patientData);
        const patientVaccineRecords = await fetchVaccineRecord(id);
        setVaccineRecords(patientVaccineRecords);
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };

    fetchPatientData();
  }, [id]);

  const handleSavePatientDetails = async (updatedPatient) => {
    try {
      const response = await updatePatientDetails(id, updatedPatient);
      setPatient(response);
    } catch (error) {
      console.error('Error updating patient details:', error);
    }
  };

  const handleDateChange = (index, date) => {
    const updatedVaccineRecords = [...vaccineRecords];
    updatedVaccineRecords[index].date = date;
    setVaccineRecords(updatedVaccineRecords);
  };

  const handleRemarksChange = (index, remarks) => {
    const updatedVaccineRecords = [...vaccineRecords];
    updatedVaccineRecords[index].remarks = remarks;
    setVaccineRecords(updatedVaccineRecords);
  };

  const handleDoseChange = (index, dose) => {
    const updatedVaccineRecords = [...vaccineRecords];
    updatedVaccineRecords[index].dose = dose;
    setVaccineRecords(updatedVaccineRecords);
  };

  const handleVaccineSelection = (e) => {
    setSelectedVaccine(e.target.value);
  };

  const handleDosesChange = (e) => {
    setDoses(e.target.value);
  };

  const handleDateInputChange = (e) => {
    setDate(e.target.value);
  };

  const handleRemarksInputChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleConfirmAddVaccineRecord = () => {
    setShowConfirmation(true);
  };

  const handleAddVaccineRecord = async () => {
    try {
      const response = await addVaccineRecord(id, { name: selectedVaccine, dose: doses, date: date, remarks: remarks });
      setVaccineRecords([...vaccineRecords, response]);
      setSelectedVaccine('');
      setDoses('');
      setDate('');
      setRemarks('');
      setShowVaccineModal(false);
      setShowConfirmation(false);
      setSuccessMessage('Vaccine record added successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error adding vaccine record:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        setErrorMessage(`Error: ${error.response.data.message || 'Failed to add vaccine record'}`);
      } else if (error.request) {
        console.error('Request data:', error.request);
        setErrorMessage('Error: No response received from the server');
      } else {
        console.error('Error message:', error.message);
        setErrorMessage(`Error: ${error.message}`);
      }
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
  };

  const handleSaveEditClick = async (recordId, index) => {
    const updatedRecord = vaccineRecords[index];
    try {
      await updateVaccineRecord(recordId, updatedRecord);
      const updatedRecords = [...vaccineRecords];
      updatedRecords[index] = updatedRecord;
      setVaccineRecords(updatedRecords);
      setSuccessMessage('Vaccine record updated successfully');
      setEditIndex(null);
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error saving vaccine record:', error);
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleSaveClick = (recordId, index) => {
    handleSaveEditClick(recordId, index);
  };

  const handleDeleteClick = async (recordId) => {
    try {
      await deleteVaccineRecord(recordId);
      setVaccineRecords(vaccineRecords.filter(record => record._id !== recordId));
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error deleting vaccine record:', error);
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const handleCreateChildHealthRecord = () => {
    navigate('/create-child-health-record');
  };

  return (
    <div className="container mt-5">
      <div className="main-content">
        <div className='page-header'>PATIENT DETAILS</div>
        <PatientDetailsForm patient={patient} onSave={handleSavePatientDetails} />

        <div className="container mt-4">
          <div className="row gx-3">
            <div className="col-md-6 mb-4 box" id="vaccine-con">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="mb-0">Vaccine Record</h2>
                <Button className="mb-0" variant="primary" onClick={() => setShowVaccineModal(true)}> <FontAwesomeIcon icon={faPlus} /> Add a Record</Button>
              </div>
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <VaccineRecordsTable
                vaccineRecords={vaccineRecords}
                handleDateChange={handleDateChange}
                handleRemarksChange={handleRemarksChange}
                handleDoseChange={handleDoseChange}
                handleEditClick={handleEditClick}
                handleSaveClick={handleSaveClick}
                handleDeleteClick={handleDeleteClick}
                editIndex={editIndex}
              />
            </div>
            <div className="col-md-6 mb-4 box" id="visit-con">
              <PatientVisitList patientId={id} />
            </div>
            <div className="col-md-12 mb-4 box">
              <Button className="mb-0" variant="primary" onClick={handleCreateChildHealthRecord}>
                Create Child Health Record
              </Button>
            </div>
          </div>
        </div>

        {/* Add Vaccine Record Modal */}
        <Modal show={showVaccineModal} onHide={() => setShowVaccineModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Vaccine Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Vaccine</Form.Label>
                <Form.Control as="select" value={selectedVaccine} onChange={handleVaccineSelection}>
                  <option value="">Select a vaccine</option>
                  {availableVaccines.map((vaccine, index) => (
                    <option key={index} value={vaccine.name}>{vaccine.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Doses</Form.Label>
                <Form.Control type="text" value={doses} onChange={handleDosesChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" value={date} onChange={handleDateInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Remarks</Form.Label>
                <Form.Control type="text" value={remarks} onChange={handleRemarksInputChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowVaccineModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmAddVaccineRecord}>
              Add Record
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Confirmation Modal */}
        <Modal show={showConfirmation} onHide={handleCloseConfirmationModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Add Vaccine Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to add this vaccine record?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmationModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddVaccineRecord}>
              Add Record
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default PatientDetails;
