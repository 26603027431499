import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Alert, Pagination, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ChildVisitRecordTable = ({ visitRecords, handleUpdateVisitRecord, handleDeleteVisitRecord }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editVisit, setEditVisit] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteVisitId, setDeleteVisitId] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const recordsPerPage = 4; // Records to display per page

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    console.log('visitRecords:', visitRecords);
  }, [visitRecords]);

  const handleEditClick = (visit) => {
    setEditVisit(visit);
    
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditVisit(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "diagnosis") {
      setIsOtherSelected(value === "Other");
    }
    setEditVisit({
      ...editVisit,
      [name]: value,
    });
  };
  
  const diagnoses = [
    'Acute Body Diarrhea', 'Acute Lower Respiratory Tract Infections', 'Acute Watery Diarrhea',
    'Animal Bites', 'Acute Respiratory Infection (5 years old above)', 'Acute Respiratory Infection (5 years old below)', 'Asthma', 'Acute Tonsillopharyngitis (ATP)', 'Chickenpox', 
    'Cholera', 'COPD', 'Dengue', 'Dermatitis', 'Diarrhea', 'Diphtheria', 'Diseases of the Heart', 
    'DMT', 'Fever', 'Filariasis', 'Gastritis', 'Genital ulcer', 'GERD', 'Gonorrhea', 'Influenza', 
    'Influenza-like illness', 'Leprosy', 'Leptospirosis', 'Malaria', 'Malnutrition', 'Measles', 
    'Meningococcemia', 'Neonatal Telanus', 'Non-neonatal Telanus', 'Paralytic Shellfish Poisoning', 
    'Pneumonia', 'Rashes', 'Schislosomiasis', 'Skin Disease', 'Syphilis', 'Typhoid and paratyphoid', 
    'Upper Respiratory Tract Infections', 'Urethral discharge', 'Urinary Tract Infection', 
    'Viral hepatitis', 'Viral meningitis', 'Other',
  ];
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const handleSaveEdit = async () => {
    try {
      await handleUpdateVisitRecord(editVisit);
      setUpdateSuccess(true);
      handleCloseEditModal();
      // Reset the form
      setEditVisit({
        visitDate: '',
        weight: '',
        height: '',
        temperature: '',
        feeding: '',
        diagnosis: '',
        prescription: '',
        remarks: '',
        checkedBy: ''
      });
  
      setTimeout(() => {
        setUpdateSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error updating visit record:', error);
    }
  };
  

  const handleDeleteClick = (visitId) => {
    const confirmation = window.prompt("Please type DELETE RECORD to confirm deletion.");
    if (confirmation === "DELETE RECORD") {
      if (window.confirm("Are you absolutely sure you want to delete this visit record? This action cannot be undone!")) {
        handleConfirmDelete(visitId);
      }
    } else {
      alert("Deletion canceled. Please type DELETE RECORD to confirm.");
    }
  };

  const handleCloseDeleteModal = () => {
    // Commented out: modal approach
    // setShowDeleteModal(false);
    // setDeleteVisitId(null);
  };

  const handleConfirmDelete = async (visitId) => {
    try {
      await handleDeleteVisitRecord(visitId);
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error deleting visit record:', error);
    }
  };

  // Pagination Logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = visitRecords.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(visitRecords.length / recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="dashboard-style">
        {/* Success Messages */}
        <div className='msg'>
          {updateSuccess && <Alert variant="success">Visit record updated successfully!</Alert>}
          {deleteSuccess && <Alert variant="success">Visit record deleted successfully!</Alert>}
        </div>

        {/* Cards for Child Visit Records */}
        <div className="container-usertable">
          {currentRecords.map((visit, index) => (
            <div className="visit-card mb-3 border rounded" key={index}>
              <div className="card-body d-flex justify-content-between align-items-start">
                <div>
                  <h5 className="visit-title">
                    <strong>{visit.visitDate}</strong>
                  </h5>
                  <p className="visit-text">
                    <span className="visit-label">Diagnosis:</span> 
                    <span className="visit-value">{visit.diagnosis}</span>
                  </p>                  
                  
                  <p className="visit-text checked">
                    <span className="visit-label">Checked By:</span> 
                    <span className="visit-value">{visit.checkedBy}</span>
                  </p>     
                </div>

             <div className="actions mt-2 text-right">
                  {/*  <span
                    className="text-primary me-3 cursor-pointer"
                    onClick={() => handleEditClick(visit)}
                  >
                    Edit
                  </span> */} 
                  <span
                    className="text-danger cursor-pointer"
                    onClick={() => handleDeleteClick(visit._id)}
                  >
                    Delete
                  </span>
                </div>
              </div>

              {/* Expandable Details */}
              <div className="expandable-details mt-0">
                {expandedIndex === index && (
                  <div className="visit-text">
                    <p className="visit-text checked">
                    <span className="visit-label">Weight:</span> 
                    <span className="visit-value">{visit.weight} kg</span>
                  </p>   
                  <p className="visit-text checked">
                    <span className="visit-label">Height:</span> 
                    <span className="visit-value">{visit.height} cm</span>
                  </p>   
                  <p className="visit-text checked">
                    <span className="visit-label">Temperature:</span> 
                    <span className="visit-value">{visit.temperature} °C </span>
                  </p>   
                  <p className="visit-text checked">
                    <span className="visit-label">Age:</span> 
                    <span className="visit-value">{visit.age}</span>
                  </p>
                  <p className="visit-text checked">
                    <span className="visit-label">Feeding:</span> 
                    <span className="visit-value">{visit.feeding}</span>
                  </p>   
                  <p className="visit-text checked">
                    <span className="visit-label">Prescription:</span> 
                    <span className="visit-value">{visit.prescription}</span>
                  </p>   
                  <p className="visit-text checked">
                    <span className="visit-label">Remarks:</span> 
                    <span className="visit-value">{visit.remarks}</span>
                  </p>   
                  </div>
                )}
                <button className="btn btn-link" onClick={() => toggleExpand(index)}>
                  {expandedIndex === index ? 'See Less' : 'See More'}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <Pagination className="pagination-custom justify-content-center mt-3">
            {[...Array(totalPages)].map((_, pageNumber) => (
              <Pagination.Item
                key={pageNumber + 1}
                active={pageNumber + 1 === currentPage}
                onClick={() => handlePageChange(pageNumber + 1)}
              >
                {pageNumber + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        )}
      </div>

      {editVisit && (
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>UPDATE CHECK-UP VISIT RECORD</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="formVisitDate">
                    <Form.Label>Visit Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={editVisit.visitDate}
                      name="visitDate"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formWeight">
                    <Form.Label>Weight</Form.Label>
                    <Form.Control
                      type="number"
                      name="weight"
                      value={editVisit.weight}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formHeight">
                    <Form.Label>Height</Form.Label>
                    <Form.Control
                      type="number"
                      name="height"
                      value={editVisit.height}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formTemperature">
                    <Form.Label>Temperature</Form.Label>
                    <Form.Control
                      type="text"
                      name="temperature"
                      value={editVisit.temperature}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formFeeding">
                    <Form.Label>Feeding</Form.Label>
                    <Form.Control
                      type="text"
                      name="feeding"
                      value={editVisit.feeding}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formDiagnosis">
                      <Form.Label>Diagnosis</Form.Label>
                      <Form.Control
                        as="select"
                        name="diagnosis"
                        value={editVisit.diagnosis}
                        onChange={handleInputChange}
                      >
                        <option value="">Select a diagnosis</option>
                        {diagnoses.map((diagnosis, index) => (
                          <option key={index} value={diagnosis}>
                            {diagnosis}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    {/* Show the input field when "Other" is selected */}
                    {isOtherSelected && (
                      <Form.Group controlId="formCustomDiagnosis">
                        <Form.Label>Diagnosis</Form.Label>
                        <Form.Control
                          type="text"
                          name="diagnosis"
                          placeholder="Enter a diagnosis"
                          value={editVisit.diagnosis} // Bind the value to the same state
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
              </Row>
              <Row>
              <Col>
                  <Form.Group controlId="formPrescription">
                    <Form.Label>Prescription</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="prescription"
                      value={editVisit.prescription}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formRemarks">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  name="remarks"
                  value={editVisit.remarks}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formCheckedBy">
                <Form.Label>Checked By</Form.Label>
                <Form.Control
                  type="text"
                  name="checkedBy"
                  value={editVisit.checkedBy}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>Close</Button>
            <Button variant="primary" onClick={handleSaveEdit}>Save Changes</Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Commented out: modal approach
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this visit record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
          <Button variant="danger" onClick={handleConfirmDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
      */}
    </>
  );
};

export default ChildVisitRecordTable;
