import React, { useState } from 'react';
import './ResetPassword.css'; // Import a CSS file for styling
import logo from './images/logo.png';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;
    return passwordRegex.test(password);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const token = new URLSearchParams(window.location.search).get('token');
  
    // Clear error before each new submission
    setError('');
  
    if (!token) {
      setError('Invalid or missing token.');
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    if (!isValidPassword(newPassword)) {
      setError('Password must be at least 10 characters long and contain at least one special character.');
      return;
    }
  
    try {
      const response = await fetch('https://onecare-web-36d0b9bac382.herokuapp.com/api/users/reset-password-from-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setSuccess('Password has been reset successfully.');
        setError(''); 
        setNewPassword(''); 
        setConfirmPassword('');
      } else {
        // Log the entire result object for debugging
        console.error('Error response:', result);
        setError(result.error || 'An unknown error occurred.'); // use the error from the server or a fallback message
        setSuccess(''); 
      }
    } catch (err) {
      // Display the error message in the UI
      setError('Failed to reset password: ' + (err.message || 'An unknown error occurred.')); // show the caught error message
      setSuccess(''); 
    }
  };
  
  return (
    <div className="reset-password-container">
      <form onSubmit={handleResetPassword} className="reset-password-form">
        <div className="reset-header">
          <img src={logo} alt="Logo" className="reset-logo" />
          <div className="reset-heading"><h2>OneCare</h2></div>
        </div>
        <div className="reset-form-group">
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            placeholder="Enter new password"
            className="form-input"
          />
        </div>
        <div className="reset-form-group">
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder="Confirm new password"
            className="form-input"
          />
        </div>
        <button type="submit" className="submit-reset-button">Reset Password</button>

        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </form>
    </div>
  );
};

export default ResetPassword;
