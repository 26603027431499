import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import '../App.css';

const sampleVisits = [
  {
    date: '2024-05-01',
    location: 'Dalig BHCS3 - Sitio #1',
    attendedBy: 'Dr. Dela Cruz',
    reason: 'Routine check-up',
    advice: 'Continue current medication',
  },
  {
    date: '2024-04-15',
    location: 'Dalig BHCS3 - Sitio #1',
    attendedBy: 'Nurse Malvar',
    reason: 'Routine check-up',
    advice: 'Start physical therapy',
  },
];

function PatientVisitList() {
  const [showAddVisitModal, setShowAddVisitModal] = useState(false);
  const [visitData, setVisitData] = useState({
    date: '',
    location: '',
    attendedBy: '',
    reason: '',
    advice: '',
  });

  const handleMoreClick = () => {
    alert('There are no more patient visits');
  };

  const handleAddVisitClick = () => {
    setShowAddVisitModal(true);
  };

  const handleCloseModal = () => {
    setShowAddVisitModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVisitData({ ...visitData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    alert('Form submitted with data: ' + JSON.stringify(visitData));
    setShowAddVisitModal(false);
    // Reset form data
    setVisitData({
      date: '',
      location: '',
      attendedBy: '',
      reason: '',
      advice: '',
    });
  };

  return (
    <div className="patient-visit-list">
      <ul className="visit-list">
        <div className="visit-list-header">
          <h2 className="mb-0">Patient Visits</h2>
          <Button className="add-visit-btn" onClick={handleAddVisitClick}>Add Visit</Button>
        </div>
        {sampleVisits.map((visit, index) => (
          <li key={index} className="visit-item">
           <div className="visit-date"><strong>Date:</strong> {visit.date}</div>
            <div className="visit-location"><strong>Location:</strong> {visit.location}</div>
            <div className="visit-attended-by"><strong>Attended By:</strong> {visit.attendedBy}</div>
            <div className="visit-reason"><strong>Reason:</strong> {visit.reason}</div>
            <div className="visit-advice"><strong>Advice:</strong> {visit.advice}</div>
          </li>
        ))}
      </ul>
      <div className="visit-list-footer">
        <button className="more-link" onClick={handleMoreClick}>More</button>
      </div>

      {/* Add Visit Modal */}
      <Modal show={showAddVisitModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>ADD A VISIT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" name="date" value={visitData.date} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="formLocation">
            <Form.Label>Location</Form.Label>
            <Form.Control type="text" name="location" value={visitData.location} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="formAttendedBy">
            <Form.Label>Attended By</Form.Label>
            <Form.Control type="text" name="attendedBy" value={visitData.attendedBy} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="formReason">
            <Form.Label>Reason</Form.Label>
            <Form.Control type="text" name="reason" value={visitData.reason} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="formAdvice">
            <Form.Label>Advice</Form.Label>
            <Form.Control type="text" name="advice" value={visitData.advice} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group controlId="formDiagnosis">
            <Form.Label>Diagnosis</Form.Label>
            <Form.Control type="text" name="diagnosis" value={visitData.diagnosis} onChange={handleInputChange} />
          </Form.Group>
          <Button variant="primary" className= "submit-btn" type="submit">Submit</Button>
        </Form>
      </Modal.Body>

      </Modal>
    </div>
  );
}

export default PatientVisitList;
