import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './Login';
import LandingPage from './LandingPage';
import Header from './Header';
import AuthenticatedApp from './AuthenticatedApp';
import { jwtDecode } from 'jwt-decode';
import ResetPassword from './ResetPassword';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const tokenCookie = document.cookie.split('; ').find(row => row.startsWith('token='));
    if (tokenCookie) {
      const jwt = tokenCookie.split('=')[1];
      try {
        const decodedToken = jwtDecode(jwt); 
        setLoggedIn(true);
        setUserRole(decodedToken.role); 
      } catch (error) {
        console.error('Invalid token', error);
        handleLogout();
      }
    }
  }, []);

  const handleLogin = (role, token) => {
    setLoggedIn(true);
    setUserRole(role);
    document.cookie = `token=${token}; Path=/;`; // Set the token in a cookie
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUserRole(null);
    document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; // Clear the token cookie
  };

  return (
    <Router>
      <div className="app">
        {loggedIn && <Header onLogout={handleLogout} />}
        <div className="main-container">
          {!loggedIn ? (
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/reset-password" element={<ResetPassword/>} />
            </Routes>
          ) : (
            <AuthenticatedApp userRole={userRole} />
          )}
        </div>
      </div>
    </Router>
  );
}

export default App;
