import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getPrenatalRecordById, updatePrenatalRecord } from '../Controller/PrenatalRecord_controller';
import PrenatalRecordDetailsForm from './prenatalRecordDetailsForm';
import LaboratoryTestResults from './prenatalLabResult'; // Adjust import path as needed
import CheckUpTable from './prenatalCheckupTable'; // Adjust import path as needed

const PrenatalRecordDetails = () => {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [originalRecord, setOriginalRecord] = useState(null);
  const [record, setRecord] = useState({
    prenatalRecordId: '', // Ensure prenatalRecordId is included in state
  });

  useEffect(() => {
    fetchPrenatalRecord();
  }, []);

  const fetchPrenatalRecord = async () => {
    try {
      const fetchedRecord = await getPrenatalRecordById(id);
      setRecord(fetchedRecord);
      setOriginalRecord(fetchedRecord);
    } catch (error) {
      console.error('Error fetching prenatal record:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecord(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setRecord(prevState => ({
      ...prevState,
      [name]: value ? new Date(value) : null,
    }));
  };

  const saveRecord = async () => {
    try {
      await updatePrenatalRecord(id, record);
      setEditMode(false);
    } catch (error) {
      console.error('Error updating prenatal record:', error);
    }
  };

  const cancelEdit = () => {
    setRecord(originalRecord);
    setEditMode(false);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <Container>
      <PrenatalRecordDetailsForm
        record={record}
        editMode={editMode}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        saveRecord={saveRecord}
        cancelEdit={cancelEdit}
        toggleEditMode={toggleEditMode}
      />
      <div className="mt-5">
        <div className="page-header">LABORATORY RESULTS</div>
        {record.prenatalRecordId && <LaboratoryTestResults prenatalRecordId={record.prenatalRecordId} />}
      </div>
      <div className="mt-5">
        {record.prenatalRecordId && <CheckUpTable prenatalRecordId={record.prenatalRecordId} />}
      </div>
    </Container>
  );
};

export default PrenatalRecordDetails;
