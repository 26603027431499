import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import { fetchUsersRegisteredToday } from '../../Controller/admin_dashboard_controller'; // Adjust path as needed

function UsersRegisteredToday() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const data = await fetchUsersRegisteredToday(page);
        setUsers(data.users);
        setTotalPages(data.totalPages);
        setTotalUsers(data.totalUsers);
      } catch (error) {
        console.error('Failed to load users:', error);
      }
    };

    loadUsers();
  }, [page]);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setPage(newPage);
  };

  return (
    <div className="main-content">
      <div className="page-header">Users Registered Today</div>
      
      <div className="container-usertable">
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Sex</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {users.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>No users registered today.</td>
                </tr>
              ) : (
                users.map((user, index) => (
                  <tr key={index}>
                    <td>{user.fName}</td>
                    <td>{user.mName}</td>
                    <td>{user.lName}</td>
                    <td>{user.sex}</td>
                    <td>{user.role}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {totalUsers > 0 && (
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={page === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            />
            {[...Array(totalPages).keys()].map(pageNumber => (
              <Pagination.Item
                key={pageNumber + 1}
                active={pageNumber + 1 === page}
                onClick={() => handlePageChange(pageNumber + 1)}
              >
                {pageNumber + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={page === totalPages}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
}

export default UsersRegisteredToday;
