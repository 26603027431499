import React, { useEffect, useState } from 'react';
import { Pagination, Alert, Button } from 'react-bootstrap';
import { getArchivedChildHealthRecords, restoreChildHealthRecord, searchArchivedChildHealthRecords } from '../Controller/ChildHealthRecord_controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFileLines,faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import PaginationComponent from './Pagination'; 

const ArchivedTable = () => {
  const [archivedUsers, setArchivedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchArchivedRecords = async () => {
      try {
        const data = await getArchivedChildHealthRecords(currentPage);
        if (data && Array.isArray(data.records)) {
          setArchivedUsers(data.records);
          setTotalPages(data.totalPages);
        } else {
          console.error('Unexpected data format:', data);
          setArchivedUsers([]); // Default to an empty array if format is incorrect
        }
      } catch (error) {
        console.error('Error fetching archived records:', error);
        setShowError(true);
      }
    };
    fetchArchivedRecords();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRestore = async (userId) => {
    try {
      await restoreChildHealthRecord(userId);
      setArchivedUsers((prevUsers) => prevUsers.filter(user => user._id !== userId));
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000); // Hide success message after 3 seconds
    } catch (error) {
      console.error('Error restoring record:', error);
      setShowError(true);
      setTimeout(() => setShowError(false), 3000); // Hide error message after 3 seconds
    }
  };
  

  const confirmRestore = (userId) => {
    const userConfirmed = window.confirm("Are you sure you want to restore this record?");
    if (userConfirmed) {
      handleRestore(userId);  // Pass the userId directly here
    }
  };
  
  const handleSearch = async () => {
    try {
      const filteredRecords = await searchArchivedChildHealthRecords(searchQuery);
      setArchivedUsers(filteredRecords);
    } catch (error) {
      console.error('Error searching archived records:', error);
      setArchivedUsers([]); // Default to an empty array if there’s an error
    }
  };

  return (
    <div className="main-content">
      <div className="page-header">
        <FontAwesomeIcon icon={faFileLines} className="user-header-icon" />
        ARCHIVED CHILD HEALTH RECORD
      </div> 
      <div className="row">
          <div className="col-md-6">
            {/* Other content  */}
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="search-bar">
              <div className="search-bar-inner">
                <input
                  type="text"
                  placeholder="Search by First Name or Last Name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
                <button
                  className="search-button"
                  onClick={() => handleSearch(searchQuery)}
                >
                  <span><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                </button>
              </div>
            </div>
          </div>
        </div>


            {/* Success and error messages */}
      <div className='msg'>{showSuccess && <Alert variant="success">Record restored successfully!</Alert>}
      {showError && <Alert variant="danger">Error occurred while restoring the record.</Alert>}</div>

      <div className="container-usertable">
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>M.I</th>
                <th>Last Name</th>
                <th>Sex</th>
                <th>Date of Birth</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {archivedUsers.length > 0 ? (
                archivedUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user.fName}</td>
                    <td>{user.mName}</td>
                    <td>{user.lName}</td>
                    <td>{user.sex}</td>
                    <td>{user.dateOfBirth}</td>
                    <td className='fixed-action-th'>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => confirmRestore(user._id)}
                      >
                          <FontAwesomeIcon icon={faArrowRotateLeft} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">No archived records found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
      </div>
    </div>
  );
};

export default ArchivedTable;
