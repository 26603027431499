import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, resetPassword } from './Controller/login_controller'; // Add resetPassword function
import LoginForm from './LoginForm';
import './login.css';
import logo from './images/logo.png';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Include the error state
  const [email, setEmail] = useState(''); // For forgot password
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await login(username, password);
      console.log('Response data:', data);

      if (data.token && data.role) {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('role', data.role);

        onLogin(data.role);
        navigate('/');
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError(error.message || 'An error occurred while logging in');
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await resetPassword(email); // Call resetPassword API with email
      console.log('Password reset email sent:', response);
      setError('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset:', error);
      setError(error.message || 'Failed to send password reset email');
    }
  };

  return (
    <div className="plx-image3">
      <div className="login-container">
        <div className="login-header">
          <img src={logo} alt="Logo" className="login-logo" />
          <h2 className="login-heading">OneCare</h2>
        </div>
        <LoginForm
          onSubmit={handleLogin}
          onForgotPassword={handleForgotPassword} 
          error={error}
          setError={setError} 
          setUsername={setUsername}
          setPassword={setPassword}
          username={username}
          password={password}
          setEmail={setEmail} 
        />
      </div>
    </div>
  );
}

export default Login;
