import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import { fetchBhcs1Appointments } from '../../Controller/admin_dashboard_controller';

function Bhcs1AppointmentsPage() {
  const [appointments, setAppointments] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  // Helper function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Get YYYY-MM-DD format
  };

  // Set initial date to today's date once component is mounted
  useEffect(() => {
    const today = getTodayDate();
    setFromDate(today);
    setToDate(today);
  }, []);

  const loadBhcs1Appointments = async () => {
    if (fromDate && toDate) {
      try {
        const data = await fetchBhcs1Appointments(fromDate, toDate, page);
        setAppointments(data.appointments);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Failed to load BHCS1 appointments:', error);
      }
    }
  };

  // Re-fetch appointments when date or page changes
  useEffect(() => {
    loadBhcs1Appointments();
  }, [fromDate, toDate, page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <div className="main-content">
      <div className="page-header"> Dalig BHCS1 - Milagros Appointments </div>
      <div className="row mb-3">
      <div className="d-flex align-items-center mb-3">
        <div className="d-flex align-items-center me-3"> {/* Add margin-end to the first div */}
          <p className="mb-0 me-2 text-nowrap">From:</p>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="form-control date-input"
            style={{ width: 'auto' }} // Adjust width as needed
          />
        </div>
        <div className="d-flex align-items-center"> 
          <p className="mb-0 me-2 text-nowrap">To:</p>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="form-control date-input"
            style={{ width: 'auto' }} // Adjust width as needed
          />
        </div>
      </div>
      </div>

      <div className="container-usertable">
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Purpose</th>
                <th>Patient Name</th>
                <th>Appointment Time</th>
                <th>Health Center</th>
              </tr>
            </thead>
            <tbody>
              {appointments.length === 0 ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>
                    No appointments found for the selected date range.
                  </td>
                </tr>
              ) : (
                appointments.map((appointment, index) => (
                  <tr key={index}>
                    <td>{new Date(appointment.date).toLocaleDateString()}</td>
                    <td>{appointment.purpose}</td>
                    <td>{appointment.patientName}</td>
                    <td>{appointment.timeSlot}</td>
                    <td>{appointment.healthCenter}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
        <Pagination className="custom-pagination">
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={page === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            />
            {[...Array(totalPages).keys()].map((pageNumber) => (
              <Pagination.Item
                key={pageNumber + 1}
                active={pageNumber + 1 === page}
                onClick={() => handlePageChange(pageNumber + 1)}
              >
                {pageNumber + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={page === totalPages}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
}

export default Bhcs1AppointmentsPage;
