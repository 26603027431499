import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faClinicMedical, faUserFriends, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AppointmentsPage from './BookedPatient';
import Bhcs1AppointmentsPage from './AdminDashboard/BHCS1Appointments';
import Bhcs2AppointmentsPage from './AdminDashboard/BHCS2Appointments';
import Bhcs3AppointmentsPage from './AdminDashboard/BHCS3Appointments';
import UsersRegisteredToday from './AdminDashboard/UsersRegisteredToday';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchAllAppointmentToday, fetchBhcs1AppointmentsToday, fetchBhcs2AppointmentsToday, fetchBhcs3AppointmentsToday, fetchUsersRegisteredToday } from '../Controller/admin_dashboard_controller';
import Cookies from 'js-cookie'; // npm install js-cookie

function AdminDashboard() {
  const [showAppointments, setShowAppointments] = useState(false);
  const [showBhcs1Appointments, setShowBhcs1Appointments] = useState(false);
  const [showBhcs2Appointments, setShowBhcs2Appointments] = useState(false);
  const [showBhcs3Appointments, setShowBhcs3Appointments] = useState(false);
  const [showUsersRegistered, setShowUsersRegistered] = useState(false);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [bhcs1AppointmentsToday, setBhcs1AppointmentsToday] = useState(0);
  const [bhcs2AppointmentsToday, setBhcs2AppointmentsToday] = useState(0);
  const [bhcs3AppointmentsToday, setBhcs3AppointmentsToday] = useState(0);
  const [usersRegisteredCount, setUsersRegisteredCount] = useState(0);

  useEffect(() => {
    const authToken = Cookies.get('authToken');

    const loadTotalAppointments = async () => {
      try {
        const data = await fetchAllAppointmentToday();
        setTotalAppointments(data.totalAppointments);
      } catch (error) {
        console.error('Failed to load total appointments:', error);
      }
    };

    const loadBhcs1AppointmentsToday = async () => {
      try {
        const count = await fetchBhcs1AppointmentsToday();
        setBhcs1AppointmentsToday(count);
      } catch (error) {
        console.error('Failed to load BHCS1 appointments for today:', error);
      }
    };

    const loadBhcs2AppointmentsToday = async () => {
      try {
        const count = await fetchBhcs2AppointmentsToday();
        setBhcs2AppointmentsToday(count);
      } catch (error) {
        console.error('Failed to load BHCS2 appointments for today:', error);
      }
    };

    const loadBhcs3AppointmentsToday = async () => {
      try {
        const count = await fetchBhcs3AppointmentsToday();
        setBhcs3AppointmentsToday(count);
      } catch (error) {
        console.error('Failed to load BHCS3 appointments for today:', error);
      }
    };

    const loadUsersRegisteredToday = async () => {
      try {
        const data = await fetchUsersRegisteredToday();
        setUsersRegisteredCount(data.totalUsers); // Assuming totalUsers is part of the response
      } catch (error) {
        console.error('Failed to load users registered today:', error);
      }
    };

    loadTotalAppointments();
    loadBhcs1AppointmentsToday();
    loadBhcs2AppointmentsToday();
    loadBhcs3AppointmentsToday();
    loadUsersRegisteredToday();
  }, []);

  const healthCenters = [
    { name: 'Dalig BHCS1 - Milagros', appointments: bhcs1AppointmentsToday },
    { name: 'Dalig BHCS2 - Perdracio', appointments: bhcs2AppointmentsToday },
    { name: 'Dalig BHCS3 - Sitio #1', appointments: bhcs3AppointmentsToday },
  ];

  const handleAppointmentsClick = () => {
    setShowAppointments(true);
    setShowBhcs1Appointments(false);
    setShowBhcs2Appointments(false);
    setShowBhcs3Appointments(false);
    setShowUsersRegistered(false);
  };

  const handleBhcs1Click = () => {
    setShowAppointments(false);
    setShowBhcs1Appointments((prev) => !prev);
    setShowBhcs2Appointments(false);
    setShowBhcs3Appointments(false);
    setShowUsersRegistered(false);
  };

  const handleBhcs2Click = () => {
    setShowAppointments(false);
    setShowBhcs1Appointments(false);
    setShowBhcs2Appointments((prev) => !prev);
    setShowBhcs3Appointments(false);
    setShowUsersRegistered(false);
  };

  const handleBhcs3Click = () => {
    setShowAppointments(false);
    setShowBhcs1Appointments(false);
    setShowBhcs2Appointments(false);
    setShowBhcs3Appointments((prev) => !prev);
    setShowUsersRegistered(false);
  };

  const handleUsersRegisteredClick = () => {
    setShowAppointments(false);
    setShowBhcs1Appointments(false);
    setShowBhcs2Appointments(false);
    setShowBhcs3Appointments(false);
    setShowUsersRegistered((prev) => !prev);
  };

  return (
    <Container className="admin-dashboard">
      <Row className="mb-4">
        <Col>
          <div className='page-header'><FontAwesomeIcon icon={faDesktop} /> ADMIN DASHBOARD</div>
        </Col>
      </Row>
      <TransitionGroup as={Row} className="equal-height-cards">
        <CSSTransition key="totalAppointments" timeout={500} classNames="fade">
          <Col md={3} className="d-flex">
            <Card className="info-card appointments-card flex-fill" onClick={handleAppointmentsClick} style={{ cursor: 'pointer' }}>
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="text-left">
                  <Card.Title>Appointments Today</Card.Title>
                  <Card.Text>{totalAppointments} Appointments</Card.Text>
                </div>
                <div className="calendar-icon-container">
                  <FontAwesomeIcon icon={faCalendarCheck} size="2x" className="info-icon" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </CSSTransition>
        {healthCenters.map((center, index) => (
          <CSSTransition key={index} timeout={500} classNames="fade">
            <Col md={3} className="d-flex">
              <Card className="info-card health-center-card flex-fill" onClick={index === 0 ? handleBhcs1Click : index === 1 ? handleBhcs2Click : handleBhcs3Click} style={{ cursor: 'pointer' }}>
                <Card.Body className="d-flex justify-content-between align-items-center">
                  <div className="text-left">
                    <Card.Title>{center.name}</Card.Title>
                    <Card.Text>{center.appointments} Appointments</Card.Text>
                  </div>
                  <div className={`icon-container color-${(index % 3) + 1}`}>
                    <FontAwesomeIcon icon={faClinicMedical} size="2x" className="info-icon" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </CSSTransition>
        ))}
        <CSSTransition key="usersRegistered" timeout={500} classNames="fade">
          <Col md={3} className="d-flex">
            <Card className="info-card users-card flex-fill" onClick={handleUsersRegisteredClick} style={{ cursor: 'pointer' }}>
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="text-left">
                  <Card.Title>Users Registered Today</Card.Title>
                  <Card.Text>{usersRegisteredCount} Users</Card.Text>
                </div>
                <div className="user-icon-container">
                  <FontAwesomeIcon icon={faUserFriends} size="2x" className="info-icon" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </CSSTransition>
      </TransitionGroup>
      {showAppointments && (
        <Row className="mt-4">
          <Col>
            <AppointmentsPage />
          </Col>
        </Row>
      )}
      {showBhcs1Appointments && (
        <Row className="mt-4">
          <Col>
            <Bhcs1AppointmentsPage />
          </Col>
        </Row>
      )}
      {showBhcs2Appointments && (
        <Row className="mt-4">
          <Col>
            <Bhcs2AppointmentsPage />
          </Col>
        </Row>
      )}
      {showBhcs3Appointments && (
        <Row className="mt-4">
          <Col>
            <Bhcs3AppointmentsPage />
          </Col>
        </Row>
      )}
      {showUsersRegistered && (
        <Row className="mt-4">
          <Col>
            <UsersRegisteredToday />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default AdminDashboard;
