import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFileLines, faEye, faPlus, faBoxArchive  } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Alert, Form, Container, Row, Col, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { addChildHealthRecord, getChildHealthRecords,deleteChildHealthRecord,searchChildHealthRecords } from '../Controller/ChildHealthRecord_controller';
import { ChildHealthRecordModel } from '../Model/ChildHealthRecordModel';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../recordStyles.css';
import StreetDropdown from './streetDropdownBtn';
import PaginationComponent from './Pagination'; 


function HealthWorkerChildHealthRecord() {
  const [records, setRecords] = useState([]);
  const initialRecordState = {
    fName: '',
    mName: '',
    lName: '',
    sex: '',
    dateOfBirth: '',
    motherFirstName: '',
    motherLastName: '',
    motherOccupation: '',
    fatherFirstName: '',
    fatherLastName: '',
    fatherOccupation: '',
    houseBuildingNumber: '',
    streetName: '',
    sitio: '',
    contactNo: '',
    contactPerson: '',
    typeOfDelivery: '',
    birthWT: '',
    birthHT: '',
    muac: '',
    cpab: '',
    newbornScreeningDate: '',
    seStatus: '',
    birthAttendant: '',
    headCircumference: '',
    chestCircumference: '',
    abdomenCircumference: ''
  };
  
  // State declaration
  const [newRecord, setNewRecord] = useState(initialRecordState);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchRecords(currentPage);
  }, [currentPage]);


  const handleSearch = async () => {
    const filteredRecords = await searchChildHealthRecords(searchQuery);
    setRecords(filteredRecords);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const fetchRecords = async (page = 1, limit = 20) => {
    try {
      const fetchedRecords = await getChildHealthRecords(page, limit);
      setRecords(fetchedRecords.records);
      setTotalPages(fetchedRecords.totalPages);
    } catch (error) {
      console.error('Error fetching child health records:', error);
    }
  };
  
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!newRecord.fName.trim()) {
      newErrors.fName = 'First Name is required'; }
    if (!newRecord.lName.trim()) {
        newErrors.lName = 'Last Name is required'; }
    if (!newRecord.sex.trim()) {
      newErrors.sex = 'Sex field is required'; }
    if (!newRecord.dateOfBirth) {
      newErrors.dateOfBirth = 'Date of birth is required'; }
 if (!newRecord.streetName) {
      newErrors.streetName = 'Street name field is required'; } 
    if (!newRecord.sitio) {
      newErrors.sitio = 'Sitio field is required'; }   
    if (!newRecord.contactNo.trim()) {
      newErrors.contactNo = 'Contact Number is required'; }   
      if (!newRecord.contactPerson.trim()) {
        newErrors.contactPerson = 'Contact Person is required'; }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleSaveRecord = async () => {
    try {
      if (validateForm()) {
        const recordInstance = new ChildHealthRecordModel(newRecord);
        const addedRecord = await addChildHealthRecord(recordInstance);
        setRecords([...records, addedRecord]);
        setShowForm(false);
        setSuccessMessage('Child health record has been successfully added.');
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
        setNewRecord(initialRecordState);  // Reset the form fields to initial state
        fetchRecords();
      }
    } catch (error) {
      console.error('Error adding child health record:', error);
      if (error.response && error.response.data.message === 'Record already exists') {
        setErrorMessage('Record already exists.');
        setShowForm(false);

      } else {
        setErrorMessage('An error occurred while adding the record.');
      }
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 3000);
    }
  };
  

  const handleViewRecordDetails = (id) => {
    console.log(`Navigating to record details with ID: ${id}`); // Debug log
    if (id) {
      navigate(`/childHealthRecords/${id}`);
    } else {
      console.error('Record ID is undefined');
    }
  };

  const redirectToArchivedPage = () => {
    navigate('/archived-child-health-record');
  };

  const handleDeleteRecord = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to archive this record?');
    if (!confirmDelete) return;

    try {
      await deleteChildHealthRecord(id);
      setRecords(records.filter(record => record._id !== id));
      setSuccessMessage('Child health record has been successfully archived.');
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      console.error('Error archiving child health record:', error);
    }
  };

  const sitiosOptions = [
    'Aba Homes', 'Avida', 'Birmingham', 'Cuesta Verde', 'Dalaya', 'Dalig I', 'Dalig II', 
'Dalig III', 'Gloria Heights', 'Junmar Subd', 'Kamatisan', 'Kaytinaga', 'La Terraza de Antipolo', 
'Lores Executive', 'Lower Zigzag', 'Lugo', 'Maia Alta', 'Milagros', 'Nayong Silangan', 
'Parugan Blk I', 'Parugan Blk II', 'Payong', 'Robbis/Courtyard', 'San Antonio', 'Sta. Maria', 
'Sto. Niño', 'Synergy', 'Terrazas Verde', 'Tubigan', 'Upper Zigzag', 'Woodrow'
  ];

  return (
    <div className="main-content">
     <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="page-header">
        <FontAwesomeIcon icon={faFileLines} className="user-header-icon" />
        CHILD HEALTH RECORD MANAGEMENT
      </div>
      
        {/*  <button className="btn btn-primary" onClick={redirectToArchivedPage}>
          <FontAwesomeIcon icon={faBoxArchive} /> 
        </button> */}
    </div>
        <Modal
          show={showForm}
          onHide={() => setShowForm(false)}
          centered
          dialogClassName="modal-custom modal-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>CREATE A CHILD HEALTH RECORD</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form>
                <Row>
                  <Col>
                    <Form.Group controlId="fName">
                      <Form.Label>First Name <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="fName"
                        value={newRecord.fName}
                        onChange={handleInputChange}
                        isInvalid={!!errors.fName}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="mName">
                      <Form.Label>M.I</Form.Label>
                      <Form.Control
                        type="text"
                        name="mName"
                        value={newRecord.mName}
                        onChange={handleInputChange} 
                        maxLength={1}                      
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="lName">
                      <Form.Label>Last Name <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="lName"
                        value={newRecord.lName}
                        onChange={handleInputChange}
                        isInvalid={!!errors.lName}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="sex">
                      <Form.Label>Sex <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        as="select"
                        name="sex"
                        value={newRecord.sex}
                        onChange={handleInputChange}
                        isInvalid={!!errors.sex}
                      >
                        <option value="">Select Sex</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="dateOfBirth">
                      <Form.Label>Date of Birth <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="date"
                        name="dateOfBirth"
                        isInvalid={!!errors.dateOfBirth}
                        value={newRecord.dateOfBirth}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="motherFirstName">
                      <Form.Label>Mother's First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="motherFirstName"
                        value={newRecord.motherFirstName}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="motherLastName">
                      <Form.Label>Mother's Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="motherLastName"
                        value={newRecord.motherLastName}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="motherOccupation">
                      <Form.Label>Mother's Occupation</Form.Label>
                      <Form.Control
                        type="text"
                        name="motherOccupation"
                        value={newRecord.motherOccupation}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                <Row>
                  <Col>
                    <Form.Group controlId="fatherFirstName">
                      <Form.Label>Father's First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fatherFirstName"
                        value={newRecord.fatherFirstName}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="fatherLastName">
                      <Form.Label>Father's Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fatherLastName"
                        value={newRecord.fatherLastName}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="fatherOccupation">
                      <Form.Label>Father's Occupation</Form.Label>
                      <Form.Control
                        type="text"
                        name="fatherOccupation"
                        value={newRecord.fatherOccupation}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                <Row>
                  <Col>
                    <Form.Group controlId="houseBuildingNumber">
                      <Form.Label>House/Building Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="houseBuildingNumber"
                        value={newRecord.houseBuildingNumber}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group controlId="streetName">
                <Form.Label>Street Name <span style={{ color: 'red' }}>*</span></Form.Label>
                <StreetDropdown
                  name="streetName"
                  value={newRecord.streetName}
                  onChange={handleInputChange}
                  isInvalid={!!errors.streetName}
                />
              </Form.Group>
                    </Col>
                </Row>
                <Col>
               <Form.Group controlId="sitio">
                <Form.Label>Sitio/Subdivision<span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="sitio"
                  value={newRecord.sitio}
                  isInvalid={!!errors.sitio}
                  onChange={handleInputChange}
               
                >
                  <option value="">Select Sitio</option>
                  {sitiosOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
                  </Col>
                  <Row>
                  <Col>
                    <Form.Group controlId="contactPerson">
                      <Form.Label>Contact Person <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="contactPerson"
                        value={newRecord.contactPerson}
                        isInvalid={!!errors.contactPerson}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col> </Row>
                <Row>
                <Col>
                    <Form.Group controlId="contactNo">
                      <Form.Label>Contact Number <span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="number"
                        name="contactNo"
                        value={newRecord.contactNo}
                        isInvalid={!!errors.contactNo}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                 <div className='form-divider'> <h2>FOR NEW BORN</h2> </div>
                <Col>
                    <Form.Group controlId="typeOfDelivery">
                      <Form.Label>Type of Delivery</Form.Label>
                      <Form.Control
                        as="select"
                        name="typeOfDelivery"
                        value={newRecord.typeOfDelivery}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Type</option>
                        <option value="NSD">NSD</option>
                        <option value="CS">CS</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                <Col>
                    <Form.Group controlId="birthWT">
                      <Form.Label>Birth Weight (kg)</Form.Label>
                      <Form.Control
                        type="number"
                        name="birthWT"
                        value={newRecord.birthWT}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="birthHT">
                      <Form.Label>Birth Height (cm)</Form.Label>
                      <Form.Control
                        type="number"
                        name="birthHT"
                        value={newRecord.birthHT}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="muac">
                      <Form.Label>MUAC</Form.Label>
                      <Form.Control
                        type="text"
                        name="muac"
                        value={newRecord.muac}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group controlId="cpab">
                    <Form.Label>CPAB</Form.Label>
                    <Form.Control
                      as="select"
                      name="cpab"
                      value={newRecord.cpab}
                      onChange={handleInputChange}
                    >
                      <option value="">Select an option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Control>
                  </Form.Group>
                </Col></Row>
                  <Row>
                  <Col>
                    <Form.Group controlId="newbornScreeningDate">
                      <Form.Label>Newborn Screening Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="newbornScreeningDate"
                        value={newRecord.newbornScreeningDate}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  </Row>
                <Row>
                <Col>
                    <Form.Group controlId="seStatus">
                      <Form.Label>SE Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="seStatus"
                        value={newRecord.seStatus}
                        onChange={handleInputChange}
                      >
                        <option value="">Select SE Status</option>
                        <option value="Hospital">Hospital</option>
                        <option value="Lying-in">Lying-in</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                <Col>
                    <Form.Group controlId="birthAttendant">
                      <Form.Label>Birth Attendant</Form.Label>
                      <Form.Control
                        as="select"
                        name="birthAttendant"
                        value={newRecord.birthAttendant}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Attendant</option>
                        <option value="MD">MD</option>
                        <option value="N">N</option>
                        <option value="MW">MW</option>
                        <option value="Others">Others</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  </Row>
                  <Row>
                  <Col>
                    <Form.Group controlId="headCircumference">
                      <Form.Label>Head Circumference</Form.Label>
                      <Form.Control
                       min="0" step="0.1"
                        type="number"
                        name="headCircumference"
                        value={newRecord.headCircumference}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="chestCircumference">
                      <Form.Label>Chest Circumference</Form.Label>
                      <Form.Control
                      min="0" step="0.1" 
                        type="number"
                        name="chestCircumference"
                        value={newRecord.chestCircumference}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
              
                  <Col>
                    <Form.Group controlId="abdomenCircumference">
                      <Form.Label>Abdomen Circumference</Form.Label>
                      <Form.Control
                       min="0" step="0.1"
                        type="number"
                        name="abdomenCircumference"
                        value={newRecord.abdomenCircumference}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowForm(false)}>Cancel</Button>
            <Button variant="primary" onClick={handleSaveRecord}>Save</Button>
          </Modal.Footer>
        </Modal>
        <div className="container-usertable">
          <div className= "row"> 
          <div className="col-md-6">
            <div className="add-user-button">
              <button className="btn btn-primary float-md-start" onClick={() => setShowForm(true)}>
              <FontAwesomeIcon icon={faPlus} />  ADD A RECORD  </button>
          </div> 
        
        </div> 
        <div className="col-md-6">
                  <div className="search-bar">
                    <div className="search-bar-inner">
                      <input
                        type="text"
                        placeholder="Search by First Name or Last Name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                      />
                      <button
                        className="search-button"
                        onClick={() => handleSearch(searchQuery)}
                      >
                     <span> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                      </button>
                    </div>
                </div>  
          </div> 
        </div> 
        </div> 
       <div className='msg'> {showSuccessMessage && (
          <Alert variant="success">
            {successMessage}
          </Alert> 
                )} </div>
        <div className='msg'>  {showErrorMessage && (
                <Alert variant="danger">
                  {errorMessage}
                </Alert>
              )}</div>
        <div className="user-table">
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>M.I</th>
              <th>Last Name</th>
              <th>Sex</th>
              <th>Date of Birth</th>
              <th>Key</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <tr key={record._id}>
                <td>{record.fName}</td>
                <td>{record.mName}</td>
                <td>{record.lName}</td>
                <td>{record.sex}</td>
                <td>{record.dateOfBirth}</td>
                <td>{record.childRecord_id?.substring(0, 10)}</td> 
                <td className='fixed-action-th'>
                  <button
                      className="btn btn-sm btn-primary me-2"
                    onClick={() => handleViewRecordDetails(record._id)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                {/*  <button className="btn btn-sm btn-danger"
                    onClick={() => handleDeleteRecord(record._id)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}

export default HealthWorkerChildHealthRecord;
