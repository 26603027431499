import React, { useState, useEffect } from 'react';
import { Pagination, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AddFamilyPlanningRecordModal from './addFamilyPlanningModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFamilyPlanningRecords, deleteFamilyPlanningRecord, searchFamilyPlanningRecords } from '../Controller/FamilyPlanningRecord_controller'; // Import delete function
import { faMagnifyingGlass, faEye, faTrashAlt, faFileLines,faBoxArchive } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import PaginationComponent from './Pagination'; 

function FamilyPlanningRecord() {
  const [showModal, setShowModal] = useState(false);
  const [records, setRecords] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchRecords(currentPage);
  }, [currentPage]);

  const fetchRecords = async (page = 1, limit = 20) => {
    try {
      const data = await getFamilyPlanningRecords(page, limit);
      setRecords(data.records || []); // Fallback to an empty array if undefined
      setTotalPages(data.totalPages || 0); // Fallback to 0 if undefined
    } catch (error) {
      console.error('Error fetching records:', error);
      setError('Failed to fetch records.');
    }
  };
  
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleAddRecordSuccess = () => {
    setSuccessMessage('Record added successfully!');
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 3000); // Hide message after 3 seconds
    fetchRecords(); // Refresh the records
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setShowErrorMessage(true);
    setTimeout(() => setShowErrorMessage(false), 3000); // Hide message after 3 seconds
  };


  const handleEditClick = (id) => {
    navigate(`/family-planning-record-details/${id}`);
  };

  const handleSearch = async () => {
    const filteredRecords = await searchFamilyPlanningRecords(searchQuery);
    setRecords(filteredRecords);
  };


  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to archive this record?');
    if (!confirmDelete) {
      return;
    }
    try {
      await deleteFamilyPlanningRecord(id);
      setSuccessMessage('Record archived successfully!');
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000); // Hide message after 3 seconds
      fetchRecords(); // Refresh the records
    } catch (error) {
      setError('Failed to archiving record.');
    }
  };

  const redirectToArchivedPage = () => {
    navigate('/archived-family-planning-record');
  };

  return (
    <div className="main-content">
            <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="page-header">
        <FontAwesomeIcon icon={faFileLines} className="user-header-icon" />
        Family Planning Records
      </div>
     
        </div>

      <div className="container-usertable">
        <div className= "row align-items-center"> 
        <div className="col-md-6">
            {/*  <Button className="btn btn-primary float-md-start" onClick={handleShowModal}>
                ADD A RECORD
              </Button> */}
      
          <div className="archive-btn">
          <button className="btn btn-primary" onClick={redirectToArchivedPage}>
          <FontAwesomeIcon icon={faBoxArchive} /> ARCHIVE
        </button></div>          </div>

          <div className="col-md-6">
                  <div className="search-bar">
                    <div className="search-bar-inner">
                      <input
                        type="text"
                        placeholder="Search by First Name or Last Name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                      />
                      <button
                        className="search-button"
                        onClick={() => handleSearch(searchQuery)}
                      >
                     <span> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                      </button>
                    </div>
                </div>  
                </div> 
        </div> 
        {showSuccessMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
        {showErrorMessage && <Alert variant="danger" className="mt-3">{errorMessage}</Alert>}
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>M.I</th>
                <th>Last Name</th>
                <th>Date of Birth</th>
                <th>Key</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record._id}>
                  <td>{record.fName}</td>
                  <td>{record.mName}</td>
                  <td>{record.lName}</td>
                  <td>{new Date(record.dob).toLocaleDateString()}</td>
                  <td>{record.familyPlanningRecord_id?.substring(0, 10)}</td>
                  <td className='fixed-action-th'>
                    {/*<Button 
                      className="btn btn-sm btn-primary me-2"
                      onClick={() => handleEditClick(record._id)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button> */}
                    <Button 
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDelete(record._id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    
        <AddFamilyPlanningRecordModal 
          show={showModal} 
          handleClose={handleCloseModal} 
          onSuccess={handleAddRecordSuccess} 
          onError={handleError} 
        />
      </div>
    </div>
  );
}

export default FamilyPlanningRecord;