import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Pagination, Form, Alert, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getInventoryByHealthCenter, dispenseMedicine, searchItemByHealthCenter } from './Controller/inventory_controller';
import PaginationComponent from './UI/Pagination'; 

const MedicineDispense = () => {
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');

  const [searchQuery, setSearchQuery] = useState("");
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dispenseData, setDispenseData] = useState({
    patientFName: '',
    patientMName: '',
    patientLName: '',
    remarks: '',
    dispenseBy: '',
    dispenseDate: '',
    qtyToGive: '',
  });

  const fetchInventory = async (page) => {
    try {
      const data = await getInventoryByHealthCenter(page);
      setMedicines(data.items);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };
  const handleSearch = async () => {
    try {
      const response = await searchItemByHealthCenter(searchQuery);
      // Destructure to get items from the response object
      const { items } = response; 
      // Assuming items is now an array
      setMedicines(items);
    } catch (error) {
      console.error('Error searching item:', error);
    }
  };
  

  useEffect(() => {
    fetchInventory(currentPage);
  }, [currentPage]);

  const handleSelectMedicine = (medicine) => {
    setSelectedMedicine(medicine);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDispenseData({
      patientFName: '',
      patientMName: '',
      patientLName: '',
      remarks: '',
      dispenseBy: '',
      dispenseDate: '',
      qtyToGive: '',
    });
  };
  

  const handleInputChange = (e) => {
    setDispenseData({ ...dispenseData, [e.target.name]: e.target.value });
  };

  const handleDispenseSubmit = async (e) => {
    e.preventDefault();
    const confirmSelect = window.confirm('Are you sure you want to issue this item?');
    if (!confirmSelect) {
      return;
    }
    // Use the existing state variable without redeclaring
    const dispenseDetails = {
      medicineId: selectedMedicine._id,
      patientFName: dispenseData.patientFName,
      patientMName: dispenseData.patientMName,
      patientLName: dispenseData.patientLName,
      remarks: dispenseData.remarks,
      dispenseBy: dispenseData.dispenseBy,
      dispenseDate: dispenseData.dispenseDate,
      qtyToGive: dispenseData.qtyToGive,
    };
  
    try {
      const response = await dispenseMedicine(dispenseDetails);
      console.log(response.message);
      fetchInventory(currentPage); //refresh table
      setSuccessMessage('Item issued successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); 
      handleCloseModal();
    } catch (error) {
      console.error('Error dispensing medicine:', error);
      setFailedMessage(`Error in issuing item! Details: ${error.message || error}`); 
      setTimeout(() => setFailedMessage(''), 3000); 
      handleCloseModal();
    }
  };
  
  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="main-content">
      <div className="page-header">
      <FontAwesomeIcon icon={faWarehouse} className="inventory-icon"/>MEDICINE AND VITAMINS 
    </div>
    <div className="container-usertable">
          <div className="row">
          <div className="col-md-6">
              
              </div> 
            <div className="col-md-6">
                  <div className="search-bar">
                    <div className="search-bar-inner">
                      <input
                        type="text"
                        placeholder="Search item by name, brand, manufacturer, category or location"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                      />
                      <button
                        className="search-button"
                        onClick={() => handleSearch(searchQuery)}
                      >
                     <span> <FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                      </button>
                    </div>
                </div>  
          </div> 
           </div> 
         <div className="msg">
        {successMessage && (
          <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
            {successMessage}
          </Alert>
        )}
      </div>
      <div className="msg">
        {failedMessage && (
          <Alert variant="danger" onClose={() => setFailedMessage('')} dismissible>
            {failedMessage}
          </Alert>
        )}
      </div>
          <div className='user-table'>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Generic Name</th>
            <th>Brand</th>
            <th>Expiration Date</th>
            <th>Stocks</th>
            <th>Category</th>
            <th class="fixed-header">Description</th>
            <th>Manufacturer</th>
            <th>Storage Location</th>
            <th>Source</th>
            <th>Batch No.</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {medicines.map((item) => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td>{item.genericName}</td>
              <td>{item.brand}</td>
              <td>{item.expirationDate}</td>
              <td>{item.qty}</td>
              <td>{item.category}</td>
              <td className="fixed-cell">
                <div className="scrollable-content">{item.description}</div>
              </td>
              <td>{item.manufacturer}</td>
              <td>{item.storageLocation}</td>
              <td>{item.source}</td>
              <td>{item.batchNumber}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => handleSelectMedicine(item)}
                >
                  SELECT
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>Medicine Dispense</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form id="my-form" onSubmit={handleDispenseSubmit}>
      <div className ='form-divider2'><Row>
      <h4> SELECTED ITEM INFORMATION</h4>
        <Col>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={selectedMedicine?.name}
          readOnly
          required
        />
      </Form.Group> </Col>
      <Col><Form.Group >
        <Form.Label>Generic Name</Form.Label>
        <Form.Control
          type="text"
          value={selectedMedicine?.genericName}
          readOnly
          required
        />
      </Form.Group> </Col>
      <Col><Form.Group >
        <Form.Label>Brand</Form.Label>
        <Form.Control
          type="text"
          value={selectedMedicine?.brand}
          readOnly
          required
        />
      </Form.Group> </Col></Row>
      <Row>
        <Col> <Form.Group >
        <Form.Label>Batch Number</Form.Label>
        <Form.Control
          type="number"
          value={selectedMedicine?.batchNumber}
          readOnly
          required
        />
      </Form.Group></Col><Col>
      <Form.Group>
        <Form.Label>Manufacturer</Form.Label>
        <Form.Control
          type="text"
          value={selectedMedicine?.manufacturer}
          readOnly
          required
        />
      </Form.Group> </Col>
     </Row>
      <Row>
      <Col><Form.Group >
        <Form.Label>Location</Form.Label>
        <Form.Control
          type="text"
          value={selectedMedicine?.storageLocation}
          readOnly
          required
        />
      </Form.Group> </Col>
        <Col><Form.Group >
        <Form.Label>Source</Form.Label>
        <Form.Control
          type="text"
          value={selectedMedicine?.source}
          readOnly
          required
        />
      </Form.Group> </Col>
      <Col> <Form.Group >
        <Form.Label>Current Stocks</Form.Label>
        <Form.Control
          type="number"
          value={selectedMedicine?.qty}
          readOnly
          required
        />
      </Form.Group></Col></Row> </div>
      <div className ='form-divider2'><h4> FILL UP INFORMATION BELOW</h4>
      <Form.Group className="mb-3">
        <Form.Label>First Name <span style={{ color: 'red' }}>*</span> </Form.Label>
        <Form.Control
          type="text"
          name="patientFName"
          value={dispenseData.patientFName}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label> Middle Name <span style={{ color: 'red' }}>*</span> </Form.Label>
        <Form.Control
          type="text"
          name="patientMName"
          value={dispenseData.patientMName}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Last Name <span style={{ color: 'red' }}>*</span> </Form.Label>
        <Form.Control
          type="text"
          name="patientLName"
          value={dispenseData.patientLName}
          onChange={handleInputChange}
          required
        />
      </Form.Group> 
      <Form.Group className="mb-3">
        <Form.Label>Given By <span style={{ color: 'red' }}>*</span> </Form.Label>
        <Form.Control
          type="text"
          name="dispenseBy"
          value={dispenseData.dispenseBy}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Notes <span style={{ color: 'red' }}>*</span> </Form.Label>
        <Form.Control
          type="text"
          name="remarks"
          value={dispenseData.remarks}
          onChange={handleInputChange}
          required
        />
      </Form.Group>
      <Row>
      <Col><Form.Group>
        <Form.Label>Date Given <span style={{ color: 'red' }}>*</span> </Form.Label>
        <Form.Control
          type="date"
          name="dispenseDate"
          value={dispenseData.dispenseDate}
          onChange={handleInputChange}
          required
        />
      </Form.Group></Col>
      <Col><Form.Group>
        <Form.Label>Quantity <span style={{ color: 'red' }}>*</span> </Form.Label>
        <Form.Control
          type="number"
          name="qtyToGive"
          value={dispenseData.qtyToGive}
          onChange={handleInputChange}
          required
        />
      </Form.Group> </Col></Row> </div>
      </Form> </Modal.Body>
        <Modal.Footer>
      <Button variant="primary" type="submit" form="my-form">
        ISSUE ITEM
      </Button>
      </Modal.Footer>
</Modal>

    </div>
  );
};

export default MedicineDispense;
