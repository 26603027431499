import React, { useState, useEffect } from 'react';
import { Table, Alert, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { getAdminUsers } from '../Controller/admin_controller';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getAdminUsers();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching admins:', error);
      setError('Failed to fetch admins.');
    }
  };

  return (
    <div className="main-content">
      <div className="page-header">
        <FontAwesomeIcon icon={faUser} className="user-header-icon" />
        ADMIN ACCOUNTS
      </div>

      <div className="container-usertable">
        <div className="row">
          <div className="col-md-12">
            {/* Remove Add Admin button */}
          </div>
        </div>
      </div>

      <div className="container-usertable">
        {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        <div className="user-table">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Address</th>
                <th>Role</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.email}</td>
                  <td>{user.fName}</td>
                  <td>{user.mName}</td>
                  <td>{user.lName}</td>
                  <td>{user.address}</td>
                  <td>{user.role}</td>
                  <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Main Modal for Add/Update */}
      <Modal show={false} onHide={() => {}}>
        <Modal.Header closeButton>
          <Modal.Title>Modal Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Modal content */}
        </Modal.Body>
      </Modal>

      {/* Password Reset Modal */}
      <Modal show={false} onHide={() => {}}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Modal content */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AdminPage;
