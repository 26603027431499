import axios from 'axios';

const API_BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api'; 

// Fetch all patients
export const getPatients = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/get-users`, {
      params: { page, limit } // Pass page and limit as query parameters
    });
    console.log('Fetched Patients Response:', response.data); // Log the response data
    return response.data;
  } catch (error) {
    console.error('Error fetching patients:', error);
    throw error; 
  }
};


// Update a patient by ID
export const updatePatient = async (id, patientData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/users/update-patient/${id}`, patientData);
    return response.data;
  } catch (error) {
    console.error('Error updating patient:', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
};

// Delete a patient by ID
export const deletePatient = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/users/delete-users/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting patient:', error);
    throw error; 
  }
};
export const searchPatientUser = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/search-patient-users`, {
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching search patient user:", error);
    return [];
  }
};
