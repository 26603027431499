import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { createSchedule } from './Controller/ScheduleManagement_controller';
import "bootstrap/dist/css/bootstrap.min.css";
import './ScheduleStyles.css';
import ScheduleCalendar from './ScheduleCalendar'; // Adjust path as needed

const ScheduleManagement = () => {
    const [selectedPurpose, setSelectedPurpose] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [availableHealthCenters, setAvailableHealthCenters] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [filteredEndTimes, setFilteredEndTimes] = useState([]);
    const healthCenters = ["BHCS1", "BHCS2", "BHCS3"];

    const timeOptions = [
        "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM"
    ];

    const timeToValue = {
        "8:00 AM": 8, "8:30 AM": 8.5, "9:00 AM": 9, "9:30 AM": 9.5, "10:00 AM": 10, "10:30 AM": 10.5, "11:00 AM": 11, "11:30 AM": 11.5, "12:00 PM": 12
    };

    const purposeSlots = {
        "General Check-up": 2,
        "Prenatal Check-up": 2,
        "Family Planning": 2,
        "Immunization": 2
    };

    useEffect(() => {
        if (startTime) {
            const startValue = timeToValue[startTime];
            const newEndTimes = timeOptions.filter(time => timeToValue[time] > startValue);
            setFilteredEndTimes(newEndTimes);
            if (!endTime || !newEndTimes.includes(endTime)) {
                setEndTime(newEndTimes[0] || "");
            }
        } else {
            setFilteredEndTimes(timeOptions);
        }
    }, [startTime]);

    const handlePurposeChange = (e) => {
        setSelectedPurpose(e.target.value);
    };

    const handleHealthCenterChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setAvailableHealthCenters([...availableHealthCenters, value]);
        } else {
            setAvailableHealthCenters(availableHealthCenters.filter(center => center !== value));
        }
    };

    const handleDayChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setAvailableDays([...availableDays, value]);
        } else {
            setAvailableDays(availableDays.filter(day => day !== value));
        }
    };

    const filterValidDates = (date) => {
        const day = date.toLocaleString('en-US', { weekday: 'long' });
        return availableDays.includes(day);
    };
    const buildTimeSlots = () => {
      const slotsPerPurpose = purposeSlots[selectedPurpose] || 0;
      const start = timeToValue[startTime];
      const end = timeToValue[endTime];
      const timeSlots = {};
  
      // Define the fixed time options with 30-minute intervals
      const fixedTimeOptions = [
          "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM",
          "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM"
      ];
  
      fixedTimeOptions.forEach((time, index) => {
          const timeValue = timeToValue[time];
          const nextTime = fixedTimeOptions[index + 1] || "End of Day";
          const nextTimeValue = timeToValue[nextTime] || Infinity;
  
          // Handle slots before the start time
          if (timeValue < start) {
              if (nextTime !== "End of Day") {
                  timeSlots[`${time} - ${nextTime}`] = {
                      BHCS1: { slots: availableHealthCenters.includes("BHCS1") ? 0 : 0 },
                      BHCS2: { slots: availableHealthCenters.includes("BHCS2") ? 0 : 0 },
                      BHCS3: { slots: availableHealthCenters.includes("BHCS3") ? 0 : 0 }
                  };
              }
          } 
          // Handle slots within the selected time range
          else if (timeValue >= start && timeValue < end) {
              timeSlots[`${time} - ${nextTime}`] = {
                  BHCS1: { slots: availableHealthCenters.includes("BHCS1") ? slotsPerPurpose : 0 },
                  BHCS2: { slots: availableHealthCenters.includes("BHCS2") ? slotsPerPurpose : 0 },
                  BHCS3: { slots: availableHealthCenters.includes("BHCS3") ? slotsPerPurpose : 0 }
              };
          } 
          // Handle slots after the end time
          else if (timeValue >= end) {
              if (nextTime !== "End of Day") {
                  timeSlots[`${time} - ${nextTime}`] = {
                      BHCS1: { slots: availableHealthCenters.includes("BHCS1") ? 0 : 0 },
                      BHCS2: { slots: availableHealthCenters.includes("BHCS2") ? 0 : 0 },
                      BHCS3: { slots: availableHealthCenters.includes("BHCS3") ? 0 : 0 }
                  };
              }
          }
      });
  
      return timeSlots;
  };
  
  
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        const confirmed = window.confirm("Are you sure you want to set this schedule?");
            
        if (!confirmed) {
            // If the user does not confirm, exit the function
            return;
        }
        const utcDate = selectedDate ? new Date(Date.UTC(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate()
        )) : null;

        const timeSlots = buildTimeSlots();

        const data = {
            date: utcDate ? utcDate.toISOString().split('T')[0] : null,
            purpose: selectedPurpose,
            healthCenters: timeSlots
        };

        try {
            await createSchedule(data);
            setSuccessMessage("Schedule set successfully!");
            setErrorMessage("");  
            clearMessages(); 
        } catch (error) {
            console.error("Error setting schedule", error);
            setErrorMessage("Failed to set schedule.");
            setSuccessMessage(""); 
            clearMessages();  
        }
    };

    const clearMessages = () => {
        setTimeout(() => {
            setSuccessMessage("");
            setErrorMessage("");
        }, 5000);
    };

    return (
        <div className="main-content">
            <div className="page-header">
                <FontAwesomeIcon icon={faCalendarDays} className="inventory-icon" />
                SCHEDULE MANAGEMENT
            </div>

            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}

            <div className="schedule-layout-grid">
                <form onSubmit={handleSubmit} className="schedule-form-container">
                <div className="schedule-form-title">Set Up Appointment Schedules</div>
                    <div className="schedule-form-group">
                        <label>Service:</label>
                        <select className="form-control" value={selectedPurpose} onChange={handlePurposeChange} required>
                            <option value="">Select Service</option>
                            <option value="General Check-up">General Check-up</option>
                            <option value="Prenatal Check-up">Prenatal Check-up</option>
                            <option value="Family Planning">Family Planning</option>
                            <option value="Immunization">Immunization</option>
                        </select>
                    </div>

                    <div className="schedule-form-group">
                        <label>Days Available:</label>
                        <div className="form-check-group">
                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((day, index) => (
                                <div key={index} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value={day}
                                        id={day}
                                        onChange={handleDayChange}
                                    />
                                    <label className="form-check-label" htmlFor={day}>
                                        {day}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="schedule-form-group">
                        <label>Date:</label>
                        <DatePicker
                            className="form-control"
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            filterDate={filterValidDates}
                            placeholderText="Select a date"
                            required
                        />
                    </div>

                    <div className="schedule-form-group">
                        <label>Health Centers:</label>
                        <div className="form-check-group">
                            {healthCenters.map((center, index) => (
                                <div key={index} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        value={center}
                                        id={center}
                                        onChange={handleHealthCenterChange}
                                        
                                    />
                                    <label className="form-check-label" htmlFor={center}>
                                        {center}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                        <div className="schedule-form-group">
                            <label>Start Time:</label>
                            <select className="form-control" value={startTime} onChange={(e) => setStartTime(e.target.value)} required>
                                <option value="">Select Start Time</option>
                                {timeOptions.map((time, index) => (
                                    <option key={index} value={time}>{time}</option>
                                ))}
                            </select>
                        </div>

                        <div className="schedule-form-group">
                            <label>End Time:</label>
                            <select className="form-control" value={endTime} onChange={(e) => setEndTime(e.target.value)} required>
                                <option value="">Select End Time</option>
                                {filteredEndTimes.map((time, index) => (
                                    <option key={index} value={time}>{time}</option>
                                ))}
                            </select>
                    </div>

                    <button type="submit" className="btn btn-primary btn-block">Set Schedule</button>
                </form>
                <div className="set-schedule-container">
                <ScheduleCalendar /></div>
            </div>
        </div>
    );
};

export default ScheduleManagement;
