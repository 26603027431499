class ChildHealthRecordModel {
  constructor({
    fName,
    mName,
    lName,
    sex, 
    dateOfBirth,
    motherFirstName,
    motherLastName,
    motherOccupation,
    fatherFirstName,
    fatherLastName,
    fatherOccupation,
    houseBuildingNumber,
    streetName,
    sitio,
    contactNo,
    contactPerson,
    typeOfDelivery,
    birthWT,
    birthHT,
    muac,
    newbornScreeningDate,
    cpab, 
    seStatus,
    birthAttendant,
    headCircumference,
    chestCircumference,
    abdomenCircumference,
  }) {
    this.fName = fName;
    this.mName = mName;
    this.lName = lName;
    this.sex = sex;
    this.dateOfBirth = dateOfBirth;
    this.motherFirstName = motherFirstName;
    this.motherLastName = motherLastName;
    this.motherOccupation = motherOccupation;
    this.fatherFirstName = fatherFirstName;
    this.fatherLastName = fatherLastName;
    this.fatherOccupation = fatherOccupation;
    this.houseBuildingNumber = houseBuildingNumber;
    this.streetName = streetName;
    this.sitio = sitio;
    this.contactNo = contactNo;
    this.contactPerson = contactPerson;
    this.typeOfDelivery = typeOfDelivery;
    this.birthWT = birthWT;
    this.birthHT = birthHT;
    this.muac = muac;
    this.cpab = cpab;
    this.newbornScreeningDate = newbornScreeningDate;
    this.seStatus = seStatus;
    this.birthAttendant = birthAttendant;
    this.headCircumference = headCircumference;
    this.chestCircumference = chestCircumference;
    this.abdomenCircumference = abdomenCircumference;
  }
}

export { ChildHealthRecordModel };
