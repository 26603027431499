import axios from 'axios';

const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api'; 

export const getAdminUsers = async () => {
  const response = await axios.get(`${BASE_URL}/users/get-admins`);
  return response.data;
};

export const addAdminUser = async (user) => {
  const response = await axios.post(`${BASE_URL}/users/register-admin`, user);
  return response.data;
};

export const updateAdminUser = async (id, user) => {
  const response = await axios.put(`${BASE_URL}/users/update-admin/${id}`, user);
  return response.data;
};

export const deleteAdminUser = async (id) => {
  const response = await axios.delete(`${BASE_URL}/users/delete-users/${id}`);
  return response.data;
};

export const resetAdminPassword = async (email, newPassword) => {
    console.log(email);
    const response = await axios.post(`${BASE_URL}/users/reset-admin-password`, { email, newPassword });
    return response.data;
  };

  //resend verificaiton email
  export const resendVerificationEmail = async (uid) => {
    try {
      const response = await axios.post(`${BASE_URL}/users/resend-verification-email`, { uid });
      return response.data;
    } catch (error) {
      console.error('Error resending verification email:', error);
      throw error;
    }
  };
  
  export const checkAdminVerificationStatus = async (uid) => {
    try {
      const response = await axios.post(`${BASE_URL}/users/check-verification-status`, { uid });
      return response.data;
    } catch (error) {
      console.error('Error checking verification status:', error);
      throw error;
    }
  };