import axios from 'axios';

const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api';

// Add a new family planning record
export const addFamilyPlanningRecord = async (record) => {
  try {
    console.log("record", record);
    const response = await axios.post(`${BASE_URL}/familyPlanning/records`, record);
    console.log(response.data);

    return response.data;
  } catch (error) {
    console.error('Error adding family planning record:', error);
    throw error;
  }
};

// Fetch all family planning records
export const getFamilyPlanningRecords = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/familyPlanning/records`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching family planning records:', error);
    throw error;
  }
};

// Fetch a family planning record by ID
export const getFamilyPlanningRecordById = async (id) => {
  try {
    console.log("recieve id", id);
    const response = await axios.get(`${BASE_URL}/familyPlanning/family-records/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching family planning record by ID:', error);
    throw error;
  }
};

export const updateFamilyPlanningRecord = async (id, record) => {
  console.log("family plan record", record);

  try {
    const response = await axios.put(`${BASE_URL}/familyPlanning/family-records/${id}`, record);
    return response.data;
  } catch (error) {
    console.error('Error updating family planning record:', error);
    throw error;
  }
};
// Add a new service visit to a family planning record
export const addServiceVisit = async (recordId, newVisit) => {
  try {
    console.log("record id", recordId);
    const response = await axios.post(`${BASE_URL}/familyPlanning/family-records/${recordId}/service-visits`, newVisit);
    return response.data;
  } catch (error) {
    console.error('Error adding service visit:', error);
    throw error;
  }
};
export const getServiceVisitsByRecordId = async (recordId) => {
  try { const response = await axios.get(`${BASE_URL}/familyPlanning/family-records/${recordId}/service-visits`);
  return response.data; } catch (error) {
    console.error('Error adding service visit:', error);
    throw error;
  }
};
// Update an existing service visit
export const updateServiceVisit = async (recordId, updatedVisit) => {
  try {
    const response = await axios.put(`${BASE_URL}/familyPlanning/family-records/${recordId}/service-visits/${updatedVisit._id}`, updatedVisit);
    return response.data;
  } catch (error) {
    console.error('Error updating service visit:', error);
    throw error;
  }
};
export const deleteServiceVisit = async (recordId, visitId) => {
  try {
    await axios.delete(`${BASE_URL}/familyPlanning/family-records/${recordId}/service-visits/${visitId}`);
  } catch (error) {
    console.error('Error deleting service visit:', error);
    throw error;
  }
};

export const searchFamilyPlanningRecords = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/familyPlanning/search-family-records`, {
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching child health records:", error);
    return [];
  }
};
export const deleteFamilyPlanningRecord = async (id) => {
  try {
    await axios.put(`${BASE_URL}/familyPlanning/records/${id}`);
  } catch (error) {
    console.error('Error deleting family planning record:', error);
    throw error;
  }
};

export const searchArchivedFamilyPlanningRecords = async (searchQuery = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/familyPlanning/search-archived-family-records`, {
      params: { search: searchQuery }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching child health records:", error);
    return [];
  }
};

export const getArchivedFamilyPlanningRecords = async (page = 1, limit = 20) => {
  try {
    const response = await axios.get(`${BASE_URL}/familyPlanning/archivedFamilyRecords`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching archived child health records:', error);
    throw error;
  }
};

// Restore archived child health record
export const restoreFamilyPlanningRecord = async (id) => {
  try {
    const response = await axios.put(`${BASE_URL}/familyPlanning/restore-familyRecord/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error restoring child health record:', error);
    throw error;
  }
};