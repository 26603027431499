import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import './App.css';

function HealthWorkerBookedPatients() {
  const [appointments, setAppointments] = useState([
    {
      firstName: 'John',
      lastName: 'Doe',
      date: '2024-06-10',
      time: '10:00 AM - 10:30 AM',
      description: 'General Checkup'
    },
    {
      firstName: 'Jane',
      lastName: 'Smith',
      date: '2024-06-12',
      time: '2:00 PM - 2:30 PM',
      description: 'Maternal Checkup'
    },
  ]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleViewAppointment = (appointment) => {
    console.log('Viewing appointment:', appointment);
    // Implement the logic to view the appointment details
  };

  const filteredAppointments = appointments.filter(
    (appointment) =>
      `${appointment.firstName} ${appointment.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="health-worker-booked-patients">
      <div className='page-header'> <FontAwesomeIcon icon={faCalendarCheck} className="appointment-icon" />APPOINTMENTS</div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search Appointments..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="schedule-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Date</th>
            <th>Time</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {filteredAppointments.map((appointment, index) => (
            <tr key={index}>
              <td>{appointment.firstName}</td>
              <td>{appointment.lastName}</td>
              <td>{appointment.date}</td>
              <td>{appointment.time}</td>
              <td>{appointment.description}</td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HealthWorkerBookedPatients;
