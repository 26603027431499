import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import {
  getChildHealthRecordById,
  updateChildHealthRecord,
  getChildVisitRecords,
  addChildVisitRecord,
  updateVisitRecord, 
  deleteVisitRecord,
  getVaccineRecords,
  addVaccineRecord,
  updateVaccineRecord,
  deleteVaccineRecord,
} from '../Controller/ChildHealthRecord_controller';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChildHealthRecordForm from './childHealthRecordForm';
import ChildVisitRecordTable from './childVisitTable';
import VaccineRecordsTable from './vaccineRecordTable';
import '../recordStyles.css'; 
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const vaccineNames = [
  'BCG vaccine',
  'Hepatitis B',
  'Pentavalent',
  'OPV',
  'IPV',
  'PCV13',
  'Measles',
  'H. Influenza Type B',
  'Rotavirus',
  'Influenza',
  'Varicella',
  'MMR',
  'Hepatitis A',
  'Typhoid',
  'Others',
];
const diagnoses = [
  'Acute Body Diarrhea', 'Acute Lower Respiratory Tract Infections', 'Acute Watery Diarrhea',
  'Animal Bites', 'Acute Respiratory Infection (5 years old above)', 'Acute Respiratory Infection (5 years old below)', 'Asthma', 'Acute Tonsillopharyngitis (ATP)', 'Chickenpox', 
  'Cholera', 'COPD', 'Dengue', 'Dermatitis', 'Diarrhea', 'Diphtheria', 'Diseases of the Heart', 
  'DMT', 'Fever', 'Filariasis', 'Gastritis', 'Genital ulcer', 'GERD', 'Gonorrhea', 'Influenza', 
  'Influenza-like illness', 'Leprosy', 'Leptospirosis', 'Malaria', 'Malnutrition', 'Measles', 
  'Meningococcemia', 'Neonatal Telanus', 'Non-neonatal Telanus', 'Paralytic Shellfish Poisoning', 
  'Pneumonia', 'Rashes', 'Schislosomiasis', 'Skin Disease', 'Syphilis', 'Typhoid and paratyphoid', 
  'Upper Respiratory Tract Infections', 'Urethral discharge', 'Urinary Tract Infection', 
  'Viral hepatitis', 'Viral meningitis'
];
const doses = [
  '1st',
  '2nd',
  '3rd',
  'booster 1',
  'booster 2',
  'booster 3',
];
const ChildHealthRecordDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [record, setRecord] = useState({
    fName: '',
    mName: '',
    lName: '',
    sex: '',
    dateOfBirth: '',
    motherFirstName: '',
    motherLastName: '',
    motherOccupation: '',
    fatherFirstName: '',
    fatherLastName: '',
    fatherOccupation: '',
    houseBuildingNumber: '',
    sitio: '',
    contactNo: '',
    contactPerson: '',
    typeOfDelivery: '',
    birthWT: '',
    birthHT: '',
    muac: '',
    cpab: '',
    newbornScreeningDate: '',
    seStatus: '',
    birthAttendant: '',
    headCircumference: '',
    chestCircumference: '',
    abdomenCircumference: '',
    childRecord_id: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [visitRecords, setVisitRecords] = useState([]);
  const [vaccineRecords, setVaccineRecords] = useState([]);
  const [showVisitModal, setShowVisitModal] = useState(false);
  const [showVaccineModal, setShowVaccineModal] = useState(false);
  const [addVisitSuccess, setAddVisitSuccess] = useState(false);
  const [vaccineUpdateSuccess, setVaccineUpdateSuccess] = useState(false);
  const [vaccineAddedSuccess, setVaccineAddedSuccess] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [customDiagnosis, setCustomDiagnosis] = useState('');

  const [newVisit, setNewVisit] = useState({
    visitDate: '',
    age: '',
    weight: '',
    height: '',
    temperature: '',
    feeding: '',
    diagnosis: '',
    prescription: '',
    remarks: '',
    checkedBy: '',
  });
  const [editVaccineIndex, setEditVaccineIndex] = useState(null);
  const [newVaccineRecord, setNewVaccineRecord] = useState({
    vaccine_name: '',
    dose: '',
    date: '',
    remarks: '',
  });

  useEffect(() => {
    fetchRecord();
  }, []);

  useEffect(() => {
    if (record.childRecord_id) {
      fetchVisitRecords(record.childRecord_id);
      fetchVaccineRecords(record.childRecord_id);
    }
  }, [record.childRecord_id]);

  const fetchRecord = async () => {
    try {
      const fetchedRecord = await getChildHealthRecordById(id);
      setRecord(fetchedRecord);
    } catch (error) {
      console.error('Error fetching child health record:', error);
    }
  };

  const handleDiagnosisChange = (e) => {
    const selectedDiagnosis = e.target.value;
    setNewVisit((prevVisit) => ({
      ...prevVisit,
      diagnosis: selectedDiagnosis,
      customDiagnosis: selectedDiagnosis === "Other" ? "" : prevVisit.customDiagnosis
    }));
    setIsOtherSelected(selectedDiagnosis === "Other");
  };

  const handleCustomDiagnosisChange = (e) => {
    const value = e.target.value;
    setCustomDiagnosis(value);
    setNewVisit((prevVisit) => ({
      ...prevVisit,
      customDiagnosis: value
    }));
  };

  const fetchVisitRecords = async (childRecordId) => {
    try {
      const fetchedVisitRecords = await getChildVisitRecords(childRecordId);
      setVisitRecords(fetchedVisitRecords);
    } catch (error) {
      console.error('Error fetching visit records:', error);
    }
  };

  const fetchVaccineRecords = async (childRecordId) => {
    try {
      const fetchedVaccineRecords = await getVaccineRecords(childRecordId);
      setVaccineRecords(fetchedVaccineRecords); // Make sure this updates the state
      console.log('Fetched records:', fetchedVaccineRecords); // Log fetched data
    } catch (error) {
      console.error('Error fetching vaccine records:', error);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecord({
      ...record,
      [name]: value,
    });
  };

  const handleUpdateRecord = async () => {
    const isConfirmed = window.confirm("Are you sure you want to update this record?");
    
    if (isConfirmed) {
      try {
        await updateChildHealthRecord(id, record);
        setUpdateSuccess(true);
        setEditMode(false);
        fetchRecord();
        setTimeout(() => {
          setUpdateSuccess(false);
        }, 3000);
      } catch (error) {
        console.error('Error updating child health record:', error);
      }
    } else {
      console.log('Update canceled by the user');
    }
  };
  
  const handleCancelEdit = () => {
    setEditMode(false);
    fetchRecord();
  };

  const handleAddVisit = () => {
    setShowVisitModal(true);
  };

  const handleUpdateVisitRecord = async (updatedRecord) => {
    try {
      const response = await updateVisitRecord(updatedRecord);
      setVisitRecords(prevRecords => prevRecords.map(record => record._id === updatedRecord._id ? response : record));
      fetchVisitRecords(record.childRecord_id);

    } catch (error) {
      console.error('Error updating visit record:', error);
    }
  };

  const handleDeleteVisitRecord = async (visitId) => {
    try {
      await deleteVisitRecord(visitId);
      setVisitRecords(prevRecords => prevRecords.filter(record => record._id !== visitId));
    } catch (error) {
      console.error('Error deleting visit record:', error);
    }
  };

  const handleCloseVisitModal = () => {
    setShowVisitModal(false);
    setNewVisit({
      visitDate: '',
      age: '',
      weight: '',
      height: '',
      temperature: '',
      feeding: '',
      diagnosis: '',
      prescription: '',
      remarks:'',
      checkedBy: '',
    });
  };
  const [errors, setErrors] = useState({});
  const handleSaveVisit = async () => {
    const newErrors = {};
  
    // check for empty fields
    if (!newVisit.visitDate) newErrors.visitDate = 'Visit date is required';
    if (!newVisit.weight) newErrors.weight = 'Weight is required';
    if (!newVisit.height) newErrors.height = 'Height is required';
    if (!newVisit.temperature) newErrors.temperature = 'Temperature is required';
    if (!newVisit.checkedBy) newErrors.checkedBy = 'Checked by is required';
  
    // If there are errors, set them in the state and return early
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // If "Other" is selected, update the diagnosis with custom diagnosis
    if (isOtherSelected) {
      setNewVisit((prevVisit) => ({
        ...prevVisit,
        diagnosis: customDiagnosis,
      }));
    }
  
    try {
      const age = calculateAge(record.dateOfBirth, newVisit.visitDate);
      const visitWithAge = { ...newVisit, age, diagnosis: isOtherSelected ? customDiagnosis : newVisit.diagnosis };
      
      await addChildVisitRecord(record.childRecord_id, visitWithAge);
      setShowVisitModal(false);
      fetchVisitRecords(record.childRecord_id);
      setAddVisitSuccess(true);
      setTimeout(() => {
        setAddVisitSuccess(false);
      }, 3000);
      setNewVisit({
        visitDate: '',
        age: '',
        weight: '',
        height: '',
        temperature: '',
        feeding: '',
        diagnosis: '',
        prescription: '',
        remarks:'',
        checkedBy: '',
      });
      setCustomDiagnosis(''); // Clear custom diagnosis field
      setIsOtherSelected(false); // Reset "Other" option
    } catch (error) {
      console.error('Error adding visit record:', error);
    }
  };
  
  

  const calculateAge = (dob, visitDate) => {
    const birthDate = new Date(dob);
    const visit = new Date(visitDate);
    let ageYears = visit.getFullYear() - birthDate.getFullYear();
    let ageMonths = visit.getMonth() - birthDate.getMonth();
    if (ageMonths < 0) {
      ageYears--;
      ageMonths += 12;
    }
    return `${ageYears} year(s) ${ageMonths} month(s)`;
  };

  const [isCustomVaccine, setIsCustomVaccine] = useState(false);

  const handleVaccineNameChange = (e) => {
    const value = e.target.value;
    setIsCustomVaccine(value === 'Others');
    setNewVaccineRecord(prevState => ({
      ...prevState,
      vaccine_name: value === 'Others' ? '' : value
    }));
  };
  
  const handleCustomVaccineNameChange = (e) => {
    const value = e.target.value;
    setNewVaccineRecord(prevState => ({
      ...prevState,
      vaccine_name: value
    }));
  };

  
  const handleDoseChange = (e) => {
    const value = e.target.value;
    setNewVaccineRecord({
      ...newVaccineRecord,
      dose: value,
    });
  };
  
  const handleRemarksChange = (e) => {
    const value = e.target.value;
    setNewVaccineRecord({
      ...newVaccineRecord,
      remarks: value,
    });
  };
  
  const handleDateChange = (e) => {
    const value = e.target.value;
    setNewVaccineRecord({
      ...newVaccineRecord,
      date: value,
    });
  };

  const handleOpenVaccineModal = () => {
    setShowVaccineModal(true);
  };

  const handleCloseVaccineModal = () => {
    setShowVaccineModal(false);
    setNewVaccineRecord({
      vaccine_name: '',
      dose: '',
      date: '',
      remarks: '',
    });
  };

  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleSaveNewVaccine = async () => {
    const errors = {};

    // Check if vaccine_name and dose are set
    if (!newVaccineRecord.vaccine_name) { errors.vaccine_name = 'Vaccine name is required'; }
    if (!newVaccineRecord.dose) {errors.dose = 'Dose is required';  }
    if (!newVaccineRecord.date) {errors.date = 'Date is required';  }
    if (!newVaccineRecord.remarks) { errors.remarks = 'Remarks is required';  }

    // Set form errors and return if there are validation errors
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setValidated(true);
      return;
    }

    try {
      await addVaccineRecord(record.childRecord_id, newVaccineRecord);
      setShowVaccineModal(false);
      setVaccineAddedSuccess(true);
      setTimeout(() => {
        setVaccineAddedSuccess(false);
      }, 3000);
      fetchVaccineRecords(record.childRecord_id);
      setNewVaccineRecord({
        vaccine_name: '',
        dose: '',
        date: '',
        remarks: '',
      });
    } catch (error) {
      console.error('Error adding vaccine record:', error);
    }
  };
  
  

  // Handler functions for edit, save, and delete actions in vaccine records
  const handleEditClick = (index) => {
    const vaccineRecord = vaccineRecords[index];
    setEditVaccineIndex(index);
    setNewVaccineRecord({
      ...vaccineRecord,
    });
    setShowVaccineModal(true);
  };

  const handleSaveClick = async () => {
    try {
      await updateVaccineRecord(record.childRecord_id, newVaccineRecord);
      setShowVaccineModal(false);
      console.log('child record id:',record.childRecord_id,);
      fetchVaccineRecords(record.childRecord_id);
      setNewVaccineRecord({
        vaccine_name: '',
        dose: '',
        date: '',
        remarks: '',
      });
      setEditVaccineIndex(null);
      setVaccineUpdateSuccess(true);
      setTimeout(() => {
        setVaccineUpdateSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error updating vaccine record:', error);
    }
  };


  const handleDeleteClick = async (index) => {
    const vaccineRecord = vaccineRecords[index];
    const vaccineId = vaccineRecord._id;
  
    if (!vaccineId) {
      console.error('Vaccine ID is undefined or null');
      return;
    }
  
    try {
      await deleteVaccineRecord(vaccineId);
      fetchVaccineRecords(record.childRecord_id);
      console.log('child record id:', record.childRecord_id);
      console.log('record id:',vaccineId);

      console.log('Updated records:', vaccineRecords);

    } catch (error) {
      console.error('Error deleting vaccine record:', error);
    }
  };
  
  return (
    <Container>
      <div className='record-title'><h3>PERSONAL INFORMATION</h3> </div>
      {updateSuccess && <Alert variant="success">Record updated successfully!</Alert>}
      <ChildHealthRecordForm
        record={record}
        setEditMode={setEditMode}
        editMode={editMode}
        handleInputChange={handleInputChange}
        handleUpdateRecord={handleUpdateRecord}
        handleCancelEdit={handleCancelEdit}
      />

    <div className="container-columns">
      <div className="left-column">
      <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="record-title">
        <h3 className="mb-0">VACCINE RECORDS</h3> {/* Use mb-0 to remove margin-bottom */}
      </div>
      <div className="add-user-button">
        <Button variant="info"className="mb-3" onClick={handleOpenVaccineModal}>
      <FontAwesomeIcon icon={faPlus} />  ADD VACCINE RECORD
      </Button> </div>
    </div>
        <div className="msg">
          {vaccineUpdateSuccess && <Alert variant="success">Vaccine record updated successfully!</Alert>}
          {vaccineAddedSuccess && <Alert variant="success">Vaccine record added successfully!</Alert>}
        </div>
        <VaccineRecordsTable
          vaccineRecords={vaccineRecords}
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          handleDeleteClick={handleDeleteClick}
          editIndex={editVaccineIndex}
        />
      </div>

  <div className="right-column">
    {addVisitSuccess && <Alert variant="success">Visit record added successfully!</Alert>}
    <div className="d-flex justify-content-between align-items-center mb-3">
    <div className="record-title">
      <h3 className="mb-0">CHECK-UP</h3>
    </div>
    <div className="add-user-button"> <Button className="mb-3" variant="info" onClick={handleAddVisit}>
    <FontAwesomeIcon icon={faPlus} /> ADD CHECK-UP
    </Button> </div></div>
    <ChildVisitRecordTable
      visitRecords={visitRecords}
      handleUpdateVisitRecord={handleUpdateVisitRecord}
      handleDeleteVisitRecord={handleDeleteVisitRecord}
    />
  </div>
</div>

      <Modal show={showVisitModal} onHide={handleCloseVisitModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Visit Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
    <Form>
      <Row className='form-row-divider'>
        <Col>
          <Form.Group controlId="formVisitDate">
            <Form.Label>Visit Date</Form.Label>
            <Form.Control
              type="date"
              value={newVisit.visitDate}
              onChange={(e) => setNewVisit({ ...newVisit, visitDate: e.target.value })}
              isInvalid={!!errors.visitDate}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formWeight">
            <Form.Label>Weight (kg)</Form.Label>
            <Form.Control
              type="number"
              name="weight"
              value={newVisit.weight}
              onChange={(e) => setNewVisit({ ...newVisit, weight: e.target.value })}
              isInvalid={!!errors.weight}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='form-row-divider'>
        <Col>
          <Form.Group controlId="formHeight">
            <Form.Label>Height (cm)</Form.Label>
            <Form.Control
              type="number"
              name="height"
              value={newVisit.height}
              onChange={(e) => setNewVisit({ ...newVisit, height: e.target.value })}
              isInvalid={!!errors.height}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formTemperature">
            <Form.Label>Temperature °C</Form.Label>
            <Form.Control
              type="number"
              name="temperature"
              value={newVisit.temperature}
              onChange={(e) => setNewVisit({ ...newVisit, temperature: e.target.value })}
              isInvalid={!!errors.temperature}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='form-row-divider'>
        <Col>
          <Form.Group controlId="formFeeding">
            <Form.Label>Feeding</Form.Label>
            <Form.Control
              type="text"
              name="feeding"
              value={newVisit.feeding}
              onChange={(e) => setNewVisit({ ...newVisit, feeding: e.target.value })}
            />
          </Form.Group>
        </Col>
        </Row>
        <Row className='form-row-divider'>
          <Col md={isOtherSelected ? 6 : 12}>
            <Form.Group controlId="formDiagnosis">
              <Form.Label>Diagnosis</Form.Label>
              <Form.Control
                as="select"
                name="diagnosis"
                value={newVisit.diagnosis}
                onChange={handleDiagnosisChange}
              >
                <option value="">Select a diagnosis</option>
                {diagnoses.map((diagnosis, index) => (
                  <option key={index} value={diagnosis}>{diagnosis}</option>
                ))}
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>
          </Col>
          {isOtherSelected && (
            <Col md={6}>
              <Form.Group controlId="formCustomDiagnosis">
              <Form.Label>Other Diagnosis</Form.Label>
                <Form.Control
                  type="text"
                  name="customDiagnosis"
                  className="mt-md-0 mt-3" // Adjusts top margin for responsive design
                  value={customDiagnosis}
                  onChange={handleCustomDiagnosisChange}
                />
              </Form.Group>
            </Col>
          )}
        </Row>

      <Row className='form-row-divider'>
      <Col>
          <Form.Group controlId="formPrescription">
            <Form.Label>Prescription</Form.Label>
            <Form.Control
              as="textarea"
              name="prescritipn"
              value={newVisit.prescription}
              onChange={(e) => setNewVisit({ ...newVisit, prescription: e.target.value })}
              isInvalid={!!errors.prescription}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='form-row-divider'>
        <Col>
          <Form.Group controlId="formRemarks">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              as="textarea"
              name="remarks"
              value={newVisit.remarks}
              onChange={(e) => setNewVisit({ ...newVisit, remarks: e.target.value })}
            />
          </Form.Group>
        </Col> </Row>
        <Row className='form-row-divider'> <Col>
          <Form.Group controlId="formCheckedBy">
            <Form.Label>Checked By</Form.Label>
            <Form.Control
              type="text"
              name="checkedBy"
              value={newVisit.checkedBy}
              onChange={(e) => setNewVisit({ ...newVisit, checkedBy: e.target.value })}
              isInvalid={!!errors.checkedBy}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVisitModal}>Close</Button>
          <Button variant="primary" onClick={handleSaveVisit}>Save Visit</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showVaccineModal} onHide={handleCloseVaccineModal}>
  <Modal.Header closeButton>
    <Modal.Title>{editVaccineIndex !== null ? 'Edit Vaccine Record' : 'Add Vaccine Record'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group as={Row} controlId="vaccineName" className="mb-3">
        <Form.Label column sm="4">Vaccine Name</Form.Label>
        <Col sm="8">
          <Form.Control
            as="select"
            value={isCustomVaccine ? 'Others' : newVaccineRecord.vaccine_name}
            onChange={handleVaccineNameChange}
            isInvalid={!!formErrors.vaccine_name}
          >
            <option value="">Select Vaccine Name</option>
            {vaccineNames.map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </Form.Control>
          {isCustomVaccine && (
            <Form.Control
              type="text"
              placeholder="Enter vaccine name"
              value={newVaccineRecord.vaccine_name}
              onChange={handleCustomVaccineNameChange}
              className="mt-3"
              isInvalid={!!formErrors.vaccine_name}
            />
          )}
      
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formDose" className="mb-3">
        <Form.Label column sm="4">Dose</Form.Label>
        <Col sm="8">
          <Form.Control
            as="select"
            value={newVaccineRecord.dose}
            onChange={handleDoseChange}
            isInvalid={!!formErrors.dose}
          >
            <option value="">Select Dose</option>
            {doses.map(dose => (
              <option key={dose} value={dose}>{dose}</option>
            ))}
          </Form.Control>
         
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formDate" className="mb-3">
        <Form.Label column sm="4">Date</Form.Label>
        <Col sm="8">
          <Form.Control
            type="date"
            value={newVaccineRecord.date}
            onChange={handleDateChange}
            isInvalid={!!formErrors.date}
          />
  
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formRemarks" className="mb-3">
        <Form.Label column sm="4">Remarks</Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            placeholder="Enter remarks"
            value={newVaccineRecord.remarks}
            onChange={handleRemarksChange}
            isInvalid={!!formErrors.remarks}
          />
        </Col>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseVaccineModal}>Close</Button>
    <Button variant="primary" onClick={editVaccineIndex !== null ? handleSaveClick : handleSaveNewVaccine}>
      {editVaccineIndex !== null ? 'Save Changes' : 'Save Vaccine Record'}
    </Button>
  </Modal.Footer>
</Modal>

    </Container>
  );
};

export default ChildHealthRecordDetails;