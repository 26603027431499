import axios from 'axios';

const BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api';

export const fetchAddressData = async (illnessType = '', month = '', year = '') => {
  try {
    // Construct query parameters
    const params = new URLSearchParams();
    if (illnessType) params.append('illnessType', illnessType);
    if (month && year) {
      params.append('month', month);
      params.append('year', year);
    }
    console.log("api request");
    // Fetch data with optional filters
    const response = await axios.get(`${BASE_URL}/map/get-address`, {
      params: params
    });
    console.log(response.data);
    return response.data; // Assume the data is an array of coordinates or addresses
  } catch (error) {
    console.error('Failed to fetch address data:', error);
    return null;
  }
};
export const fetchAnalyticsData = async (illnessType, month, year) => {
  try {
    const params = new URLSearchParams();
    if (illnessType) params.append('illnessType', illnessType);
    if (month && year) {
      params.append('month', month);
      params.append('year', year);
    }

    const response = await axios.get(`${BASE_URL}/map/get-analytics`, {
      params: params
    });
    
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch analytics data:`, error);
    return null;
  }
};
