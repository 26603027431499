import axios from 'axios';

const API_BASE_URL = 'https://onecare-web-36d0b9bac382.herokuapp.com/api';


// fetch all health workers
export const getHealthWorkers = async () => {
    try { 
      const response = await axios.get(`${API_BASE_URL}/users/get-health-workers`);
      return response.data;
    } catch (error) {
      console.error('Error fetching health workers:', error);
    }
  };
  // check verification status
  export const checkHealthWorkerVerificationStatus = async (uid) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/users//check-verification-status`, { uid });
      return response.data;
    } catch (error) {
      console.error('Error checking verification status:', error);
      throw error;
    }
  };

  //resend verificaiton email
  export const resendVerificationEmail = async (uid) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/users/resend-verification-email`, { uid });
      return response.data;
    } catch (error) {
      console.error('Error resending verification email:', error);
      throw error;
    }
  };
  

// add new health worker
export const addHealthWorker = async (data) => {
  const response = await axios.post(`${API_BASE_URL}/users/register`, data);
  return response.data;
};

// update health worker
export const updateHealthWorker = async (id, data) => {
  const response = await axios.put(`${API_BASE_URL}/users/update-healthworker/${id}`, data);
  return response.data;
};

// delete health worker
export const deleteHealthWorker = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/users/delete-users/${id}`);
  return response.data;
};

// reset health worker password  
export const resetHealthWorkerPassword = async (email, newPassword) => {
  const response = await axios.post(`${API_BASE_URL}/users/reset-password`, { email, newPassword });
  return response.data;
};
