import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getPrenatalRecordById, updatePrenatalRecord } from '../Controller/PrenatalRecord_controller';
import StreetDropdown from './streetDropdownBtn'; 

const PrenatalRecordDetails = () => {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [originalRecord, setOriginalRecord] = useState(null); // To store original record for cancel functionality
  const [record, setRecord] = useState({
    fName: '',
    mName: '',
    lName: '',
    dob: null,
    houseNumber: '',
    streetName: '',
    sitio: '',
    occupation: '',
    contactNumber: '',
    emergencyContactPerson: '',
    emergencyContactPersonNumber: '',
    philHealthNumber: '',
    comorbidity: '',
    allergy: '',
    obstetricScoreG: '',
    obstetricScoreT: '',
    obstetricScoreP: '',
    obstetricScoreA: '',
    obstetricScoreL: '',
    lmp: null,
    edc: null,
    tdShotsHistory: '',
  });

  useEffect(() => {
    fetchPrenatalRecord();
  }, []);

  const fetchPrenatalRecord = async () => {
    try {
      const fetchedRecord = await getPrenatalRecordById(id);
      setRecord(fetchedRecord);
      setOriginalRecord(fetchedRecord); // Store original record for cancel functionality
    } catch (error) {
      console.error('Error fetching prenatal record:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecord(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setRecord(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const saveRecord = async () => {
    try {
      console.log("saving record", record);
      await updatePrenatalRecord(id, record);
      setEditMode(false);
      setSuccessMessage('Record updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating prenatal record:', error);
    }
  };

  const cancelEdit = () => {
    setRecord(originalRecord); // Revert to original record
    setEditMode(false);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <Container>
      <div className="page-header d-flex justify-content-between align-items-center">
        <h2>PERSONAL INFORMATION  </h2>
      </div>
      <div className = "msg">{successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>  )} </div>
      {record && (
        <Form>
          <Row>
            <Col>
              <Form.Group controlId="fName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fName"
                  value={record.fName}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="mName">
                <Form.Label>Middle Initial</Form.Label>
                <Form.Control
                  type="text"
                  name="mName"
                  value={record.mName}
                  onChange={handleInputChange}
                  maxLength={1}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lName"
                  value={record.lName}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="dob">
                <Form.Label>Date of Birth</Form.Label>
                <input
                  type="date"
                  name="dob"
                  value={record.dob}
                  onChange={handleDateChange}
                  className="form-control"
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="occupation">
                <Form.Label>Occupation</Form.Label>
                <Form.Control
                  type="text"
                  name="occupation"
                  value={record.occupation}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
           
          </Row>
          <Row>
          <Col>
              <Form.Group controlId="houseNumber">
                <Form.Label>House/Building Number</Form.Label>
                <Form.Control
                  type="text"
                  name="houseNumber"
                  value={record.houseNumber}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="streetName">
            <Form.Label>Street Name</Form.Label>
            <StreetDropdown
              name="streetName"
              value={record.streetName}
              onChange={handleInputChange}
              disabled={!editMode} 
            />
          </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="sitio">
                <Form.Label>Sitio/Subdivision </Form.Label>
                <Form.Control
                  as="select"
                  name="sitio"
                  value={record.sitio}
                  onChange={handleInputChange}
                  disabled={!editMode} >
                  <option value="">Select Sitio</option>
                  {[
                    'Lower Zigzag', 'Parugan Blk II', 'Sto. Niño', 'Dalig III', 'Tubigan', 'San Antonio',
                    'Nayong Silangan', 'Woodrow', 'Maia Alta', 'Lores Executive', 'Dalaya', 'Sta. Maria',
                    'Gloria Heights', 'Cuesta Verde', 'Terrazas Verde', 'Lugo', 'Payong', 'Dalig II', 'Kamatisan',
                    'Milagros', 'Aba Homes', 'Upper Zigzag', 'Parugan Blk I', 'Kaytinaga', 'Dalig I',
                    'Birmingham', 'Junmar Subd', 'La Terraza de Antipolo', 'Robbis/Courtyard', 'Avida', 'Synergy'
                  ].map((sitio) => (
                    <option key={sitio} value={sitio}>
                      {sitio}
                    </option>
                  ))}
                 </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
           
            <Col>
              <Form.Group controlId="contactNumber">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="number"
                  name="contactNumber"
                  value={record.contactNumber}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="emergencyContactPerson">
                <Form.Label>Emergency Contact Person</Form.Label>
                <Form.Control
                  type="text"
                  name="emergencyContactPerson"
                  value={record.emergencyContactPerson}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="emergencyContactPersonNumber">
                <Form.Label>Emergency Contact Person Number</Form.Label>
                <Form.Control
                  type="number"
                  name="emergencyContactPersonNumber"
                  value={record.emergencyContactPersonNumber}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
           
            <Col>
              <Form.Group controlId="philHealthNumber">
                <Form.Label>PhilHealth Number</Form.Label>
                <Form.Control
                  type="number"
                  name="philHealthNumber"
                  value={record.philHealthNumber}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="comorbidity">
                <Form.Label>Comorbidity</Form.Label>
                <Form.Control
                  type="text"
                  name="comorbidity"
                  value={record.comorbidity}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="allergy">
                <Form.Label>Allergy</Form.Label>
                <Form.Control
                  type="text"
                  name="allergy"
                  value={record.allergy}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Form.Group controlId="obstetricScoreG">
                <Form.Label>Obstetric Score G</Form.Label>
                <Form.Control
                  type="number"
                  name="obstetricScoreG"
                  value={record.obstetricScoreG}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="obstetricScoreT">
                <Form.Label>Obstetric Score T</Form.Label>
                <Form.Control
                  type="number"
                  name="obstetricScoreT"
                  value={record.obstetricScoreT}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="obstetricScoreP">
                <Form.Label>Obstetric Score P</Form.Label>
                <Form.Control
                  type="number"
                  name="obstetricScoreP"
                  value={record.obstetricScoreP}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="obstetricScoreA">
                <Form.Label>Obstetric Score A</Form.Label>
                <Form.Control
                  type="number"
                  name="obstetricScoreA"
                  value={record.obstetricScoreA}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="obstetricScoreL">
                <Form.Label>Obstetric Score L</Form.Label>
                <Form.Control
                  type="number"
                  name="obstetricScoreL"
                  value={record.obstetricScoreL}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="lmp">
                <Form.Label>Last Menstrual Period</Form.Label>
                <input
                  type="date"
                  name="lmp"
                  value={record.lmp}
                  onChange={handleDateChange}
                  className="form-control"
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="edc">
                <Form.Label>Expected Date of Confinement</Form.Label>
                <input
                  type="date"
                  name="edc"
                  value={record.edc}
                  onChange={handleDateChange}
                  className="form-control"
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="tdShotsHistory">
                <Form.Label>Tetanus Shots History</Form.Label>
                <Form.Control
                  type="text"
                  name="tdShotsHistory"
                  value={record.tdShotsHistory}
                  onChange={handleInputChange}
                  disabled={!editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          {!editMode && (
            <div className="text-right mt-3">
               <div className="add-user-button"><Button variant="info" onClick={toggleEditMode}>
                <FontAwesomeIcon icon={faEdit} /> UPDATE
              </Button>
            </div> </div>
          )}
          {editMode && (
            <div className="text-right mt-3">
              <Button variant="success" className="mr-2" style={{ marginRight: '10px' }} onClick={saveRecord}>
                <FontAwesomeIcon icon={faSave} /> Save
              </Button>
              <Button variant="danger"  className="ml-2" onClick={cancelEdit}>
                <FontAwesomeIcon icon={faTimes} /> Cancel
              </Button>
            </div>
          )}
        </Form>
      )}
    </Container>
  );
};

export default PrenatalRecordDetails;
