// src/components/FamilyPlanningRecordForm.js
import React from 'react';
import { Container,Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import StreetDropdown from './streetDropdownBtn'; 

const FamilyPlanningRecordForm = ({
  record,
  updatedRecord,
  editMode,
  handleChange,
  handleSaveClick,
  handleCancelClick,
  handleEditClick,
  setUpdatedRecord,
}) =>  {
  const formatLabel = (label) => {
    return label.replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/_/g, ' ') // Handle snake_case if needed
                .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const customLabels = {
    heent: { epilepsy: 'Epilepsy/Convulsion/Seizure',
    headache: 'Severe Headache/Dizziness',
    visualDisturbance: 'Visual disturbance/blurring of vision',
    nose: 'Nose',
    throat: 'Throat',
  },
    chestHeart: {
      chestPain: 'Severe chest pain',
      shortnessOfBreath: 'Shortness of breath and easily fatigue',
      breastMasses: 'Breast/axillary masses',
      nippleDischarges: 'Nipple discharge',
      systolicBP: 'Systolic of 140 and above',
      diastolicBP: 'Diastolic of 90 and above',
      familyHistory: 'Family history of CVA (stokes), hypertension, asthma, rheumatic heart disease',
    },
   abdomen:{ 
    abdominalMass: 'Mass in abdomen',
    gallbladderDisease: 'History of gallbladder disease',
    liverDisease: 'History of liver disease',
    },
    extremities: {
      varicosities: 'Severe varicosities',
      extremityPain: 'Swelling or severe pain the legs not related to injuries',
    },
    medicalHistory: {
      std: 'STD',
      bleedingTendencies: 'Bleeding tendecies (nose, gums, etc.)',
    },
    breast:{
      skin: 'skin - orange peel or dimpling',
    }
  };
  const renderCheckboxGroup = (checkboxes, groupName) => {
    if (!checkboxes || typeof checkboxes !== 'object') return null;

    return Object.entries(checkboxes).map(([key, checked], index) => {
        const label = customLabels[groupName]?.[key] || formatLabel(key);  // Check for custom label, otherwise use formatLabel

        return (
            <Form.Check
                key={index}
                type="checkbox"
                name={`${groupName}[${key}]`}
                label={label}  // Use custom or formatted label
                checked={checked}
                onChange={(e) => {
                    const newCheckboxes = { ...updatedRecord[groupName], [key]: e.target.checked };
                    setUpdatedRecord({ ...updatedRecord, [groupName]: newCheckboxes });
                }}
                disabled={!editMode}
            />
        );
    });
};


    // Function to handle nested field changes
    const handleNestedChange = (section, field, value) => {
        setUpdatedRecord({
            ...updatedRecord,
            [section]: {
                ...updatedRecord[section],
                [field]: value,
            },
        });
    };

  return (
    <Container className="family-planning-form">
    <div className='page-header'> CLIENT INFORMATION</div>
    <Form>
      <Row>
        <Col md={4}>
          <Form.Group controlId="formClientFName">
            <Form.Label>First Name</Form.Label>
            <Form.Control 
              type="text" 
              name="fName"
              value={updatedRecord.fName || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formClientMName">
            <Form.Label>Middle Initial</Form.Label>
            <Form.Control 
              type="text" 
              name="mName"
              value={updatedRecord.mName || ''} 
              disabled={!editMode} 
              maxLength={1}
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formClientLName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
              type="text" 
              name="lName"
              value={updatedRecord.lName || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group controlId="formDOB">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control 
              type="date" 
              name="dob"
              value={updatedRecord.dob ? updatedRecord.dob.split('T')[0] : ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formEducation">
            <Form.Label>Highest Education</Form.Label>
            <Form.Control 
              as="select" 
              name="highestEduc"
              value={updatedRecord.highestEduc || ''} 
              disabled={!editMode}  
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="none">None</option>
              <option value="Elementary">Elementary</option>
              <option value="HighSchool">High School</option>
              <option value="College">College</option>
              <option value="PostGraduate">Post Graduate</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formOccupation">
            <Form.Label>Occupation</Form.Label>
            <Form.Control 
              type="text" 
              name="occupation"
              value={updatedRecord.occupation || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group controlId="formSpouseFName">
            <Form.Label>Spouse First Name</Form.Label>
            <Form.Control 
              type="text" 
              name="spouseFname"
              value={updatedRecord.spouseFname || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formSpouseMName">
            <Form.Label>Spouse Middle Name</Form.Label>
            <Form.Control 
              type="text" 
              name="spouseMname"
              value={updatedRecord.spouseMname || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formSpouseLName">
            <Form.Label>Spouse Last Name</Form.Label>
            <Form.Control 
              type="text" 
              name="spouseLname"
              value={updatedRecord.spouseLname || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group controlId="formSpouseDOB">
            <Form.Label>Spouse Date of Birth</Form.Label>
            <Form.Control 
              type="date" 
              name="spouseDob"
              value={updatedRecord.spouseDob ? updatedRecord.spouseDob.split('T')[0] : ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      
        <Col md={4}>
        <Form.Group controlId="formSpouseEducation">
            <Form.Label>Spouse Highest Education</Form.Label>
            <Form.Control 
              as="select" 
              name="spouseHighestEduc"
              value={updatedRecord.spouseHighestEduc || ''} 
              disabled={!editMode}   
              onChange={handleChange} 
            >
              <option value="">Select</option>
              <option value="None">None</option>
              <option value="Elementary">Elementary</option>
              <option value="HighSchool">High School</option>
              <option value="College">College</option>
              <option value="PostGraduate">Post Graduate</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formSpouseOccupation">
            <Form.Label>Spouse Occupation</Form.Label>
            <Form.Control 
              type="text" 
              name="spouseOccupation"
              value={updatedRecord.spouseOccupation || ''} 
              disabled={!editMode}  
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group controlId="formHouseBuildingNumber">
            <Form.Label>House/Building Number</Form.Label>
            <Form.Control 
              type="text" 
              name="houseBuildingNumber"
              value={updatedRecord.houseBuildingNumber || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formStreetName">
            <Form.Label>Street Name</Form.Label>
            <StreetDropdown
              name="streetName"
              value={updatedRecord.streetName || ''} 
              onChange={handleChange}
              disabled={!editMode} 
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Sitio/Subdivision</Form.Label>
            <Form.Control
              as="select" 
               name="sitio"
               value={updatedRecord.sitio || ''} 
               disabled={!editMode}  
               onChange={handleChange} 
            >
              <option value="">Select Sitio</option>
              {[
                'Lower Zigzag', 'Parugan Blk II', 'Sto. Niño', 'Dalig III', 'Tubigan', 'San Antonio',
                'Nayong Silangan', 'Woodrow', 'Maia Alta', 'Lores Executive', 'Dalaya', 'Sta. Maria',
                'Gloria Heights', 'Cuesta Verde', 'Terrazas Verde', 'Lugo', 'Payong', 'Dalig II', 'Kamatisan',
                'Milagros', 'Aba Homes', 'Upper Zigzag', 'Parugan Blk I', 'Kaytinaga', 'Dalig I',
                'Birmingham', 'Junmar Subd', 'La Terraza de Antipolo', 'Robbis/Courtyard', 'Avida', 'Synergy'
              ].map((sitio) => (
                <option key={sitio} value={sitio}>
                  {sitio}
                </option>
              ))}
            </Form.Control>
          </Form.Group> 
        </Col>
       
      </Row>
      <Row>
      <Col md={3}>
          <Form.Group controlId="formIncome">
            <Form.Label>Average Monthly Family Income</Form.Label>
            <Form.Control 
              type="number" 
              name="income"
              value={updatedRecord.income || ''} 
              disabled={!editMode}  
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      <Col md={3}>
          <Form.Group controlId="formClientNumber">
            <Form.Label>Client No.</Form.Label>
            <Form.Control 
              type="number" 
              name="clientNo"
              value={updatedRecord.clientNo || ''} 
              disabled={!editMode}  
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        <Col md={3}>
        <Form.Group>
            <Form.Label> Plan for More Children </Form.Label>
            <Form.Control
              as="select"
              name="planMoreChildren" 
              value={updatedRecord.planMoreChildren || ''} 
              disabled={!editMode}  
              onChange={handleChange} 
              >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Control>  </Form.Group> </Col>
            <Col md={3}>
          <Form.Group controlId="formNoOfChildren">
            <Form.Label>No. of Children</Form.Label>
            <Form.Control 
              type="number" 
              name="noOfChildren"
              value={updatedRecord.noOfChildren || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="record-divider"><Row>
     
        <Col md={8}>
          <Form.Group controlId="formFpReason">
            <Form.Label>Family Planning Reason</Form.Label>
            <Form.Control 
              type="text" 
              name="fpReason"
              value={updatedRecord.fpReason || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group> 
          <Form.Group controlId="formAcceptorType">
          <Form.Label>Acceptor Type</Form.Label>
          <Form.Control
            as="select"
            name="acceptorType"
            value={updatedRecord.acceptorType || ''}
            disabled={!editMode} 
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="newAcceptor">New to Program</option>
            <option value="continuingUser">Continuing User</option>
          </Form.Control>
        </Form.Group>
          <Form.Group controlId="formPreviousMethod">
            <Form.Label>Previous Method</Form.Label>
            <Form.Control 
              type="text" 
              name="previousMethod"
              value={updatedRecord.previousMethod || ''} 
              disabled={!editMode}  
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
        
        <Col md={4}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h3>Accepted Methods</h3>
          {renderCheckboxGroup(updatedRecord.acceptedMethods, 'acceptedMethods')} </div>
        </Col>
      </Row>  </div>
        <Row>  
       <Col md={4}>   <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h3>HEENT</h3>
          {renderCheckboxGroup(updatedRecord.heent, 'heent')}</div>
        </Col>
        <Col md={4}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h3>Chest/Heart</h3>
          {renderCheckboxGroup(updatedRecord.chestHeart, 'chestHeart')} </div>
        </Col>
        <Col md={4}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h3>Abdomen</h3>
          {renderCheckboxGroup(updatedRecord.abdomen, 'abdomen')}</div>
        </Col>
      </Row>  
      <div className="record-divider"> <Row>
        <Col md={4}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h3>Genital</h3>
          {renderCheckboxGroup(updatedRecord.genital, 'genital')}</div>
        </Col>
        <Col md={4}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h3>Extremities</h3>
          {renderCheckboxGroup(updatedRecord.extremities, 'extremities')}
          <div className="record-divider"><h3>Skin</h3>
          {renderCheckboxGroup(updatedRecord.skin, 'skin')}</div>
          </div>
        </Col>
        <Col md={4}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h3>History of any of the following</h3>
          {renderCheckboxGroup(updatedRecord.medicalHistory, 'medicalHistory')}</div>
        </Col>
      </Row> </div>
      <div className="record-divider"> <Row>
      <h2>Obstetrical History</h2>
        <Col md={3}>
          <Form.Group controlId="formPregnancies">
            <Form.Label>Pregnancies</Form.Label>
            <Form.Control 
              type="number" 
              name="pregnancies"
              value={updatedRecord.obstetricalHistory.pregnancies || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'pregnancies', e.target.value)}
              />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="formFullTerm">
            <Form.Label>Full Term</Form.Label>
            <Form.Control 
              type="number" 
              name="fullTerm"
              value={updatedRecord.obstetricalHistory.fullTerm || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'fullTerm', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="formPremature">
            <Form.Label>Premature</Form.Label>
            <Form.Control 
              type="number" 
              name="premature"
              value={updatedRecord.obstetricalHistory.premature || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'premature', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="formAbortion">
            <Form.Label>Abortion</Form.Label>
            <Form.Control 
              type="number" 
              name="abortion"
              value={updatedRecord.obstetricalHistory.abortion || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'abortion', e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
       
      <Col md={3}>
          <Form.Group controlId="formLivingChildren">
            <Form.Label>Living Children</Form.Label>
            <Form.Control 
              type="number" 
              name="livingChildren"
              value={updatedRecord.obstetricalHistory.livingChildren || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'livingChildren', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group controlId="formLastDeliveryDate">
            <Form.Label>Last Delivery Date</Form.Label>
            <Form.Control 
              type="date" 
              name="lastDeliveryDate"
              value={updatedRecord.obstetricalHistory.lastDeliveryDate ? updatedRecord.obstetricalHistory.lastDeliveryDate.split('T')[0] : ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'lastDeliveryDate', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
      <Form.Group controlId="formLastDeliveryType">
        <Form.Label>Type of Last Delivery</Form.Label>
        <Form.Control
          as="select"
          name="lastDeliveryType"
          value={updatedRecord.obstetricalHistory.lastDeliveryType || ''}
          disabled={!editMode} 
          onChange={(e) => handleNestedChange('obstetricalHistory', 'lastDeliveryType', e.target.value)}
        >
          <option value="">Select</option>
          <option value="normal">Normal</option>
          <option value="caesarian">Caesarian</option>
          <option value="forceps">Forceps</option>
        </Form.Control>
      </Form.Group>
    </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group controlId="formPastMenstrualPeriod">
            <Form.Label>Past Menstrual Period</Form.Label>
            <Form.Control 
              type="date" 
              name="pastMenstrualPeriod"
              value={updatedRecord.obstetricalHistory.pastMenstrualPeriod || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'pastMenstrualPeriod', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formLastMenstrualPeriod">
            <Form.Label>Last Menstrual Period</Form.Label>
            <Form.Control 
              type="date" 
              name="lastMenstrualPeriod"
              value={updatedRecord.obstetricalHistory.lastMenstrualPeriod || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'lastMenstrualPeriod', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formMenstrualBleeding">
            <Form.Label>Menstrual Bleeding</Form.Label>
            <Form.Control 
              type="text" 
              name="menstrualBleeding"
              value={updatedRecord.obstetricalHistory.menstrualBleeding || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('obstetricalHistory', 'menstrualBleeding', e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
       <Row>
        <div>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h6>History of any of the following</h6>
        <Col md={6}> 
          <Form.Group controlId="formHydatidiformMole">
            <Form.Check
              type="checkbox"
              name="hydatidiformMole"
              label="Hydatidiform Mole (within the last 12 months)"
              checked={updatedRecord.obstetricalHistory.hydatidiformMole || false}
              onChange={(e) => {
                const newObstetricalHistory = { ...updatedRecord.obstetricalHistory, hydatidiformMole: e.target.checked };
                setUpdatedRecord({ ...updatedRecord, obstetricalHistory: newObstetricalHistory });
              }}
              disabled={!editMode} 
            />
          </Form.Group> 
       
          <Form.Group controlId="formEctopicPregnancy">
            <Form.Check
              type="checkbox"
              name="ectopicPregnancy"
              label="Ectopic Pregnancy"
              checked={updatedRecord.obstetricalHistory.ectopicPregnancy || false}
              onChange={(e) => {
                const newObstetricalHistory = { ...updatedRecord.obstetricalHistory, ectopicPregnancy: e.target.checked };
                setUpdatedRecord({ ...updatedRecord, obstetricalHistory: newObstetricalHistory });
              }}
              disabled={!editMode} 
            />
          </Form.Group>
        </Col>  </div></div></Row>
     </div>
      <div className="record-divider"><Row> <h2>Physical Examination</h2>
      <Col md={4}>
          <Form.Group controlId="formBloodPressure">
            <Form.Label>Blood Pressure</Form.Label>
            <Form.Control 
              type="number" 
              name="bloodPressure"
              value={updatedRecord.physicalExamination.bloodPressure || ''} 
              disabled={!editMode}  
              onChange={(e) => handleNestedChange('physicalExamination', 'bloodPressure', e.target.value)}
              />
          </Form.Group>
          </Col>
          <Col md={4}>
          <Form.Group controlId="formWeight">
            <Form.Label>Weight</Form.Label>
            <Form.Control 
              type="number" 
              name="weight"
              value={updatedRecord.physicalExamination.weight || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('physicalExamination', 'weight', e.target.value)}
            />
          </Form.Group>           </Col>
          <Col md={4}>
          <Form.Group controlId="formPulseRate">
            <Form.Label>Pulse Rate</Form.Label>
            <Form.Control 
              type="number" 
              name="pulseRate"
              value={updatedRecord.physicalExamination.pulseRate || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('physicalExamination', 'pulseRate', e.target.value)}
            />
          </Form.Group>  </Col>
      </Row> </div>
      <div className="record-divider">  <Row>
        <Col md={6}>   <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h3>Conjunctiva</h3>
          {renderCheckboxGroup(updatedRecord.conjunctiva, 'conjunctiva')} </div>
        </Col>
        <Col md={6}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
        <h3>Neck</h3>
          {renderCheckboxGroup(updatedRecord.neck, 'neck')} </div>
        </Col>
      </Row></div>
      <Col>
  <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
    <h3>Breast</h3>
    
    <Row>
      {/* General Conditions */}
      <Col md={4}>
  <div className="breast-container">
    {['mass', 'nippleDischarge', 'skin', 'enlargedAxillaryLympNodes'].map((condition) => {
      const label = condition === 'skin' 
        ? 'Skin - Orange Peel or Dimpling'  // Custom label for 'skin'
        : formatLabel(condition);  // Default formatting for other conditions

      return (
        <Form.Check
          key={condition}
          type="checkbox"
          label={label}
          name={`breast.${condition}`}
          checked={updatedRecord.breast[condition]}
          onChange={(e) => {
            const newChecked = { ...updatedRecord.breast, [condition]: e.target.checked };
            setUpdatedRecord({ ...updatedRecord, breast: newChecked });
          }}
          disabled={!editMode}
        />
      );
    })}
  </div>
</Col>


     {/* Left Breast Circle */}
     <Col md={4}>
        <h6>Left Breast</h6>
        <div className="breast-diagram">
          <label className="breast-quadrant upper-left">
            <input
              type="checkbox"
              name="breast.leftBreast.upperLeft"
              checked={updatedRecord.breast.leftBreast?.upperLeft || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.leftBreast || {}), 
                  upperLeft: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    leftBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-upper-left'>Upper Left</span>
          </label>

          <label className="breast-quadrant upper-right">
            <input
              type="checkbox"
              name="breast.leftBreast.upperRight"
              checked={updatedRecord.breast.leftBreast?.upperRight || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.leftBreast || {}), 
                  upperRight: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    leftBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-upper-right'>Upper Right</span>
          </label>

          <label className="breast-quadrant lower-left">
            <input
              type="checkbox"
              name="breast.leftBreast.lowerLeft"
              checked={updatedRecord.breast.leftBreast?.lowerLeft || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.leftBreast || {}), 
                  lowerLeft: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    leftBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-lower-left'>Lower Left</span>
          </label>

          <label className="breast-quadrant lower-right">
            <input
              type="checkbox"
              name="breast.leftBreast.lowerRight"
              checked={updatedRecord.breast.leftBreast?.lowerRight || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.leftBreast || {}), 
                  lowerRight: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    leftBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-lower-right'>Lower Right</span>
          </label>
        </div>
      </Col>

      {/* Right Breast Circle */}
      <Col md={4}>
        <h6>Right Breast</h6>
        <div className="breast-diagram">
          <label className="breast-quadrant upper-left">
            <input
              type="checkbox"
              name="breast.rightBreast.upperLeft"
              checked={updatedRecord.breast.rightBreast?.upperLeft || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.rightBreast || {}), 
                  upperLeft: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    rightBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-upper-left'>Upper Left</span>
          </label>

          <label className="breast-quadrant upper-right">
            <input
              type="checkbox"
              name="breast.rightBreast.upperRight"
              checked={updatedRecord.breast.rightBreast?.upperRight || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.rightBreast || {}), 
                  upperRight: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    rightBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-upper-right'>Upper Right</span>
          </label>

          <label className="breast-quadrant lower-left">
            <input
              type="checkbox"
              name="breast.rightBreast.lowerLeft"
              checked={updatedRecord.breast.rightBreast?.lowerLeft || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.rightBreast || {}), 
                  lowerLeft: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    rightBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-lower-left'>Lower Left</span>
          </label>

          <label className="breast-quadrant lower-right">
            <input
              type="checkbox"
              name="breast.rightBreast.lowerRight"
              checked={updatedRecord.breast.rightBreast?.lowerRight || false}
              onChange={(e) => {
                const newChecked = { 
                  ...(updatedRecord.breast.rightBreast || {}), 
                  lowerRight: e.target.checked 
                };
                setUpdatedRecord({ 
                  ...updatedRecord, 
                  breast: { 
                    ...updatedRecord.breast, 
                    rightBreast: newChecked 
                  }
                });
              }}
              disabled={!editMode}
            />
            <span className='span-lower-right'>Lower Right</span>
          </label>
        </div>
      </Col>
    </Row>
  </div>
</Col>
      <Row>
        
        <Col md={4}>   <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h3>Thorax</h3>
          {renderCheckboxGroup(updatedRecord.thorax, 'thorax')}</div>
        </Col>
        <Col md={4}>   <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h3>Abdomen</h3>
          {renderCheckboxGroup(updatedRecord.physicalExaminationAbdomen, 'physicalExaminationAbdomen')}
          </div></Col>
        <Col md={4}>  <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h3>Extremities</h3>
          {renderCheckboxGroup(updatedRecord.physicalExaminationExtremities, 'physicalExaminationExtremities')}
          </div></Col>
      </Row> 
      <div className="record-divider"> <Row>
      <Col >
          <Form.Group controlId="formPhysicalExaminationOthers">
            <Form.Label>Other Observations</Form.Label>
            <Form.Control 
              type="text" 
              name="physicalExaminationOthers"
              value={updatedRecord.physicalExaminationOthers || ''} 
              disabled={!editMode} 
              onChange={handleChange} 
            />
          </Form.Group>
        </Col>
      </Row> </div>
      <div className="record-divider"><Row>
      <h2>Pelvic Examination</h2>
      <Col md={3}>
        <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h2>Perineum</h2>
          
          {Object.keys(updatedRecord.perineum).map((key) => {
            if (key === 'consistency') {
              return (
                <div key={key}>
                  <h5>Consistency</h5>
                  {Object.keys(updatedRecord.perineum.consistency).map((consistencyKey) => (
                    <Form.Check
                      key={consistencyKey}
                      type="checkbox"
                      label={formatLabel(consistencyKey)} 
                      name={`perineum.consistency.${consistencyKey}`}
                      checked={updatedRecord.perineum.consistency[consistencyKey]}
                      onChange={(e) => {
                        const newConsistency = {
                          ...updatedRecord.perineum.consistency,
                          [consistencyKey]: e.target.checked,
                        };
                        setUpdatedRecord({
                          ...updatedRecord,
                          perineum: {
                            ...updatedRecord.perineum,
                            consistency: newConsistency,
                          },
                        });
                      }}
                      disabled={!editMode}
                    />
                  ))}
                </div>
              );
            } else {
              // Handle other fields normally
              return (
                <Form.Check
                  key={key}
                  type="checkbox"
                  label={formatLabel(key)}
                  name={`perineum.${key}`}
                  checked={updatedRecord.perineum[key]}
                  onChange={(e) => {
                    const newPerineum = {
                      ...updatedRecord.perineum,
                      [key]: e.target.checked,
                    };
                    setUpdatedRecord({ ...updatedRecord, perineum: newPerineum });
                  }}
                  disabled={!editMode}
                />
              );
            }
          })}
        </div>
      </Col>

        <Col md={3}>  <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h2>Vagina</h2>
          {renderCheckboxGroup(updatedRecord.vagina, 'vagina')}</div>
        </Col>
        <Col md={3}>  <div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h2>Cervix</h2>
          {renderCheckboxGroup(updatedRecord.cervix, 'cervix')}</div>
        </Col>
        <Col md={3}><div className={`f-record-divider ${!editMode ? 'edit-disabled' : ''}`}>
          <h2>Adnexa</h2>
          {renderCheckboxGroup(updatedRecord.adnexa, 'adnexa')} </div>
        </Col>
    
      </Row> </div>
      <div className="record-divider">  <Row>
         <h2>Uterus</h2>
         <Col md={4}>
          <Form.Group controlId="formUterusPosition">
            <Form.Label>Position</Form.Label>
            <Form.Control 
              as="select" 
              name="uterus[position]"
              value={updatedRecord.uterus.position || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('uterus', 'position', e.target.value)}
            >
              <option value="">Select</option>
                <option value="mid">Mid</option>
                <option value="anteflexed">Anteflexed</option>
                <option value="retroflexed">Retroflexed</option></Form.Control>
          </Form.Group>  </Col>
          <Col md={4}>
          <Form.Group controlId="formUterusSize">
            <Form.Label>Size</Form.Label>
            <Form.Control 
              as="select"               
              name="uterus[size]"
              value={updatedRecord.uterus.size || ''} 
              disabled={!editMode} 
              onChange={(e) => handleNestedChange('uterus', 'size', e.target.value)}
            >
              <option value="">Select</option>
                <option value="normal">Normal</option>
                <option value="small">Small</option>
                <option value="large">Large</option> </Form.Control>
          </Form.Group> </Col>
          <Col md={4}>
  <Form.Group controlId="formUterusMass">
    <Form.Label>Mass</Form.Label>
    <Form.Control 
      type="number" 
      name="uterus[mass]"
      value={updatedRecord.uterus.mass || ''} 
      disabled={!editMode} 
      onChange={(e) => handleNestedChange('uterus', 'mass', e.target.value)} // Fixed the 'Mass' to 'mass'
      step="any" // Allows decimal values
    />
  </Form.Group> 
</Col>

      </Row> </div>
   
 
      {editMode && (
        <div>
            <Button variant="success" className="mr-2" style={{ marginRight: '10px' }}onClick={handleSaveClick}>
            <FontAwesomeIcon icon={faSave} />  Save
          </Button>
          <Button variant="danger"  className="ml-2"  onClick={handleCancelClick}>
          <FontAwesomeIcon icon={faTimes} />  Cancel
          </Button>
        </div>
      )}
      
      {/* Show Edit button only when not in edit mode */}
      {!editMode && (
        <div className="add-user-button"><button onClick={handleEditClick}>
          <FontAwesomeIcon icon={faEdit} /> UPDATE
          </button> </div> 
      )}
    </Form></Container>
  );
};

export default FamilyPlanningRecordForm;
