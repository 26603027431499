import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker'; 
import 'react-datepicker/dist/react-datepicker.css'; 
import '../App.css';

const PatientDetailsForm = ({ patient, onSave }) => {
  const [editablePatient, setEditablePatient] = useState(patient);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    setEditablePatient(patient);
  }, [patient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditablePatient({ ...editablePatient, [name]: value });
  };

  const handleDateChange = (date) => {
    setEditablePatient({ ...editablePatient, birthday: date }); // Update birthday with selected date
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmSave = () => {
    onSave(editablePatient);
    setIsEditing(false);
    setShowConfirmation(false);
    setSuccessMessage('Changes saved successfully');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  const handleCancelClick = () => {
    setEditablePatient(patient);
    setIsEditing(false);
  };

  return (
    <Form className="patient-details-form">
      
      {/* Success Message */}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <div className="row custom-row">
        <div className="col-md-4">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control custom-input"
              name="fname"
              value={editablePatient.fname || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Middle Name</label>
            <input
              type="text"
              className="form-control custom-input"
              name="mname"
              value={editablePatient.mname || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control custom-input"
              name="lname"
              value={editablePatient.lname || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
      </div>

      <div className="row custom-row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Gender</label>
            <input
              type="text"
              className="form-control custom-input"
              name="gender"
              value={editablePatient.gender || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
        <div className="col-md-4">
        <div className="form-group">
            <label>Date of Birth</label>
            {isEditing ? ( 
              <DatePicker
                selected={editablePatient.birthday} //  selected date
                onChange={handleDateChange} 
                dateFormat="yyyy-MM-dd" // date format
                className="form-control custom-date" 
                readOnly={!isEditing}
              />
            ) : (
              <input
                type="text"
                className="form-control custom-input"
                name="birthday"
                value={editablePatient.birthday ? new Date(editablePatient.birthday).toISOString().split('T')[0] : ''}                onChange={handleChange}
                readOnly={!isEditing}
              />
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control custom-input"
              name="email"
              value={editablePatient.email || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
      </div>

      <div className="row custom-row">
        <div className="col-12">
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              className="form-control custom-AddressInput"
              name="address"
              value={editablePatient.address || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
      </div>

      <div className="row custom-row">
        <div className="col-md-4">
          <div className="form-group">
            <label>Contact Number</label>
            <input
              type="text"
              className="form-control custom-input"
              name="contactNumber"
              value={editablePatient.contactNumber || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Contact Person</label>
            <input
              type="text"
              className="form-control custom-input"
              name="contactPerson"
              value={editablePatient.contactPerson || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Height</label>
            <input
              type="text"
              className="form-control custom-input"
              name="height"
              value={editablePatient.height || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Weight</label>
            <input
              type="text"
              className="form-control custom-input"
              name="weight"
              value={editablePatient.weight || ''}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>
        <div className="ptn-button-container">
          {isEditing ? (
            <>
              <Button className="patientBtn" variant="primary" onClick={handleSaveClick}>Save</Button>
              <Button className="patientBtn" variant="secondary" onClick={handleCancelClick}>Cancel</Button>
            </>
          ) : (
            <Button className="patientBtn" variant="secondary" onClick={handleEditClick}>Edit</Button>
          )}
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Save</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save the changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleConfirmSave}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

     
    </Form>
  );
};

export default PatientDetailsForm;
